import { Component } from "react";

export default class EntityError extends Component {
  constructor(props) {
    super(props);
  }

  componentDidCatch(error, errorInfo) {
    console.log(error);
    this.props.setEntityError(error);
  }

  render() {
    if (this.props.entityErrors?.length > 0) {
      return <a-entity
        geometry="primitive: box"
        material="color: red; opacity: 0.5"
        position={this.props.entityProps?.position}
        rotation={this.props.entityProps?.rotation}
        scale={this.props.entityProps?.scale}
      ></a-entity>
    }

    return this.props.children;
  }
}