import { useCallback, useEffect, useState } from "react";

import "../gydence/utils";
import "../gydence/anchor";
import "../gydence/animations";
import "../gydence/camera";
import "../gydence/cameraSource";
import "../gydence/container";
import "../gydence/depth-estimation";
import "../gydence/follow";
import "../gydence/grid";
import "../gydence/imageSource";
import "../gydence/list";
import "../gydence/logo";
import "../gydence/material";
import "../gydence/model";
import "../gydence/networking";
import "../gydence/ring";
import "../gydence/selectionTool";
import "../gydence/spawner";
import "../gydence/stencil";
import "../gydence/testing";
import "../gydence/text3d";
import "../gydence/tfjs-hand-tracking";

import styles from "./index.module.css";

export default function AppView() {
  const [overlayElements, setOverlayElements] = useState(undefined);
  const [cssProps, setCSSProps] = useState(undefined);
  const [hasLoadedScripts, setHasLoadedScripts] = useState(false);

  const messageHandler = useCallback((event) => {
    if (!event.data.type) {
      return;
    }

    switch (event.data.type) {
      case "data-update":
        setOverlayElements(event.data.html);
        setCSSProps(event.data.css);
        break;
      case "scripts-loaded":
        setHasLoadedScripts(true);
        break;
      default:
        break;
    }
  }, []);

  useEffect(() => {
    const injectedCSSID = "injectedCSS";
    const existingStyleSheet = document.querySelector("#" + injectedCSSID);
    const stylesheet = existingStyleSheet ?? document.createElement("style");
    stylesheet.id = injectedCSSID;
    stylesheet.innerHTML = cssProps;
    if (!existingStyleSheet) {
      document.head.appendChild(stylesheet);
    }
  }, [cssProps]);

  useEffect(() => {
    if (hasLoadedScripts) {
      const event = new Event("DOMContentLoaded");
      document.dispatchEvent(event);
    }
  }, [hasLoadedScripts]);

  useEffect(() => {
    window.addEventListener("message", messageHandler);

    return () => {
      window.removeEventListener("message", messageHandler);
    }
  }, [messageHandler]);

  return (
    <>
      <div
        id="overlayDOM"
        className={styles.block}
      >
        {hasLoadedScripts && overlayElements ?
          <div
            className={styles.block}
            style={{ position: "absolute", width: "100vw", height: "100vh" }}
            dangerouslySetInnerHTML={{ __html: overlayElements }}
          />
          : <></>
        }
      </div>
    </>
  )
}