import "aframe";

AFRAME.registerSystem("environment-mapper", {
  schema: {
    enabled: {
      default: false
    },
    activeEnvironment: {
      default: 0
    },
    sigma: {
      default: 0
    },
    debug: {
      default: false
    }
  },

  init: function () {
    this.indexMap = {};
    this.needsUpdate = true;
    this.scene = new THREE.Scene();
    this.generator = new THREE.PMREMGenerator(this.el.sceneEl.renderer);
  },

  update: function (oldData) {
    if (this.data.enabled) {
      this.needsUpdate = true;
    }

    if (this.data.debug != oldData.debug) {
      for (const environmentContent of Object.values(this.indexMap)) {
        for (const el of environmentContent) {
          el.object3D.traverse((node) => {
            node.visible = !node.isLight ? this.data.debug : false;
          });
        }
      }
    }
  },

  updateHandler: function (e) {
    const component = e.srcElement.components["environment-mapper"];
    const system = component.system;
    if (component.data.environmentIndex === system.data.activeEnvironment) {
      system.needsUpdate = true;
    }
  },

  registerElement: function (el, index, oldIndex) {
    if (oldIndex) {
      this.unregisterElement(el, oldIndex);
    }

    if (!(index in this.indexMap)) {
      this.indexMap[index] = [];
    }

    this.indexMap[index].push(el);

    const self = this;
    el.addEventListener("componentchanged", self.updateHandler);

    if (index === this.data.activeEnvironment) {
      this.needsUpdate = true;
    }

    el.object3D.traverse((node) => {
      node.visible = !node.isLight ? this.data.debug : false;
    });
  },

  unregisterElement: function (el, index) {
    let indexArray = this.indexMap[index];
    const indexToRemove = indexArray.indexOf(el);
    if (indexToRemove !== -1) {
      indexArray.splice(indexToRemove, 1);

      const self = this;
      el.removeEventListener("componentchanged", self.updateHandler);

      if (index === this.data.activeEnvironment) {
        this.needsUpdate = true;
      }

      el.object3D.traverse((node) => {
        node.visible = true;
      });
    }
  },

  tick: function () {
    if (!this.needsUpdate || !this.data.enabled) {
      return;
    }

    const environmentContent = this.indexMap[this.data.activeEnvironment];
    if (environmentContent) {
      let parentMap = {};
      for (const el of environmentContent) {
        parentMap[el.object3D.uuid] = el.object3D.parent;
        this.scene.attach(el.object3D);
        el.object3D.traverse((node) => {
          node.visible = true;
        });
      }

      if (this.renderTarget) {
        this.renderTarget.dispose();
      }
      if (this.environmentTexture) {
        this.environmentTexture.dispose();
      }

      this.renderTarget = this.generator.fromScene(this.scene, this.data.sigma);
      this.environmentTexture = this.renderTarget.texture;
      this.el.sceneEl.object3D.environment = this.environmentTexture;

      for (const el of environmentContent) {
        el.object3D.traverse((node) => {
          node.visible = !node.isLight ? this.data.debug : false;
        });
        parentMap[el.object3D.uuid].attach(el.object3D);
      }
    }

    this.needsUpdate = false;
  },

  remove: function () {
    for (const environmentContent of Object.values(this.indexMap)) {
      for (const el of environmentContent) {
        el.object3D.traverse((node) => {
          node.visible = true;
        });
      }
    }

    if (this.renderTarget) {
      this.renderTarget.dispose();
    }
    if (this.environmentTexture) {
      this.environmentTexture.dispose();
    }
    this.el.sceneEl.object3D.environment = undefined;

    this.generator.dispose();
  }
});

AFRAME.registerComponent("environment-mapper", {
  schema: {
    environmentIndex: {
      default: 0
    }
  },

  init: function () {
    this.matrixWorld = new THREE.Matrix4();
  },

  update: function (oldData) {
    if (this.data.environmentIndex !== oldData.environmentIndex) {
      this.system.registerElement(this.el, this.data.environmentIndex, oldData.environmentIndex);
    }
  },

  remove: function () {
    this.system.unregisterElement(this.el, this.data.environmentIndex);
  },

  tick: function () {
    if (!this.el.object3D.matrixWorld.equals(this.matrixWorld)) {
      this.system.needsUpdate = true;
      this.matrixWorld.copy(this.el.object3D.matrixWorld);
    }
  }
});