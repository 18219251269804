import { useCallback, useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";

import { Button, Checkbox, FormControl, FormControlLabel, FormGroup, IconButton, MenuItem, Switch, TextField } from "@mui/material";
import { ChevronLeft, Close } from "@mui/icons-material";

import { supabase } from "./signin";
import GridList from "../components/gridList";

import styles from "./index.module.css";
import { PageControls } from "../components/itemListEditor";
import { ControlledInput } from "../components/controlledInput";

const NUM_LISTINGS_PER_PAGE = 12;
const NUM_COLUMNS = 4;
const sortingOptions = ["featured", "popular", "recent", "owned", "mine"];
const filterOptions = ["template", "script", "asset", "app", "environment"];

export default function Marketplace({ mode, startingSortMode, ownedItems, ownedListings,
    user, setEditorModeHandler, targetListing, setTargetListingHandler, refreshOwnedItemsHandler }) {
  const [page, setPage] = useState(0);
  const [sortMode, setSortMode] = useState(startingSortMode ?? sortingOptions[0]);
  const [filters, setFilters] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [items, setItems] = useState([]);

  const [selectedListing, setSelectedListing] = useState(targetListing ?? undefined);
  const [selectedListingData, setSelectedListingData] = useState(undefined);
  const [selectedVersion, setSelectedVersion] = useState(undefined);
  const [versions, setVersions] = useState([]);
  const [selectedVersionData, setSelectedVersionData] = useState(undefined);

  const purchaseItem = useCallback((listing, item) => {
    supabase.rpc("purchase_item", {
      listing: listing,
      item: item
    }).then((result) => {
      console.log(result);
      if (!result.error) {
        refreshOwnedItemsHandler();
      }
    });
  }, [refreshOwnedItemsHandler]);

  const setUserListed = useCallback((listing, checked) => {
    supabase.rpc("update_listing_user_listed", {
      listing: listing,
      value: checked
    }).then((result) => {
      console.log(result);
      if (!result.error) {
        let currSelectedListingData = {...selectedListingData};
        currSelectedListingData.user_listed = checked;
        setSelectedListingData(currSelectedListingData);
      }
    });
  }, [selectedListingData]);

  useEffect(() => {
    setPage(0);
  }, [mode, sortMode, filters, searchText]);

  useEffect(() => {
    if (selectedListing) {
      const fetchData = async () => {
        let creator = undefined;
        let allVersions = [];
        {
          const { data } = await supabase.from("marketplace_listings").select("*")
            .eq("id", selectedListing).limit(1);
          creator = data?.[0]?.creator;
          allVersions = data?.[0]?.versions;
          setSelectedListingData(data?.length > 0 ? data[0] : undefined);
        }

        if (creator && allVersions?.length > 0) {
          let query = supabase.from("marketplace_items").select("id")
            .eq("listing", selectedListing);

          if (creator !== user?.user.id) {
            query = query.eq("under_review", false);
          }

          const { data } = await query.limit(allVersions.length);
          setVersions(data.map((version) => version.id));

          const ownedSelectedListing = ownedItems ? data?.find((version) => ownedItems.find((item) => item === version.id)) : undefined;
          setSelectedVersion(ownedSelectedListing?.id ?? data?.slice(-1)[0].id);
        }
      };
      fetchData();
    } else {
      setSelectedVersion(undefined);
      setSelectedListingData(undefined);
    }
  }, [selectedListing]);

  useEffect(() => {
    if (selectedVersion) {
      const fetchData = async () => {
        const { data } = await supabase.from("marketplace_items").select("name,description,under_review")
          .eq("id", selectedVersion).limit(1);
        setSelectedVersionData(data?.length > 0 ? data[0] : undefined);
      };
      fetchData();
    } else {
      setSelectedVersionData(undefined);
    }
  }, [selectedVersion]);

  useEffect(() => {
    const fetchData = async () => {
      let query = supabase.from("marketplace_listings").select("*");

      // TODO: featured, popular
      // Filters
      switch (sortMode) {
        case "recent":
          break;
        case "owned":
          query = query.in("id", ownedListings);
          break;
        case "mine":
          query = query.eq("creator", user?.user?.id);
          break;
        default:
          break;
      }

      if (sortMode !== "mine" && sortMode !== "owned") {
        query = query.eq("under_review", false).eq("listed", true).eq("user_listed", true);
      }

      if (filters.length > 0) {
        query = query.in("type", filters);
      }

      if (searchText.length > 0) {
        query = query.textSearch("search_index", searchText, {
          type: "websearch",
          config: "english"
        });
      }

      // Modifiers (must come after filters)
      query = query.range(page * NUM_LISTINGS_PER_PAGE, page * NUM_LISTINGS_PER_PAGE + NUM_LISTINGS_PER_PAGE);

      switch (sortMode) {
        case "recent":
        case "mine":
          query = query.order("created_at", { ascending: false });
          break;
        case "owned":
          // TODO: sort by purchase time?
          break;
        default:
          break;
      }

      const { data } = await query;
      if (data) {
        setItems(data)
      } else {
        setItems([]);
      }
    }
    fetchData();
  }, [mode, page, sortMode, filters, searchText]);

  return (
    <div
      className={styles.ui}
      style={{
        width: "90vw",
        height: "90vh",
        maxWidth: "1000px",
        maxHeight: "1000px",
        color: "#CCCCCC",
        background: "rgba(50, 50, 50, 0.7)",
        overflow: "auto",
        position: "relative"
      }}
      onClick={(e) => e.stopPropagation()}
    >
      <div
        className={styles.topRight}
      >
        <IconButton
          onClick={() => {
            setEditorModeHandler("Entities");
            setTargetListingHandler(undefined);
          }}
          sx={{
            color: "rgba(255, 255, 255, 0.4)"
          }}
        >
          <Close />
        </IconButton>
      </div>

      {selectedListingData ?
        <>
          <div
            className={styles.topLeft}
          >
            <IconButton
              onClick={() => setSelectedListing(undefined)}
              sx={{
                color: "rgba(255, 255, 255, 0.4)"
              }}
            >
              <ChevronLeft />
            </IconButton>
          </div>
          <h1>{selectedListingData.name}</h1>
          {(selectedVersionData?.name && selectedVersionData.name !== selectedListingData.name) ?
            <h2>{selectedVersionData.name}</h2>
            : <></>
          }
          {selectedListingData.tags?.length > 0 ?
            <p>Tags: {selectedListingData.tags}</p>
            : <></>
          }
          {(versions.length > 1 && selectedVersion) ?
            <>
              <TextField
                label="Version"
                value={selectedVersion}
                onChange={(e) => setSelectedVersion(e.target.value) }
                size="small"
                select
                sx={{
                  background: "white"
                }}
              >
                {versions.map((version, index) => (
                  <MenuItem key={version} value={version}>v{index + 1}</MenuItem>
                ))}
              </TextField>
            </>
            : <></>
          }
          <br />
          {selectedVersionData?.description?.length > 0 ?
            <div
              style={{
                height: "300px",
                overflow: "auto"
              }}
            >
              <ReactMarkdown>{selectedVersionData.description}</ReactMarkdown>
            </div>
            : <></>
          }
          {ownedItems?.find((item) => { return item === selectedVersion} ) ?
            <Button
              variant="contained"
              size="small"
              disabled
            >
              Owned!
            </Button>
            : <Button
              variant="contained"
              size="small"
              onClick={() => { purchaseItem(selectedListing, selectedVersion); }}
            >
              {/* TODO: add paid  */}
              {ownedListings.find((listing) => { return listing === selectedListing} ) ?
                "Switch Versions" : "Purchase: " + "Free"}
            </Button>
          }

          {selectedListingData?.creator === user?.user?.id ?
            <>
              <br />
              <Button
                variant="contained"
                size="small"
                color="secondary"
                onClick={() => {
                  setEditorModeHandler("Upload");
                  setTargetListingHandler(selectedListing);
                }}
              >
                {"Upload new version >"}
              </Button>
              <br />
              <FormControlLabel
                control={
                  <Switch
                    checked={selectedListingData.user_listed}
                    onChange={(e) => setUserListed(selectedListingData.id, e.target.checked)}
                    size="small"
                  />
                }
                label="Public?"
                labelPlacement="start"
              />
              {selectedListingData.under_review ?
                <>
                  <br />
                  <span>Listing under review.</span>
                </>
                : <></>
              }
              {selectedVersionData?.under_review ?
                <>
                  <br />
                  <span>Version under review.</span>
                </>
                : <></>
              }
              {!selectedListingData.listed ?
                <>
                  <br />
                  <span>De-listed.</span>
                </>
                : <></>
              }
            </>
            : <></>
          }
        </> :
        <>
          <p className={styles.unselectable}>{mode}</p>
          <TextField
            label="Sort By"
            defaultValue={startingSortMode ?? sortingOptions[0]}
            onChange={(e) => setSortMode(e.target.value) }
            size="small"
            select
            sx={{
              background: "white"
            }}
          >
            {sortingOptions.map((option) => (
              <MenuItem key={option} value={option}>{option}</MenuItem>
            ))}
          </TextField>
          <br />
          <br />
          <label>Filters:</label>
          <br />
          <FormControl component="fieldset">
            <FormGroup aria-label="position" row>
              {filterOptions.map((option) => (
                <FormControlLabel
                  key={option}
                  value={option}
                  control={
                    <Checkbox
                      onChange={(e) => {
                        if (e.target.checked) {
                          let currFilters = [...filters]
                          currFilters.push(option);
                          setFilters(currFilters);
                        } else {
                          const index = filters.indexOf(option);
                          if (index !== -1) {
                            let currFilters = [...filters]
                            currFilters.splice(index, 1);
                            setFilters(currFilters);
                          }
                        }
                      }}
                    />
                  }
                  label={option + "s"}
                />
              ))}
            </FormGroup>
          </FormControl>
          <br />
          <br />
          <ControlledInput
            type="text"
            label="Search"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            size="small"
            sx={{
              input: {
                backgroundColor: "white"
              }
            }}
          />
          <br />
          <br />
          <GridList
            selected={undefined}
            list={items.length > NUM_LISTINGS_PER_PAGE ? items.slice(0, NUM_LISTINGS_PER_PAGE) : items}
            numColumns={NUM_COLUMNS}
            onSelectHandler={(id) => { setSelectedListing(id); }}
            getGridItem={(item) => {
              let owned = ownedListings.indexOf(item.id) !== -1;
              return (
                <div
                  style={{
                    width: "100%",
                    height: "100%"
                  }}
                >
                  {item.name}

                  <div
                    className={styles.bottomRight}
                  >
                    {owned ? "Owned" : "Free"}
                  </div>
                </div>
              )
            }}
            style={{
              height: "350px",
              overflow: "auto"
            }}
          />
          {(items.length > NUM_LISTINGS_PER_PAGE || page > 0) ?
            <PageControls
              hasMoreItems={items.length > NUM_LISTINGS_PER_PAGE}
              page={page}
              setPage={setPage}
            />
            : <></>
          }
        </>
      }
    </div>
  )
}