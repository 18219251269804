import { useEffect, useState, useRef } from "react";

import { TabContext, TabPanel } from "@mui/lab";
import { Button, IconButton, Pagination, Tab, Tabs } from "@mui/material";
import { NavigateBefore, NavigateNext, Remove } from "@mui/icons-material";

import styles from "../pages/index.module.css"

export const NUM_ITEMS_PER_PAGE = 6;

export function PageControls({ hasMoreItems, page, setPage, pageCount }) {
  return (
    <div
      className={[styles.bottomMiddle, styles.block].join(" ")}
      style={{
        width: "100%",
      }}
    >
      {pageCount ?
        <Pagination
          count={pageCount}
          page={page + 1}
          onChange={(_, newPage) => setPage(newPage - 1)}
          size="small"
          sx={{
            width: "fit-content",
            margin: "0 auto",
          }}
        />
        : <div
          style={{
            width: "fit-content",
            margin: "0 auto"
          }}
        >
          <IconButton
            onClick={page > 0 ? () => setPage(page - 1) : undefined}
          >
            <NavigateBefore color={page === 0 ? "disabled" : undefined} size="small" />
          </IconButton>
          <span>{page + 1}</span>
          <IconButton
            onClick={hasMoreItems ? () => setPage(page + 1) : undefined}
          >
            <NavigateNext color={!hasMoreItems ? "disabled" : undefined} size="small" />
          </IconButton>
        </div>
      }
    </div>
  )
}

function ItemList({ items, isPaged, page, setPageHandler, selectedItem, selectHandler,
    deleteItemHandler, useName, getItemText }) {
  const isDeletable = deleteItemHandler;

  const hasMoreItems = isPaged ? items.length > NUM_ITEMS_PER_PAGE : ((page + 1) * NUM_ITEMS_PER_PAGE < items.length);

  return (
    <div
      style={{
        position: "absolute",
        textAlign: "left",
        width: "350px",
        height: "275px"
      }}
    >
      {items ? [...Array(Math.min(Math.max(isPaged ? items.length : (items.length - page * NUM_ITEMS_PER_PAGE), 0), NUM_ITEMS_PER_PAGE))].map((_, i) => {
          if ((isPaged ? i : (page * NUM_ITEMS_PER_PAGE + i)) >= items.length) {
            return;
          }

          const item = items[isPaged ? i : (page * NUM_ITEMS_PER_PAGE + i)];
          return <div key={["div_", item.id].join("")}>
            <span
              key={item.id}
              style={{
                padding: "2px 10px",
                backgroundColor: (item.id === selectedItem) ? "#dddddd" : undefined,
                borderRadius: (item.id === selectedItem) ? "10px" : undefined,
                cursor: "pointer",
              }}
              onClick={() => selectHandler(selectedItem === item.id ? undefined : item.id)}
            >
              {getItemText(item)}
            </span>
            {isDeletable ?
              <IconButton
                key={["button_", item.id].join("")}
                onClick={() => deleteItemHandler(useName ? item.name : item.id)}
              >
                <Remove />
              </IconButton>
              : <></>
            }
            <br key={["br_", item.id].join("")} />
          </div>
        })
        : <></>
      }

      {hasMoreItems || page > 0 ?
        <PageControls
          hasMoreItems={hasMoreItems}
          page={page}
          setPage={setPageHandler}
          pageCount={!isPaged ? Math.ceil(items.length / NUM_ITEMS_PER_PAGE) : undefined}
        />
        : <></>
      }
    </div>
  )
}

export function ItemListPanel({ editorMode, selectedObject, setSelectedObject,
  items, ownedItems, addHandler, deleteItemHandler, dropHandler, setOffsetHandler }) {
  const modes = ["Site", "Owned"];
  const [mode, setMode] = useState(modes[0]);
  const [page, setPage] = useState(0);

  useEffect(() => {
    setPage(0);
  }, [mode]);

  useEffect(() => {
    if (setOffsetHandler) {
      setOffsetHandler(page * NUM_ITEMS_PER_PAGE);
    }
  }, [page]);

  const uploadAssetInputRef = useRef(undefined);

  const isPaged = !!setOffsetHandler;
  const getItemText = editorMode === "Assets" ?
    (item) => {return item.name}
    : (item) => {
      return (item.name?.length > 0) ? item.name :
        (item.url?.length > 0) ? item.url : item.id;
    };

  return (
    <div
      style={{
        position: "relative",
        width: "400px",
        height: "400px"
      }}
      onDrop={dropHandler ? (e) => dropHandler(e, addHandler) : undefined}
      onDragEnter={dropHandler ? (e) => e.preventDefault() : undefined}
      onDragOver={dropHandler ? (e) => e.preventDefault() : undefined}
    >
      <TabContext value={mode}>
        <Tabs value={mode} onChange={(_, value) => setMode(value)} centered>
          {modes.map((modeValue) => {
            return <Tab key={modeValue} label={modeValue} value={modeValue} />
          })}
        </Tabs>
        <TabPanel value="Site">
          <ItemList
            items={items}
            isPaged={isPaged}
            page={page}
            setPageHandler={setPage}
            selectedItem={selectedObject}
            selectHandler={setSelectedObject}
            deleteItemHandler={deleteItemHandler}
            getItemText={getItemText}
            useName={editorMode === "Assets"}
          />
        </TabPanel>
        <TabPanel value="Owned">
          <ItemList
            items={ownedItems}
            isPaged={false}
            page={page}
            setPageHandler={setPage}
            selectedItem={selectedObject}
            selectHandler={setSelectedObject}
            deleteItemHandler={undefined}
            getItemText={getItemText}
            useName={editorMode === "Assets"}
          />
        </TabPanel>
      </TabContext>

      {editorMode === "Scripts" ?
        <div
          className={[styles.bottomMiddle, styles.block].join(" ")}
          style={{ width: "100%" }}
        >
          <Button
            onClick={() => addHandler({
              name: "",
              url: "",
              module: false,
              execution: "normal"
            })}
          >
            Add Script URL
          </Button>
          <Button
            onClick={() => addHandler({
              name: "",
              script: "",
              module: false
            })}
          >
            Add Script Block
          </Button>
        </div>
        : editorMode === "Assets" ?
          <div
            className={[styles.bottomMiddle, styles.block].join(" ")}
            style={{ width: "100%" }}
          >
            <Button
              onClick={() => uploadAssetInputRef.current.click()}
            >
              Upload Assets
              <input
                ref={uploadAssetInputRef}
                type="file"
                onInput={(e) => {
                  for (let file of e.target.files) {
                    addHandler(file);
                  }
                }}
                hidden
                multiple
              />
            </Button>
          </div>
          : <div
              className={[styles.bottomMiddle, styles.block].join(" ")}
              style={{ width: "100%" }}
            >
              <Button
                onClick={() => addHandler({
                  name: "",
                  html: "",
                  css: "",
                  script: ""
                })}
              >
                Add App
              </Button>
            </div>
      }
    </div>
  )
}