import { useState, useEffect, useMemo, useCallback } from "react";
import { Button, Checkbox, FormControlLabel, IconButton, MenuItem, Tab, Tabs, TextField } from "@mui/material";
import { TabContext, TabPanel } from "@mui/lab";
import { Close } from "@mui/icons-material";

import { supabase } from "./signin";
import { ControlledInput, ControlledTextArea } from "../components/controlledInput";

import styles from "./index.module.css";

function UploadCheckbox({ id, name, checkMap, setCheckMap, onChange, ...rest }) {
  const key = id ?? name;
  return (
    <div
      {...rest}
    >
      <FormControlLabel
        label={name}
        control={
          <Checkbox
            checked={checkMap[key] ?? true}
            onChange={(e) => {
              let currMap = {...checkMap};
              currMap[key] = e.target.checked;
              if (onChange) {
                onChange(e.target.checked, currMap);
              }
              setCheckMap(currMap);
            }}
          />
        }
      />
    </div>
  )
}

const uncheckHierarchy = (entity, entityParentMap, checkMap) => {
  const children = entityParentMap[entity];
  for (const child of children) {
    checkMap[child] = false;
    if (child in entityParentMap) {
      uncheckHierarchy(child, entityParentMap, checkMap);
    }
  }
};

function EntityList({ entity, entityPropertyMap, entityParentMap, checkMap, setCheckMap }) {
  return (
    <>
      {entityParentMap?.[entity]?.map((child) => {
        return (
          <div
            key={["div_", child].join("")}
            style={{
              paddingLeft: entity ? 10 : 0,
            }}>
            <UploadCheckbox
              id={child}
              name={entityPropertyMap?.[child]?.name ?? child}
              checkMap={checkMap}
              setCheckMap={setCheckMap}
              onChange={(checked, map) => {
                if (!checked && (child in entityParentMap)) {
                  uncheckHierarchy(child, entityParentMap, map);
                }
              }}
            />
            <EntityList
              key={child}
              entity={child}
              entityPropertyMap={entityPropertyMap}
              entityParentMap={entityParentMap}
              checkMap={checkMap}
              setCheckMap={setCheckMap}
            />
          </div>
        )
      })}
    </>
  );
}

function ItemList({ items, name, checkMap, setCheckMap, privateMap, setPrivateMap }) {
  return (
    <div style={{ textAlign: "left" }}>
      <UploadCheckbox name={name} checkMap={checkMap} setCheckMap={setCheckMap} onChange={(checked, map) => {
        for (const item of items) {
          map[item.id] = checked;
        }
      }} />
      <div
        style={{
          paddingLeft: "10px"
        }}
      >
        {items.map((item) => {
          return <div
            key={"div" + item.id}
          >
            {
              privateMap ?
                <UploadCheckbox
                  style={{
                    float: "right",
                    marginRight: "300px"
                  }}
                  key={"private" + item.id}
                  id={item.id}
                  name={"Private?"}
                  checkMap={privateMap}
                  setCheckMap={setPrivateMap}
                />
              : <></>
            }
            <UploadCheckbox
              key={"upload" + item.id}
              id={item.id}
              name={item.name ?? item.id}
              checkMap={checkMap}
              setCheckMap={setCheckMap}
            />
          </div>
        })}
      </div>
    </div>
  )
}

const NEW_LISTING_NAME = "New Listing";
const modes = ["Entities", "Scripts", "Assets", "Apps", "Description"];
const privateModes = ["Scripts", "Apps"];

const isChecked = (key, map) => {
  return !(key in map) || map[key];
};

export default function UploadManager({ user, currentSite, setEditorModeHandler, targetListing, setTargetListingHandler,
  refreshOwnedItemsHandler, scenePropertyMap, entityPropertyMap, entityParentMap, overlayElements,
  cssProps, scripts, apps, getPublicURL }) {
  const [mode, setMode] = useState(modes[0]);

  const [assets, setAssets] = useState([]);
  const [listings, setListings] = useState([]);

  const [currentListing, setCurrentListing] = useState(targetListing);
  const [listingName, setListingName] = useState(NEW_LISTING_NAME);
  const [listingTags, setListingTags] = useState("");
  const [itemName, setItemName] = useState("");
  const [itemDescription, setItemDescription] = useState("");

  const [checkMap, setCheckMap] = useState({});
  const [privateMap, setPrivateMap] = useState({});
  const [selectedType, setSelectedType] = useState("Template");

  const onListingChanged = useCallback(async (newListing) => {
    setCurrentListing(newListing);
    let listing = listings.find((listing) => listing.id === newListing);
    setListingName(listing?.name ?? "");
    setListingTags(listing?.tags ?? "");

    if (listing?.versions?.length > 0) {
      const { data } = await supabase.from("marketplace_items").select("name,description")
        .eq("id", listing.versions[listing.versions.length - 1]).limit(1);
      if (data) {
        setItemName(data[0].name);
        setItemDescription(data[0].description);
      } else {
        setItemName("");
        setItemDescription("");
      }
    } else {
      setItemName("");
      setItemDescription("");
    }
  }, [listings]);

  // Often, our target listing is set before we've fetched the listings.
  // We defer the listing changed event so that the listings have time to load.
  useEffect(() => {
    if (listings.length > 0) {
      onListingChanged(targetListing);
    }
  }, [targetListing]);

  useEffect(() => {
    if (targetListing) {
      onListingChanged(targetListing);
    }
  }, [listings]);

  useEffect(() => {
    if (isChecked("Scene", checkMap) || isChecked("HTML", checkMap) || isChecked("CSS", checkMap)) {
      setSelectedType("Template");
      return;
    }

    let targetType = undefined;
    for (let entity of Object.keys(entityPropertyMap)) {
      if (isChecked(entity, checkMap)) {
        // Environments can have multiple entities
        targetType = "Environment";
      }
    }

    for (let script of scripts) {
      if (isChecked(script.id, checkMap)) {
        if (targetType) {
          setSelectedType("Template");
          return;
        }
        targetType = "Script";
      }
    }

    for (let asset of assets) {
      if (isChecked(asset.id, checkMap)) {
        if (targetType) {
          setSelectedType("Template");
          return;
        }
        targetType = "Asset";
      }
    }

    for (let app of apps) {
      if (isChecked(app.id, checkMap)) {
        if (targetType) {
          setSelectedType("Template");
          return;
        }
        targetType = "App";
      }
    }

    setSelectedType(targetType);
  }, [checkMap, assets]);

  useEffect(() => {
    const fetchData = async () => {
      {
        const NUM_ITEMS_PER_REQUEST = 50;

        let page = 0;
        let data = undefined;
        let results = [];
        do {
          data = (await supabase.storage.from("storage").list("sites/" + currentSite + "/private/", {
            limit: NUM_ITEMS_PER_REQUEST,
            offset: page * NUM_ITEMS_PER_REQUEST
          })).data;
          if (data && data.length > 0) {
            results = results.concat(data);
            page++;
          }
        } while (data && data.length > 0);

        if (results.length > 0) {
          setAssets(results);
        } else {
          setAssets([]);
        }
      }

      {
        const { data } = await supabase.from("marketplace_listings").select("*")
          .eq("creator", user?.user?.id).order("created_at", { ascending: false });
        if (data) {
          data.unshift({ name: NEW_LISTING_NAME, id: "new" });
          setListings(data);
        } else {
          setListings([]);
        }
      }
    }
    fetchData();
  }, []);

  const copyAssets = useCallback(async (listing, assetsData) => {
    const { data } = await supabase.from("marketplace_listings").select("versions").eq("id", listing).limit(1);
    if (data && data[0]?.versions?.length > 0) {
      const newItemID = data[0].versions[data[0].versions.length - 1];
      const promises = assetsData.map((asset) => {
        return supabase.storage.from("storage")
                .copy("sites/" + currentSite + "/private/" + asset,
                      "marketplace/" + newItemID + "/" + asset)
      });

      const results = await Promise.allSettled(promises);
      console.log(results);
      for (let result of results) {
        if (result?.value?.error) {
          console.log(result.value.error);
        }
      }
    }
  }, [currentSite]);

  const upload = useCallback(() => {
    let data = {};

    // TODO: warn if anything references asset link that's not included, or any asset that is included
    // is never referenced?
    if (isChecked("Scene", checkMap) && scenePropertyMap && Object.values(scenePropertyMap).length > 0) {
      data.scene = scenePropertyMap;
    }

    // Minify HTML, CSS, + JS + replace asset URLs
    // TODO: actually minify
    if (isChecked("HTML", checkMap) && overlayElements && overlayElements.length > 0) {
      data.overlay = overlayElements;
    }

    if (isChecked("CSS", checkMap) && cssProps && cssProps.length > 0) {
      data.css = cssProps;
    }

    // add_entities accepts parents by relative index, so we make sure that:
    // - parents come before children
    // - childrens' parent property is replaced by the difference in index
    // created_at and id are also removed because they get replaced
    if (entityParentMap && undefined in entityParentMap) {
      const entitiesData = [];
      const populateEntities = (entity) => {
        for (let child of entityParentMap[entity]) {
          if (isChecked(child, checkMap)) {
            entitiesData.push({...entityPropertyMap[child]});
          }
          if (child in entityParentMap) {
            populateEntities(child);
          }
        }
      };
      populateEntities(undefined);

      for (let i = 0; i < entitiesData.length; i++) {
        let entity = entitiesData[i];

        if ("parent" in entity) {
          const parentID = entity.parent;
          const parentIndex = entitiesData.findIndex((e) => { return e.id == parentID });
          if (parentIndex === -1) {
            delete entity.parent;
          } else {
            entity.parent = parentIndex - i;
          }
        }

        delete entity.created_at;
      }

      for (let entity of entitiesData) {
        delete entity.id;
      }

      if (entitiesData.length > 0) {
        data.entities = entitiesData;
      }
    }

    const scriptsData = [];
    for (const script of scripts) {
      if (isChecked(script.id, checkMap)) {
        let scriptProps = {...script};
        if (isChecked(script.id, privateMap)) {
          scriptProps.private = true;
        }
        delete scriptProps.id;
        delete scriptProps.created_at;
        scriptsData.push(scriptProps);
      }
    }

    if (scriptsData.length > 0) {
      data.scripts = scriptsData;
    }

    const appsData = [];
    for (const app of apps) {
      if (isChecked(app.id, checkMap)) {
        let appProps = {...app};
        if (isChecked(app.id, privateMap)) {
          appProps.private = true;
        }
        delete appProps.id;
        delete appProps.created_at;
        appsData.push(appProps);
      }
    }

    if (appsData.length > 0) {
      data.apps = appsData;
    }

    const assetsData = [];
    for (const asset of assets) {
      if (isChecked(asset.id, checkMap)) {
        assetsData.push(asset.name);
      }
    }

    if (selectedType === "Script") {
      data = data.scripts[0];
    } else if (selectedType === "App") {
      data = data.apps[0];
    } else if (selectedType === "Environment") {
      data = {
        "environment": data.entities
      }
    } else if (selectedType === "Asset") {
      data = {
        "asset": getPublicURL(assetsData[0])
      }
    }

    if (currentListing === "new") {
      supabase.rpc("add_listing", {
        site: currentSite,
        type: selectedType.toLowerCase(),
        listingname: listingName,
        listingtags: listingTags,
        itemname: itemName,
        itemdescription: itemDescription,
        data: data,
      }).then(async (result) => {
        console.log(result);
        if (!result.error) {
          copyAssets(result.data, assetsData);
          refreshOwnedItemsHandler();
          setEditorModeHandler("Marketplace");
          setTargetListingHandler(result.data);
        }
      });
    } else {
      supabase.rpc("update_listing", {
        site: currentSite,
        listing: currentListing,
        itemtype: selectedType.toLowerCase(),
        listingname: listingName,
        listingtags: listingTags,
        itemname: itemName,
        itemdescription: itemDescription,
        data: data,
      }).then(async (result) => {
        console.log(result);
        if (!result.error) {
          copyAssets(currentListing, assetsData);
          refreshOwnedItemsHandler();
          setEditorModeHandler("Marketplace");
          setTargetListingHandler(currentListing);
        }
      });
    }
  }, [checkMap, privateMap, scenePropertyMap, overlayElements, cssProps, entityParentMap, entityPropertyMap,
    scripts, apps, assets, selectedType, getPublicURL, currentSite, currentListing, listingName, listingTags,
    itemName, itemDescription, copyAssets, refreshOwnedItemsHandler, setEditorModeHandler,
    setTargetListingHandler]);

  const lists = useMemo(() => { return {
    "Scripts": scripts,
    "Assets": assets,
    "Apps": apps
  }}, [scripts, assets, apps]);

  return (
    <div
      className={styles.ui}
      style={{
        width: "90vw",
        height: "90vh",
        maxWidth: "1000px",
        maxHeight: "1000px",
        color: "#CCCCCC",
        background: "rgba(50, 50, 50, 0.7)",
        overflow: "auto",
        position: "relative"
      }}
      onClick={(e) => e.stopPropagation()}
    >
      <div
        className={styles.topRight}
      >
        <IconButton
          onClick={() => {
            setEditorModeHandler("Entities");
            setTargetListingHandler(undefined);
          }}
          sx={{
            color: "rgba(255, 255, 255, 0.4)"
          }}
        >
          <Close />
        </IconButton>
      </div>

      <p className={styles.unselectable}>Upload</p>
      {listings.length > 0 ?
        <TextField
          label="Listing"
          defaultValue={targetListing ?? listings[0].id}
          onChange={(e) => onListingChanged(e.target.value)}
          size="small"
          select
          sx={{
            background: "white"
          }}
        >
          {listings.map((listing) => (
            <MenuItem key={listing.id} value={listing.id}>{listing.name}</MenuItem>
          ))}
        </TextField>
        : <></>
      }
      <br />
      <br />
      <ControlledInput
        key={"listing" + currentListing}
        type="text"
        label="Listing Name"
        value={listingName}
        onChange={(e) => setListingName(e.target.value)}
        size="small"
        sx={{
          input: {
            backgroundColor: "white"
          }
        }}
      />
      <ControlledInput
        key={"item" + currentListing}
        type="text"
        label="Version Name"
        value={itemName}
        onChange={(e) => setItemName(e.target.value)}
        size="small"
        sx={{
          input: {
            backgroundColor: "white"
          }
        }}
      />
      <ControlledInput
        key={"tags" + currentListing}
        type="text"
        label="Tags"
        value={listingTags}
        onChange={(e) => setListingTags(e.target.value)}
        size="small"
        sx={{
          input: {
            backgroundColor: "white"
          }
        }}
      />
      <br />
      <br />
      <TabContext value={mode}>
        {modes.length > 1 ?
          <Tabs value={mode} onChange={(_, value) => setMode(value)} centered>
            {modes.map((modeValue) => {
              return <Tab key={modeValue} label={modeValue} value={modeValue} sx={{ color: "white" }} />
            })}
          </Tabs>
          : <></>
        }
        {modes.map((modeValue) => {
          return (
            <TabPanel key={modeValue} value={modeValue}>
              {modeValue === "Entities" ?
                <div style={{ textAlign: "left" }}>
                  <UploadCheckbox name="Scene" checkMap={checkMap} setCheckMap={setCheckMap} />
                  <UploadCheckbox name="HTML" checkMap={checkMap} setCheckMap={setCheckMap} />
                  <UploadCheckbox name="CSS" checkMap={checkMap} setCheckMap={setCheckMap} />
                  {Object.keys(entityPropertyMap).length > 0 ?
                    <>
                      <UploadCheckbox name="Entities" checkMap={checkMap} setCheckMap={setCheckMap} onChange={(checked, map) => {
                        for (let entity of Object.keys(entityPropertyMap)) {
                          map[entity] = checked;
                        }
                      }} />
                      <div
                        style={{
                          paddingLeft: "10px"
                        }}
                      >
                        <EntityList
                          entity={undefined}
                          entityPropertyMap={entityPropertyMap}
                          entityParentMap={entityParentMap}
                          checkMap={checkMap}
                          setCheckMap={setCheckMap}
                        />
                      </div>
                    </>
                    : <></>
                  }
                </div> : modeValue === "Description" ?
                <ControlledTextArea
                  key={"description" + currentListing}
                  style={{
                    width: "100%",
                    height: "300px"
                  }}
                  value={itemDescription}
                  onChange={(e) => setItemDescription(e.target.value)}
                /> :
                <ItemList
                  items={lists[modeValue]}
                  name={modeValue}
                  checkMap={checkMap}
                  setCheckMap={setCheckMap}
                  privateMap={privateModes.indexOf(modeValue) !== -1 ? privateMap : undefined}
                  setPrivateMap={privateModes.indexOf(modeValue) !== -1 ? setPrivateMap : undefined}
                />
              }
            </TabPanel>
          )
        })}
      </TabContext>
      <p>Selected type: {selectedType ?? "Nothing selected"}</p>
      <Button
        variant="contained"
        size="small"
        disabled={selectedType ? undefined : true}
        onClick={upload}
      >
        Upload!
      </Button>
      {currentListing && currentListing !== "new" ?
        <>
          <br />
          <Button
            variant="contained"
            size="small"
            color="secondary"
            onClick={() => {
              setEditorModeHandler("Listings");
              setTargetListingHandler(currentListing);
            }}
          >
            {"Go to listing >"}
          </Button>
        </>
        : <></>
      }
    </div>
  )
}