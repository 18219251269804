import "aframe";

const USE_NORMAL_GRID = false;

const gridVertexShader = `
varying vec4 colorOut;

void main() {
#if defined(USE_COLOR_ALPHA)
  colorOut = color;
#elif defined(USE_COLOR)
  colorOut = vec4(color, 1);
#endif

  int tickIndex = gl_VertexID / 4;
  int vertexIndex = gl_VertexID % 4;
  float vertexIndexF = float(vertexIndex);
  float i = float(tickIndex % minorDivisions);
  float j = float(tickIndex / minorDivisions);

  float halfSize = 0.5f * float(minorDivisions);
  // Grid of points
  vec3 pos = vec3(i - halfSize, 0, j - halfSize);

  // Center around the camera
  float minorDivisionsF = float(minorDivisions);
  float modCameraPosX = mod(cameraPosition.x, minorDivisionsF);
  float modCameraPosZ = mod(cameraPosition.z, minorDivisionsF);
  pos.x += minorDivisionsF * floor((cameraPosition.x - pos.x + halfSize) / minorDivisionsF);
  pos.z += minorDivisionsF * floor((cameraPosition.z - pos.z + halfSize) / minorDivisionsF);

  vec2 dist = abs(cameraPosition.xz - pos.xz) / halfSize;
  colorOut.a = 1.0f - (dist.x * dist.x + dist.y * dist.y);

  // Make it a tick mark
  pos.x += step(vertexIndexF, 1.5f) * 2.0f * (vertexIndexF - 0.5f) * tickSize;
  pos.z += step(1.5f, vertexIndexF) * 2.0f * (vertexIndexF - 2.5f) * tickSize;
  gl_Position = projectionMatrix * (modelViewMatrix * vec4(pos, 1.0));
}
`;

const gridFragmentShader = `
varying vec4 colorOut;

void main() {
  gl_FragColor = colorOut;
}
`;

AFRAME.registerComponent("grid", {
  schema: {
    minorDivisions: { type: "number", default: 10 },
    majorDivisions: { type: "number", default: 5 },
    majorColor: {
      type: "color",
      default: "#646464",
    },
    minorColor: {
      type: "color",
      default: "#C8C8C8",
    },
    tickSize: { type: "number", default: 0.1 },
  },

  init: function () {
    let vertices = [];
    const colors = [];
    let material;

    if (USE_NORMAL_GRID) {
      this.el.object3D.scale.set(this.data.minorDivisions, 1, this.data.minorDivisions);

      const step = 1.0 / this.data.minorDivisions;
      const halfSize = 0.5;
      for (
        let i = 0, k = -halfSize;
        i <= this.data.minorDivisions;
        i++, k += step
      ) {
        vertices.push(-0.5, 0, k, 0.5, 0, k);
        vertices.push(k, 0, -0.5, k, 0, 0.5);

        const color =
          i % this.data.majorDivisions === 0
            ? new THREE.Color(this.data.majorColor)
            : new THREE.Color(this.data.minorColor);
          colors.push(color.r, color.g, color.b, color.r, color.g, color.b);
          colors.push(color.r, color.g, color.b, color.r, color.g, color.b);
      }

      material = new THREE.LineBasicMaterial({
        vertexColors: true,
        toneMapped: false,
      });
    } else {
      vertices = new Array(12 * this.data.minorDivisions * this.data.minorDivisions).fill(0);
      for (let i = 0; i < this.data.minorDivisions; i++) {
        for (let j = 0; j < this.data.minorDivisions; j++) {

          const color =
            i % this.data.majorDivisions === 0 || j % this.data.majorDivisions === 0
              ? new THREE.Color(this.data.majorColor)
              : new THREE.Color(this.data.minorColor);
          for (let i = 0; i < 4; i++) {
            colors.push(color.r, color.g, color.b);
          }
        }
      }

      material = new THREE.ShaderMaterial({
        vertexColors: true,
        vertexShader: gridVertexShader,
        fragmentShader: gridFragmentShader,
        defines: {
          tickSize: this.data.tickSize,
          minorDivisions: this.data.minorDivisions
        }
      });
      material.transparent = true;
    }

    const geometry = new THREE.BufferGeometry();
    geometry.setAttribute(
      "position",
      new THREE.Float32BufferAttribute(vertices, 3)
    );
    geometry.setAttribute("color", new THREE.Float32BufferAttribute(colors, 3));

    this.el.object3D.add(new THREE.LineSegments(geometry, material));

    if (!USE_NORMAL_GRID) {
      this.el.object3D.traverse(function(child) {
        child.frustumCulled = false;
      });
    }
  },
});
