import { track } from "@vercel/analytics";

document.gydenceTrackEvent = (eventName, eventProps) => {
  track(eventName, eventProps);
}

export default function gydenceAPI({ currentSite, isEditing, isApp, isPublic, scenePropertyMap, entityPropertyMap,
                                     html, css, scripts, apps }) {
  let apiString = `
    window.GYDENCE = {};
    const GYDENCE = window.GYDENCE;
    GYDENCE.isEditing = ` + isEditing + `;
    GYDENCE.isApp = ` + isApp + `;
    GYDENCE.currentSite = "` + currentSite + `";

    const setupGydenceURLs = () => {
      GYDENCE.publicSiteURL = "` + window.location.origin + `/view/" + GYDENCE.currentSite;
      GYDENCE.privateSiteURL = "` + window.location.origin + `/preview/" + GYDENCE.currentSite;
      GYDENCE.assetURL = "https://zsrpzccdlyygmucesyzt.supabase.co/storage/v1/object/public/storage/sites/" + GYDENCE.currentSite + "` + (isPublic ? "/public" : "/private") + `/";
    };
    setupGydenceURLs();
  `;

  // Some functionality is only exposed during editing
  if (isEditing) {
    apiString += `
      GYDENCE.supabase = supabase.createClient("` + process.env.REACT_APP_NEXT_PUBLIC_SUPABASE_URL + '", "' + process.env.REACT_APP_NEXT_PUBLIC_SUPABASE_ANON_KEY + `");

      // Storage
      GYDENCE.listAssets = (options) => {
        return GYDENCE.supabase.storage.from("storage")
          .list("sites/" + GYDENCE.currentSite + "/private/", options);
      };
      GYDENCE.listAllAssets = async () => {
        const NUM_ITEMS_PER_REQUEST = 100;

        let page = 0;
        let data = [];
        let assets = [];
        do {
          data = (await GYDENCE.listAssets({
            limit: NUM_ITEMS_PER_REQUEST,
            offset: page * NUM_ITEMS_PER_REQUEST
          })).data;
          if (data && data.length > 0) {
            assets = assets.concat(data);
            page++;
          }
        } while (data && data.length > 0);

        return assets;
      };
      GYDENCE.uploadAsset = (name, asset, options) => {
        return GYDENCE.supabase.storage.from("storage")
          .upload("sites/" + GYDENCE.currentSite + "/private/" + name, asset, options ?? {
            cacheControl: "3600",
            upsert: true
          });
      };
      GYDENCE.renameAsset = (oldName, newName) => {
        return GYDENCE.supabase.storage.from("storage")
          .move("sites/" + GYDENCE.currentSite + "/private/" + oldName, "sites/" + GYDENCE.currentSite + "/private/" + newName);
      };
      GYDENCE.deleteAssets = (filenames) => {
        return GYDENCE.supabase.storage.from("storage")
          .remove(filenames.map((filename) => { return "sites/" + GYDENCE.currentSite + "/private/" + filename; }));
      };

      // Site properties
      window.addEventListener("message", (event) => {
        if (!event.data.type) {
          return;
        }

        switch (event.data.type) {
          case "api-update":
            GYDENCE.currentSite = event.data.currentSite;
            setupGydenceURLs();
            GYDENCE.scenePropertyMap = event.data.scenePropertyMap;
            GYDENCE.entityPropertyMap = event.data.entityPropertyMap;
            GYDENCE.overlayElements = event.data.overlayElements;
            GYDENCE.css = event.data.cssProps;
            GYDENCE.scripts = event.data.scripts;
            GYDENCE.apps = event.data.apps;
            break;
          default:
            break;
        }
      });

      GYDENCE.scenePropertyMap = ` + JSON.stringify(scenePropertyMap) + `;
      GYDENCE.getSceneProperty = (prop) => {
        return GYDENCE.scenePropertyMap[prop];
      };
      GYDENCE.editSceneProperty = (prop, value, forceUpdate = true) => {
        if (value === undefined) {
          return GYDENCE.supabase.rpc("remove_scene_property", {
            site: GYDENCE.currentSite,
            property: prop,
            forceupdate: JSON.stringify(forceUpdate)
          });
        } else {
          return GYDENCE.supabase.rpc("update_scene_property", {
            site: GYDENCE.currentSite,
            property: prop,
            value: value,
            forceupdate: JSON.stringify(forceUpdate)
          });
        }
      };
      GYDENCE.entityPropertyMap = ` + JSON.stringify(entityPropertyMap) + `;
      GYDENCE.getEntityProperty = (entityID, prop) => {
        return GYDENCE.entityPropertyMap[entityID]?.[prop];
      };
      GYDENCE.addEntity = (props, forceUpdate = true) => {
        return GYDENCE.addEntities([props], forceUpdate);
      };
      GYDENCE.addEntities = (propsArray, forceUpdate = true) => {
        return GYDENCE.supabase.rpc("add_entities", {
          site: GYDENCE.currentSite,
          propsarray: propsArray,
          forceupdate: JSON.stringify(forceUpdate)
        });
      };
      GYDENCE.editEntityProperty = (entityID, prop, value, forceUpdate = true) => {
        if (value === undefined) {
          return GYDENCE.supabase.rpc("remove_entity_property", {
            site: GYDENCE.currentSite,
            entity: entityID,
            property: prop,
            forceupdate: JSON.stringify(forceUpdate)
          });
        } else {
          return GYDENCE.supabase.rpc("update_entity_property", {
            site: GYDENCE.currentSite,
            entity: entityID,
            property: prop,
            value: value,
            forceupdate: JSON.stringify(forceUpdate)
          });
        }
      };
      GYDENCE.deleteEntity = (entityID, forceUpdate = true) => {
        return GYDENCE.supabase.rpc("delete_entity", {
          site: GYDENCE.currentSite,
          entity: entityID,
          forceupdate: JSON.stringify(forceUpdate)
        });
      };
      GYDENCE.overlayElements = ` + JSON.stringify(html) + `;
      GYDENCE.getOverlayElements = () => {
        return GYDENCE.overlayElements;
      };
      GYDENCE.editOverlayElements = (value, forceUpdate = true) => {
        return GYDENCE.supabase.rpc("update_overlay_elements", {
          site: GYDENCE.currentSite,
          value: value,
          forceupdate: JSON.stringify(forceUpdate)
        });
      };
      GYDENCE.css = ` + JSON.stringify(css) + `;
      GYDENCE.getCSS = () => {
        return GYDENCE.css;
      };
      GYDENCE.editCSS = (value, forceUpdate = true) => {
        return GYDENCE.supabase.rpc("update_css", {
          site: GYDENCE.currentSite,
          value: value,
          forceupdate: JSON.stringify(forceUpdate)
        });
      };
      GYDENCE.scripts = ` + JSON.stringify(scripts) + `;
      GYDENCE.getScripts = () => {
        return GYDENCE.scripts;
      };
      GYDENCE.editScript = (scriptID, prop, value, forceUpdate = true) => {
        return GYDENCE.supabase.rpc("update_script_property", {
          site: GYDENCE.currentSite,
          script: scriptID,
          property: prop,
          value: value,
          forceupdate: JSON.stringify(forceUpdate)
        });
      };
      GYDENCE.apps = ` + JSON.stringify(apps) + `;
      GYDENCE.getApps = () => {
        return GYDENCE.apps;
      };
      GYDENCE.editApp = (appID, prop, value, forceUpdate = true) => {
        return GYDENCE.supabase.rpc("update_app_property", {
          site: GYDENCE.currentSite,
          app: appID,
          property: prop,
          value: value,
          forceupdate: JSON.stringify(forceUpdate)
        });
      };`;
  }

  return apiString;
}