import { useEffect, useRef, useState, useCallback } from "react";

import { EditorView, keymap } from "@codemirror/view";
import { EditorState } from "@codemirror/state";
import { basicSetup } from "codemirror";
import { indentWithTab } from "@codemirror/commands";

import { TextField } from "@mui/material";

// from: https://stackoverflow.com/questions/46000544/react-controlled-input-cursor-jumps
export function ControlledInput({ value, onChange, ...rest }) {
  // FIXME: this value will be wrong if onChange/updatePropHandler fails
  const [currValue, setCurrValue] = useState(undefined);
  const ref = useRef(undefined);

  useEffect(() => {
    if (value === currValue) {
      setCurrValue(undefined);
    }
  }, [value, currValue]);

  const handleChange = useCallback((e) => {
     setCurrValue(e.target.value);
     onChange && onChange(e);
  }, [onChange]);

  return <TextField ref={ref} value={currValue ?? value} onChange={handleChange} {...rest} />;
};

export function ControlledTextArea({ value, onChange, ...rest }) {
  // FIXME: this value will be wrong if onChange/updatePropHandler fails
  const [currValue, setCurrValue] = useState(undefined);
  const [cursor, setCursor] = useState(undefined);
  const ref = useRef(undefined);

  useEffect(() => {
     const input = ref.current;
     if (input) {
       input.setSelectionRange(cursor, cursor);
     }
  }, [ref, cursor, value]);

  useEffect(() => {
    if (value === currValue) {
      setCurrValue(undefined);
    }
  }, [value, currValue]);

  const handleChange = useCallback((e) => {
     setCurrValue(e.target.value);
     setCursor(e.target.selectionStart);
     onChange && onChange(e);
  }, [onChange]);

  return <textarea ref={ref} value={currValue ?? value} onChange={handleChange} {...rest} />;
};

export function ControlledEditor({ value, onChange, readOnly, extensions, ...rest }) {
  // FIXME: support multi-user editing
  const ref = useRef(undefined);
  const view = useRef(undefined);

  let allExtensions = [
    EditorView.updateListener.of((update) => {
      if (update.docChanged && onChange) {
        onChange(update.state.doc.toString());
      }
    }),
    basicSetup,
    keymap.of([indentWithTab]),
  ];

  if (extensions) {
    allExtensions = allExtensions.concat(extensions);
  }

  if (readOnly) {
    allExtensions = allExtensions.concat(EditorView.contentAttributes.of({ contenteditable: false }));
  }

  useEffect(() => {
    view.current = new EditorView({
      state: EditorState.create({
        doc: value,
        extensions: allExtensions,
      }),
      parent: ref.current,
    });

    return () => {
      view.current.destroy();
      view.current = null;
    };
  }, []);

  return <div ref={ref} {...rest} />;
}