import "aframe";

import { rematerial } from "./utils";

AFRAME.registerComponent("stencil-surface", {
  schema: {
    mask: { default: 1 },
    order: { default: 1 },
  },

  init: function () {
    const self = this;
    this.el.addEventListener("model-loaded", function () { self.updateMaterials(self); });
    this.el.addEventListener("gltf-loaded", function () { self.updateMaterials(self); });
    this.el.addEventListener("object3dset", function () { self.updateMaterials(self); });
    self.updateMaterials(self);
  },

  update: function () {
    const self = this;
    self.updateMaterials(self);
  },

  updateMaterials: function (self) {
    rematerial(self, () => true, (node) => {
      node.renderOrder = self.data.order;
      if (node.material) {
        node.material.colorWrite = false;
        node.material.depthWrite = false;
        node.material.stencilWrite = true;
        node.material.stencilRef = self.data.mask;
        node.material.stencilFuncMask = 0xFF;
        node.material.stencilFunc= THREE.AlwaysStencilFunc;
        node.material.stencilFail= THREE.ReplaceStencilOp;
        node.material.stencilZFail= THREE.ReplaceStencilOp;
        node.material.stencilZPass= THREE.ReplaceStencilOp;
      }
    }, self);
  },
});

AFRAME.registerComponent("stencil-member", {
  schema: {
    mask: { default: 1 },
    order: { default: 2 },
  },

  init: function () {
    const self = this;
    self.el.addEventListener("model-loaded", function () { self.updateMaterials(self); });
    self.el.addEventListener("gltf-loaded", function () { self.updateMaterials(self); });
    this.el.addEventListener("object3dset", function () { self.updateMaterials(self); });
    self.updateMaterials(self);
  },

  update: function () {
    const self = this;
    self.updateMaterials(self);
  },

  updateMaterials: function (self) {
    rematerial(self, () => true, (node) => {
      node.renderOrder = self.data.order;
      if (node.material) {
        node.material.colorWrite = true;
        node.material.depthWrite = true;
        node.material.stencilWrite = true;
        node.material.stencilRef = self.data.mask;
        node.material.stencilFuncMask = 0xFF;
        node.material.stencilFunc = THREE.EqualStencilFunc;
        node.material.stencilFail = THREE.KeepStencilOp;
        node.material.stencilZFail = THREE.KeepStencilOp;
        node.material.stencilZPass = THREE.ReplaceStencilOp;
      }
    }, self);
  },
});
