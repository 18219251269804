import "aframe";
import { useEffect, useState, useMemo, useCallback } from "react";

import "../gydence/utils";
import "../gydence/anchor";
import "../gydence/animations";
import "../gydence/camera";
import "../gydence/cameraSource";
import "../gydence/container";
import "../gydence/depth-estimation";
import "../gydence/environmentMap";
import "../gydence/follow";
import "../gydence/grid";
import "../gydence/html";
import "../gydence/imageSource";
import "../gydence/list";
import "../gydence/logo";
import "../gydence/material";
import "../gydence/model";
import "../gydence/networking";
import "../gydence/ring";
import "../gydence/selectionTool";
import "../gydence/spawner";
import "../gydence/stencil";
import "../gydence/testing";
import "../gydence/text3d";
import "../gydence/tfjs-hand-tracking";

import { EntityHierarchyErrorRender } from "./entityList";

import styles from "./index.module.css";

const dragStart = {};
const dragScaleStart = {};
let prevValue = undefined;

const entityPos = new THREE.Vector3();
const entityScale = new THREE.Vector3();
const cameraPos = new THREE.Vector3();
const dragDir = new THREE.Vector3();

export default function EditorView() {
  // Site/entity selection
  const [selectedObject, setSelectedObject] = useState(undefined);

  // Scene/entity state maps
  const [scenePropertyMap, setScenePropertyMap] = useState({});
  const [environmentEntities, setEnvironmentEntities] = useState(undefined);
  const [entityPropertyMap, setEntityPropertyMap] = useState({});
  const [entityParentMap, setEntityParentMap] = useState({});
  const [entityErrorMap, setEntityErrorMap] = useState({});

  const cameraChildren = useMemo(() => {
    const cameraChildrenData = new Set();
    for (const entityProps of Object.values(entityPropertyMap)) {
      const parent = entityProps.parent;
      if (parent != undefined && parent.toLowerCase() === "camera") {
        cameraChildrenData.add(entityProps.parent);
      }
    }
    return cameraChildrenData;
  }, [entityPropertyMap]);

  // 2D UI
  const [overlayElements, setOverlayElements] = useState(undefined);
  const [cssProps, setCSSProps] = useState(undefined);

  const [is2DSelection, setIs2DSelection] = useState(false);
  const [hasLoadedScripts, setHasLoadedScripts] = useState(false);

  useEffect(() => {
    if (hasLoadedScripts) {
      const event = new Event("DOMContentLoaded");
      document.dispatchEvent(event);
    }
  }, [hasLoadedScripts]);

  const setEntityErrorHandler = useCallback((error) => {
    // TODO: this should accept the id, which would also make it work for the environment
    // Keep track of any errors
    let currentEntityErrorMap = {...entityErrorMap};
    if (!(selectedObject in currentEntityErrorMap)) {
      currentEntityErrorMap[selectedObject] = [];
    }
    currentEntityErrorMap[selectedObject].push(error);
    setEntityErrorMap(currentEntityErrorMap);
  }, [entityErrorMap, selectedObject]);

  const messageHandler = useCallback((event) => {
    if (!event.data.type) {
      return;
    }

    switch (event.data.type) {
      case "data-update":
        setScenePropertyMap(event.data.scenePropertyMap);
        setEntityPropertyMap(event.data.entityPropertyMap);
        setEntityParentMap(event.data.entityParentMap);
        setOverlayElements(event.data.overlayElements);
        setCSSProps(event.data.cssProps);
        break;
      case "environment-update":
        setEnvironmentEntities(event.data.environment);
      case "select-object":
        setSelectedObject(event.data.selectedObject);
        break;
      case "scripts-loaded":
        setHasLoadedScripts(true);
        break;
      default:
        break;
    }
  }, []);

  const gydenceMessageHandler = useCallback((e) => {
    switch (e.detail.type) {
      case "editing-changed":
        setIs2DSelection(document.is2DSelection);
        break;
      case "select-object":
        setSelectedObject(e.detail.selectedObject);
        window.parent.postMessage(e.detail, "*");
        break;
      case "drag-start":
        dragStart.x = e.detail.pos.x;
        dragStart.y = e.detail.pos.y;
        break;
      case "entity-edit":
        window.parent.postMessage(e.detail, "*");
        break;
    }
  }, []);

  const handleMouseMove = useCallback((e) => {
    if (dragStart.x !== undefined && Math.abs(e.clientX - dragStart.x) < 5 && Math.abs(e.clientY - dragStart.y) < 5) {
      return;
    }

    if (selectedObject !== undefined && dragStart.x !== undefined) {
      const el = document.querySelector("#entity_" + selectedObject);
      if (document.isKeyPressed("Control")) {
        prevValue = { x: el.object3D.scale.x, y: el.object3D.scale.y, z: el.object3D.scale.z };
        entityScale.copy(el.object3D.scale);
        if (dragScaleStart.x === undefined) {
          dragScaleStart.x = entityScale.x;
          dragScaleStart.y = entityScale.y;
          dragScaleStart.z = entityScale.z;
        }

        el.object3D.getWorldPosition(entityPos);
        entityPos.project(el.sceneEl.camera);
        entityPos.setX(window.innerWidth * 0.5 * (entityPos.x + 1));
        entityPos.setY(window.innerHeight * -0.5 * (entityPos.y - 1));
        let distance = Math.sqrt(Math.pow(e.clientX - entityPos.x, 2) + Math.pow(e.clientY - entityPos.y, 2)) /
          Math.sqrt(Math.pow(dragStart.x - entityPos.x, 2) + Math.pow(dragStart.y - entityPos.y, 2));
        distance = Math.max(0.1, Math.min(5, distance));
        el.object3D.scale.copy(dragScaleStart);
        el.object3D.scale.multiplyScalar(distance);

        if (Math.abs(prevValue.x - el.object3D.scale.x) > 1e-6 || Math.abs(prevValue.y - el.object3D.scale.y) > 1e-6 ||
          Math.abs(prevValue.z - el.object3D.scale.z) > 1e-6) {
          const event = new CustomEvent("gydence-message", { detail: { type: "entity-edit", entity: selectedObject,
            prop: "scale", value: el.object3D.scale.x + " " + el.object3D.scale.y + " " + el.object3D.scale.z }});
          document.dispatchEvent(event);
        }
      } else {
        prevValue = { x: el.object3D.position.x, y: el.object3D.position.y, z: el.object3D.position.z };
        el.object3D.getWorldPosition(entityPos);
        el.sceneEl.camera.getWorldPosition(cameraPos);
        dragDir.set(2.0 * e.clientX / window.innerWidth - 1, -2.0 * e.clientY / window.innerHeight + 1, 0.5).unproject(el.sceneEl.camera).sub(cameraPos).normalize();

        let t = undefined;
        if (!document.isKeyPressed("Shift")) {
          if (Math.abs(dragDir.y) > 1e-6) {
            t = (entityPos.y - cameraPos.y) / dragDir.y;
          }
        } else {
          if (Math.abs(dragDir.z) > 1e-6) {
            t = (entityPos.z - cameraPos.z) / dragDir.z;
          }
        }

        if (t) {
          entityPos.copy(dragDir);
          entityPos.multiplyScalar(t);
          entityPos.add(cameraPos);
          if (el.object3D.parent) {
            el.object3D.parent.worldToLocal(entityPos);
          }
          if (document.isKeyPressed("Shift")) {
            el.object3D.position.setY(entityPos.y);
          } else {
            el.object3D.position.copy(entityPos);
          }

          if (Math.abs(prevValue.x - el.object3D.position.x) > 1e-6 || Math.abs(prevValue.y - el.object3D.position.y) > 1e-6 ||
            Math.abs(prevValue.z - el.object3D.position.z) > 1e-6) {
            const event = new CustomEvent("gydence-message", { detail: { type: "entity-edit", entity: selectedObject,
              prop: "position", value: el.object3D.position.x + " " + el.object3D.position.y + " " + el.object3D.position.z }});
            document.dispatchEvent(event);
          }
        }
      }
    }
  }, [selectedObject]);

  const handleMouseUp = useCallback(() => {
    dragStart.x = undefined;
    dragScaleStart.x = undefined;
  }, []);

  useEffect(() => {
    const injectedCSSID = "injectedCSS";
    const existingStyleSheet = document.querySelector("#" + injectedCSSID);
    const stylesheet = existingStyleSheet ?? document.createElement("style");
    stylesheet.id = injectedCSSID;
    stylesheet.innerHTML = cssProps;
    if (!existingStyleSheet) {
      document.head.appendChild(stylesheet);
    }
  }, [cssProps]);

  useEffect(() => {
    window.addEventListener("message", messageHandler);
    document.addEventListener("gydence-message", gydenceMessageHandler);
    document.addEventListener("mouseup", handleMouseUp);
    document.addEventListener("mousemove", handleMouseMove);

    return () => {
      window.removeEventListener("message", messageHandler);
      document.removeEventListener("gydence-message", gydenceMessageHandler);
      document.removeEventListener("mouseup", handleMouseUp);
      document.removeEventListener("mousemove", handleMouseMove);
    }
  }, [messageHandler, gydenceMessageHandler, handleMouseUp, handleMouseMove]);

  return (
    <>
      {/* TODO: handle scene error */}
      {hasLoadedScripts ?
        <a-scene
          {...scenePropertyMap}
        >
          {/* <div data-include="views/editor" /> */}
          {/* <!-- Selection Tool Tool --> */}
          <a-entity
            id="selectionTool"
            key={selectedObject}
            selection-tool={(selectedObject && (selectedObject !== "Scene")) ? ("target: #entity_" + selectedObject) : "target: ;"}
          ></a-entity>

          {/* <!-- Camera(s) + UI --> */}
          <a-entity id="camera-container" gydence-controls="" cursor="rayOrigin: mouse">
            <a-entity id="camera" camera="">
              {[...cameraChildren].map((child) => {
                return <EntityHierarchyErrorRender
                  key={child}
                  entity={child}
                  entityPropertyMap={entityPropertyMap}
                  entityParentMap={entityParentMap}
                  entityErrorMap={entityErrorMap}
                  setEntityErrorHandler={setEntityErrorHandler}
                />
              })}
            </a-entity>
          </a-entity>

          {/* <!-- Person --> */}
          <a-entity id="person" class="removeOnMobile" position="0 1.6 1">
            <a-entity
              id="hmd"
              position="0 0 -0.25"
              scale="0.1 0.1 0.1"
              gltf-model={process.env.PUBLIC_URL + "/assets/glasses.glb"}
              rematerial="material: #C27BA0"
            ></a-entity>
            <a-entity
              id="leftHand"
              position="-0.27 -0.5 -0.4"
              rotation="0 -65 50"
              scale="1 1 -1"
              gltf-model={process.env.PUBLIC_URL + "/assets/hand.glb"}
              rematerial="material: #ffb3d8, emissive #444"
            ></a-entity>
            <a-entity
              id="rightHand"
              position="0.27 -0.5 -0.4"
              rotation="0 -115 50"
              gltf-model={process.env.PUBLIC_URL + "/assets/hand.glb"}
              rematerial="material: #ffb3d8, emissive #444"
            ></a-entity>
          </a-entity>

          {/* <!-- Environment --> */}
          {environmentEntities ?
            environmentEntities.map((entity, i) => {
              entity.id = i;
              return <a-entity key={entity.id} {...entity}></a-entity>
            })
            : <a-entity>
                <a-entity grid="minorDivisions:30;majorDivisions:5"></a-entity>
                <a-entity light="type:ambient;color:#BBB"></a-entity>
                <a-entity light="type:directional;color:#FFF;intensity:0.6" position="-0.5 1 1"></a-entity>
                <a-sky color="#FFFFFF"></a-sky>
            </a-entity>
          }

          {/* <!-- Content --> */}
          {entityPropertyMap ?
            <EntityHierarchyErrorRender
              entity={undefined}
              entityPropertyMap={entityPropertyMap}
              entityParentMap={entityParentMap}
              entityErrorMap={entityErrorMap}
              setEntityErrorHandler={setEntityErrorHandler}
            />
            : <></>
          }
        </a-scene>
        : <></>
      }

      <div
        id="overlayDOM"
        className={styles.block}
        style={{ position: "absolute", top: "0px", width: "100vw", height: "100vh", padding: "0px", pointerEvents: is2DSelection ? undefined : "none" }}
      >
        {(hasLoadedScripts && overlayElements) ?
          <div
            className={styles.block}
            dangerouslySetInnerHTML={{ __html: overlayElements }}
          />
          : <></>
        }
      </div>
    </>
  )
}