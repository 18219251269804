import { useEffect, useState, useCallback } from "react";
import QRCode from "react-qr-code";
import { Button, IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";

import { getComponentOrSystemProp } from "./propertyList";
import { supabase } from "./signin";

import styles from "./index.module.css";

export default function PublishSite({ currentSite, scenePropertyMap, entityPropertyMap, overlayElements, cssProps,
    scripts, preview, setEditorModeHandler, editorViewFrame }) {
  const [loaded, setLoaded] = useState(false);

  const publishSite = useCallback(async () => {
    const NUM_ITEMS_PER_REQUEST = 50;
    {
      let page = 0;
      let data = [];
      do {
        data = (await supabase.storage.from("storage").list("sites/" + currentSite + "/public/", {
          limit: NUM_ITEMS_PER_REQUEST,
          offset: page * NUM_ITEMS_PER_REQUEST
        })).data;
        if (data && data.length > 0) {
          const assets = data.map((asset) => {
            return "sites/" + currentSite + "/public/" + asset.name;
          });
          await supabase.storage.from("storage").remove(assets);
          page++;
        }
      } while (data && data.length > 0);
    }

    {
      let page = 0;
      let data = [];
      let promises = [];
      do {
        data = (await supabase.storage.from("storage").list("sites/" + currentSite + "/private/", {
          limit: NUM_ITEMS_PER_REQUEST,
          offset: page * NUM_ITEMS_PER_REQUEST
        })).data;
        if (data && data.length > 0) {
          promises.push(...data.map((asset) => {
            return supabase.storage.from("storage")
                    .copy("sites/" + currentSite + "/private/" + asset.name,
                          "sites/" + currentSite + "/public/" + asset.name)
          }));
          page++;
        }
      } while (data && data.length > 0);

      let results = await Promise.allSettled(promises);
      for (let result of results) {
        if (result?.value?.error) {
          console.log(result.value.error);
        }
      }
    }

    let data = {
      // TODO: handle version properly
      version: 1
    };

    // Replace asset URLS in scene and entities
    if (scenePropertyMap && Object.values(scenePropertyMap).length > 0) {
      let scenePropertyMapString = JSON.stringify(scenePropertyMap);
      scenePropertyMapString = scenePropertyMapString.replaceAll(currentSite + "/private", currentSite + "/public");
      data.scene = JSON.parse(scenePropertyMapString);
    }

    if (entityPropertyMap && Object.values(entityPropertyMap).length > 0) {
      let entityPropertyMapString = JSON.stringify(entityPropertyMap);
      entityPropertyMapString = entityPropertyMapString.replaceAll(currentSite + "/private", currentSite + "/public");
      data.entities = Object.values(JSON.parse(entityPropertyMapString));
    }

    // Minify HTML, CSS, + JS + replace asset URLs
    // TODO: actually minify
    if (overlayElements && overlayElements.length > 0) {
      data.overlay = overlayElements.replaceAll(currentSite + "/private", currentSite + "/public");
    }

    if (cssProps && cssProps.length > 0) {
      data.css = cssProps.replaceAll(currentSite + "/private", currentSite + "/public");
    }

    if (scripts && scripts.length > 0) {
      let minifiedScripts = [];
      for (const script of scripts) {
        let minifiedScript = {...script};
        if (minifiedScript.script && minifiedScript.script.length > 0) {
          minifiedScript.script = minifiedScript.script.replaceAll(currentSite + "/private", currentSite + "/public");
        }
        minifiedScripts.push(minifiedScript);
      }
      data.scripts = minifiedScripts;
    }

    supabase.from("public").upsert({
      id: currentSite,
      data: data
    }).then((result) => {
      if (!result.error) {
        setLoaded(true);
      }
    });
  }, [currentSite, scenePropertyMap, entityPropertyMap, overlayElements, cssProps, scripts]);

  useEffect(() => {
    if (!loaded) {
      if (preview) {
        setLoaded(true);
      } else {
        publishSite();
      }
    }
  }, []);

  const url = window.location.origin + (preview ? "/preview/" : "/view/") + currentSite + window.location.search;

  let allowList = new Set();
  if (!preview) {
    const allSystems = editorViewFrame?.contentWindow?.AFRAME?.systems ?? AFRAME.systems;
    const allComponents = editorViewFrame?.contentWindow?.AFRAME?.components ?? AFRAME.components;
    {
      const element = editorViewFrame?.contentWindow?.document?.querySelector("#scene");
      for (const prop of Object.keys(scenePropertyMap)) {
        if (prop in allSystems) {
          const requiredAllowList = getComponentOrSystemProp(element, true, allSystems, allComponents, prop, "requiredAllowList");
          if (requiredAllowList) {
            for (const allowProp of requiredAllowList) {
              allowList.add(allowProp);
            }
          }
        }
      }
    }

    for (const entity of Object.keys(entityPropertyMap)) {
      const entityProps = entityPropertyMap[entity];
      const element = editorViewFrame?.contentWindow?.document?.querySelector("#entity_" + entityProps.id);
      for (const prop of Object.keys(entityProps)) {
        if (prop in allComponents) {
          const requiredAllowList = getComponentOrSystemProp(element, false, allSystems, allComponents, prop, "requiredAllowList");
          if (requiredAllowList) {
            for (const allowProp of requiredAllowList) {
              allowList.add(allowProp);
            }
          }
        }
      }
    }
  }

  return (
    <div
      className={styles.ui}
      style={{
        width: "90vw",
        height: "90vh",
        maxWidth: "700px",
        maxHeight: "500px",
        color: "#CCCCCC",
        background: "rgba(50, 50, 50, 0.7)",
        overflow: "auto",
        position: "relative"
      }}
      onClick={(e) => e.stopPropagation()}
    >
      <div
        className={styles.topRight}
      >
        <IconButton
          onClick={() => { setEditorModeHandler("Entities"); }}
          sx={{
            color: "rgba(255, 255, 255, 0.4)"
          }}
        >
          <Close />
        </IconButton>
      </div>

      <p className={styles.unselectable}>{preview ? "Preview" : "Publish"}</p>

      <div
          style={{
            width: "100%",
            height: "90vh",
            maxHeight: "425px",
            position: "relative"
          }}
        >
        {!loaded ?
          <div className={styles.centered}>Loading...</div>
          : <div>
            <div
              style={{
                left: "128px",
                top: "178px"
              }}
              className={styles.topLeft}
            >
              <Button
                variant="contained"
                onClick={() => { window.open(url, "_blank"); }}
              >
                Open Site
              </Button>
            </div>
            <div
              style={{
                right: "128px",
                top: "50px"
              }}
              className={styles.topRight}
            >
              <QRCode value={url} />
              <br />
              <span>Scan to view on your phone.</span>
            </div>

            {!preview ?
              <div
                style={{
                  width: "100%"
                }}
                className={styles.bottomMiddle}
              >
                <span>To add to your website, insert this HTML snippet:</span>
                <br />
                <br />
                <code>{"<iframe src=\"" + url + "\"" + (allowList.size > 0 ? " allow=\"" + [...allowList].join(";") + "\"" : "") + " />"}</code>
                <br />
                <br />
              </div>
              : <></>
            }
          </div>
        }
      </div>
    </div>
  )
}