import { useState, useEffect, useRef, useMemo, useCallback } from "react";

import { css } from "@codemirror/lang-css";
import { html } from "@codemirror/lang-html";
import { javascript } from "@codemirror/lang-javascript";

import { Button, Divider, FormControlLabel, FormGroup, IconButton, ListItemIcon, Menu, MenuItem, Stack, Switch, Tab, Tabs, TextField } from "@mui/material";
import { Add, Check, MoreVert, Refresh, Remove } from "@mui/icons-material";
import { TabContext, TabPanel } from "@mui/lab";

import { debounce } from "../gydence/utils";
import { ControlledInput, ControlledEditor } from "../components/controlledInput";
import gydenceAPI from "./api";

import styles from "./index.module.css";

const buildOption = (oneOfOption, prefix) => {
  return (
    <MenuItem key={prefix + oneOfOption} value={oneOfOption}>{oneOfOption}</MenuItem>
  )
};

function PropValue({ name, value, propSchema, isPrivate, isReadOnly, onChange }) {
  const oneOf = propSchema?.oneOf;

  return (
    <>
      {oneOf ?
        <TextField
          label={name}
          value={value}
          onChange={(e) => onChange(name, e.target.value)}
          size="small"
          select
        >
          {Array.isArray(oneOf) ?
            oneOf.map((option) => buildOption(option, "option_" + name + "_"))
            : Object.keys(oneOf).map((option) => buildOption(option, "option_" + name + "_"))}
        </TextField>
        : (propSchema?.type === "boolean" || (propSchema?.default && typeof propSchema.default === "boolean")) ?
          <FormControlLabel
            control={
              <Switch
                checked={(typeof value === "boolean" && value) || value === "true"}
                onChange={(e) => onChange(name, e.target.checked)}
                size="small"
              />
            }
            label={name}
            labelPlacement="start"
          />
        : <ControlledInput
          type="text"
          label={name}
          value={isPrivate ? "<Private>" : value}
          disabled={isReadOnly}
          onChange={!isReadOnly ? (e) => onChange(name, e.target.value) : undefined}
          size="small"
        />
      }
    </>
  )
}

function SubPropEditor({ propSchema, prop, subProp, subProps, privateSubProps, updatePropHandler }) {
  const subPropChangeHandler = useCallback((subPropName, newSubPropValue) => {
    let constructedValue = "";
    for (const subPropNameAndValue of subProps) {
      if (subPropNameAndValue.name === subPropName) {
        constructedValue += subPropNameAndValue.name + ":" + newSubPropValue + ";";
      } else {
        constructedValue += subPropNameAndValue.name + ":" + subPropNameAndValue.value + ";";
      }
    }
    updatePropHandler(prop, constructedValue);
  }, [subProps, prop, updatePropHandler]);

  const subPropDeleteHandler = useCallback((subPropName) => {
    let constructedValue = "";
    for (const subPropNameAndValue of subProps) {
      if (subPropNameAndValue.name !== subPropName) {
        constructedValue += subPropNameAndValue.name + ":" + subPropNameAndValue.value + ";";
      }
    }
    updatePropHandler(prop, constructedValue);
  }, [subProps, prop, updatePropHandler]);

  const isPrivateSubProp = useMemo(() => {
    privateSubProps.indexOf(subProp.name) !== -1
  }, [privateSubProps, subProp]);

  return (
    <div>
      <PropValue
        name={subProp.name}
        value={subProp.value}
        propSchema={propSchema}
        isPrivate={isPrivateSubProp}
        isReadOnly={isPrivateSubProp}
        onChange={subPropChangeHandler}
      />
      <IconButton
        onClick={() => subPropDeleteHandler(subProp.name)}
      >
        <Remove />
      </IconButton>
    </div>
  )
};

export const getComponentOrSystemProp = (element, isScene, allSystems, allComponents, prop, componentProp) => {
  let value = undefined;
  if (isScene) {
    value = element?.systems?.[prop]?.prototype?.[componentProp] ?? allSystems[prop]?.prototype?.[componentProp];
  }
  if (value === undefined) {
    value = element?.components?.[prop]?.[componentProp] ?? allComponents[prop]?.[componentProp];
  }

  return value;
};

const readOnlyProps = ["id", "created_at"];

function PropEditor({ prop, propValue, subProps, privateProps, updatePropHandler, removePropHandler, isScene,
    allComponents, allSystems, element }) {
  const isAframeProp = useMemo(() => {
    return (isScene && allSystems[prop]) || allComponents[prop];
  }, [isScene, allSystems, allComponents, prop]);
  const schema = useMemo(() => {
    return getComponentOrSystemProp(element, isScene, allSystems, allComponents, prop, "schema");
  }, [element, isScene, allSystems, allComponents, prop]);
  const isSingleProp = useMemo(() => {
    return getComponentOrSystemProp(element, isScene, allSystems, allComponents, prop, "isSingleProperty");
  }, [element, isScene, allSystems, allComponents, prop]);

  const unusedSubProps = useMemo(() => {
    if (schema) {
      return Object.keys(schema)
        .filter((subProperty) => {
          return subProperty !== "schemaChange" &&
            !subProps.some((subProp) => { return subProp.name && subProp.name === subProperty});
        });
    }

    return [];
  }, [schema, subProps]);

  const [isPrivateProp, privateSubProps] = useMemo(() => {
    let isPrivateData = false;
    let privateSubPropsData = [];
    for (let i = 0; i < privateProps.length; i++) {
      let privateProp = privateProps[i];
      if (privateProp.length > 0 && privateProp[0] === prop) {
        isPrivateData = true;
        if (privateProp.length > 1) {
          privateSubPropsData.push(privateProp[1]);
        }
      }
    }
    return [isPrivateData, privateSubPropsData];
  }, [privateProps, prop]);

  const isNonDeletable = readOnlyProps.indexOf(prop) !== -1;
  const isReadOnly = isNonDeletable || isPrivateProp;

  return (
    <div>
      <label className={styles.unselectable}>{prop}: </label>
      {!isNonDeletable ?
        <IconButton
          onClick={() => removePropHandler(prop)}
        >
          <Remove />
        </IconButton>
        : <></>
      }
      <br className={styles.unselectable}/>
      {(!isAframeProp || isSingleProp) ?
        <PropValue
          name={prop}
          value={propValue}
          propSchema={schema}
          isPrivate={isPrivateProp}
          isReadOnly={isReadOnly}
          onChange={updatePropHandler}
        />
        :
        <div>
          {subProps.map((subProp) => {
            return <SubPropEditor
                      key={[prop, "_", subProp.name].join("")}
                      propSchema={schema[subProp.name]}
                      prop={prop}
                      subProp={subProp}
                      subProps={subProps}
                      privateSubProps={privateSubProps}
                      updatePropHandler={updatePropHandler}
                    />
          })}
        </div>
      }

      {(isAframeProp && !isSingleProp && unusedSubProps.length > 0) ?
        <div key={["div3_", prop].join("")}>
          <TextField
            id={["addSubProperty_", prop].join("")}
            name={["addSubProperty_", prop].join("")}
            label="Add sub-property"
            defaultValue={unusedSubProps[0]}
            variant="filled"
            size="small"
            select
          >
            {unusedSubProps.map((option) => buildOption(option, prop + "_"))}
          </TextField>
          <IconButton
            onClick={() => {
              let constructedValue = "";
              for (const subPropNameAndValue of subProps) {
                constructedValue += subPropNameAndValue.name + ":" + subPropNameAndValue.value + ";";
              }
              const newSubProp = document.querySelector("#addSubProperty_" + prop).innerHTML;
              constructedValue += newSubProp + ":" + schema[newSubProp]?.default + ";";
              updatePropHandler(prop, constructedValue);
            }}
          >
            <Add />
          </IconButton>
          <br key={["br2_", prop].join("")} className={styles.unselectable} />
        </div>
        : <></>
      }

      <Divider />
    </div>
  )
};

const priority = [
  "name",
  "id",
  "parent",
  "created_at",
  "position",
  "rotation",
  "scale"
];

const filteredProps = ["created_at", "privateProps"];

// TODO: automate this list, use component property?
// from: https://github.com/aframevr/aframe/tree/v1.3.0/src/components/scene
// doesn't handle scene components from imported scripts
const sceneProps = [
  "ar-hit-test",
  "background",
  "debug",
  "device-orientation-permission-ui",
  "embedded",
  "fog",
  "inspector",
  "keyboard-shortcuts",
  "pool",
  "reflection",
  "screenshot",
  "stats",
  "vr-mode-ui"
];

export default function EntityPropertyList({ props, updatePropHandler, removePropHandler, isScene, editorViewFrame }) {
  const sortedPropKeys = useMemo(() => {
    let result = Object.keys(props).sort((left, right) => {
      const index1 = priority.indexOf(left);
      const index2 = priority.indexOf(right);

      if (index1 === -1 && index2 === -1) {
        return (left < right) ? -1 : 1;
      } else if (index1 === -1) {
        return 1;
      } else if (index2 === -1) {
        return -1;
      } else {
        return (index1 < index2) ? -1 : 1;
      }
    });

    result = result.filter((prop) => { return filteredProps.indexOf(prop) === -1; });
    return result;
  }, [props]);

  const privateProps = useMemo(() => {
    let result = [];
    if ("privateProps" in props) {
      for (let privateProp of props.privateProps) {
        result.push(privateProp.split("."));
      }
    }
    return result;
  }, [props]);

  const allComponents = editorViewFrame?.contentWindow?.AFRAME?.components ?? AFRAME.components;
  const allSystems = editorViewFrame?.contentWindow?.AFRAME?.systems ?? AFRAME.systems;

  const unusedProps = useMemo(() => {
    let result = Object.keys(allComponents).filter((prop) => {
      const isSceneComponent = sceneProps.indexOf(prop) !== -1;
      return (isScene === isSceneComponent);
    });

    if (isScene) {
      result = result.concat(Object.keys(allSystems));
    } else {
      result.push("name");
      result.push("parent");
    }
    result = result.filter((prop) => {
      return (!(prop in props) || allComponents[prop]?.multiple);
    });

    return result;
  }, [allComponents, allSystems, isScene, props]);

  const element = useMemo(() => {
    return editorViewFrame?.contentWindow?.document?.querySelector(isScene ? "#scene" : ("#entity_" + props.id));
  }, [editorViewFrame, isScene, props]);

  const subPropMap = useMemo(() => {
    let result = {};
    for (const propKey of sortedPropKeys) {
      let propValue = props[propKey];
      if (!(typeof propValue === "string")) {
        propValue = JSON.stringify(propValue);
      }
      const subProps = propValue.split(";").filter((subProp) => {
        return subProp !== "";
      }).map((subPropNameAndValue) => {
        const colonIndex = subPropNameAndValue.indexOf(":");
        if (colonIndex !== -1) {
          return { name: subPropNameAndValue.slice(0, colonIndex), value: subPropNameAndValue.slice(colonIndex + 1) };
        } else {
          return { value: subPropNameAndValue };
        }
      });
      result[propKey] = subProps;
    }
    return result;
  }, [sortedPropKeys, props]);

  return (
    <div>
      {sortedPropKeys.map((prop) => {
        return (
          <PropEditor
            key={prop}
            prop={prop}
            propValue={props[prop]}
            subProps={subPropMap[prop]}
            privateProps={privateProps}
            updatePropHandler={updatePropHandler}
            removePropHandler={removePropHandler}
            isScene={isScene}
            allComponents={allComponents}
            allSystems={allSystems}
            element={element}
          />
        )
      })}

      {unusedProps.length > 0 ?
        <div>
          <TextField
            id="addComponent"
            name="addComponent"
            defaultValue={unusedProps[0]}
            label="Add component"
            variant="filled"
            size="small"
            select
          >
            {unusedProps.map((option) => buildOption(option, ""))}
          </TextField>
          {/* TODO: Handle naming for multiple components */}
          <IconButton
            onClick={() => updatePropHandler(document.querySelector("#addComponent").innerHTML, "") }
          >
            <Add />
          </IconButton>
        </div>
        : <></>
      }
    </div>
  )
}

const modes = ["HTML", "CSS"];
export function UIEditor({ overlayElements, cssProps, updateOverlayElementHandler, updateCSSHandler,
    refreshScriptsHandler, reloadOn2DChange, setReloadOn2DChangeHandler }) {
  const [mode, setMode] = useState(modes[0]);

  // Menu state
  const [menuAnchorEl, setMenuAnchorEl] = useState(undefined);
  const menuOpen = Boolean(menuAnchorEl);
  const menuClick = (e) => {
    setMenuAnchorEl(e.currentTarget);
  };
  const menuClose = () => {
    setMenuAnchorEl(undefined);
  };

  return (
    <div style={{ position: "relative" }}>
      <div
        className={styles.topRight}
        style={{
          zIndex: 1
        }}
      >
        <IconButton
          onClick={refreshScriptsHandler}
        >
          <Refresh />
        </IconButton>
        <IconButton
          aria-label="more"
          id="more-button"
          aria-controls={menuOpen ? 'basic-menu' : undefined}
          aria-expanded={menuOpen ? 'true' : undefined}
          aria-haspopup="true"
          onClick={menuClick}
        >
          <MoreVert />
        </IconButton>
        <Menu
          id="basic-menu"
          anchorEl={menuAnchorEl}
          open={menuOpen}
          onClose={menuClose}
          MenuListProps={{
            'aria-labelledby': 'more-button',
          }}
        >
          <MenuItem
            inset="true"
            onClick={() => {
              setReloadOn2DChangeHandler(!reloadOn2DChange);
            }}
          >
            {reloadOn2DChange ?
              <ListItemIcon>
                <Check />
              </ListItemIcon>
              : <></>
            }
            Reload on Change
          </MenuItem>
        </Menu>
      </div>
      <TabContext value={mode}>
        <Tabs value={mode} onChange={(_, value) => setMode(value)} centered>
          {modes.map((modeValue) => {
            return <Tab key={modeValue} label={modeValue} value={modeValue} />
          })}
        </Tabs>
        <TabPanel value="HTML">
          <ControlledEditor
              value={overlayElements}
              onChange={(value) => updateOverlayElementHandler(value)}
              extensions={[html()]}
              style={{
                width: "600px",
                height: "80vh",
                textAlign: "left",
                overflow: "auto",
              }}
          />
        </TabPanel>
        <TabPanel value="CSS">
          <ControlledEditor
              value={cssProps}
              onChange={(value) => updateCSSHandler(value)}
              extensions={[css()]}
              style={{
                width: "600px",
                height: "80vh",
                textAlign: "left",
                overflow: "auto",
              }}
          />
        </TabPanel>
      </TabContext>
    </div>
  )
}

export function ScriptPropEditor({ props, updatePropHandler, refreshScriptsHandler,
    reloadOnScriptChange, setReloadOnScriptChangeHandler }) {
  // Menu state
  const [menuAnchorEl, setMenuAnchorEl] = useState(undefined);
  const menuOpen = Boolean(menuAnchorEl);
  const menuClick = (e) => {
    setMenuAnchorEl(e.currentTarget);
  };
  const menuClose = () => {
    setMenuAnchorEl(undefined);
  };

  return (
    <div key={props.id} style={{ position: "relative", minWidth: "400px", minHeight: "200px" }}>
      {!(("url" in props) || props.private) ?
        <div
          className={styles.topRight}
          style={{
            zIndex: 1
          }}
        >
          <IconButton
            onClick={refreshScriptsHandler}
          >
            <Refresh />
          </IconButton>
          <IconButton
            aria-label="more"
            id="more-button"
            aria-controls={menuOpen ? 'basic-menu' : undefined}
            aria-expanded={menuOpen ? 'true' : undefined}
            aria-haspopup="true"
            onClick={menuClick}
          >
            <MoreVert />
          </IconButton>
          <Menu
            id="basic-menu"
            anchorEl={menuAnchorEl}
            open={menuOpen}
            onClose={menuClose}
            MenuListProps={{
              'aria-labelledby': 'more-button',
            }}
          >
            <MenuItem
              inset="true"
              onClick={() => {
                setReloadOnScriptChangeHandler(!reloadOnScriptChange);
              }}
            >
              {reloadOnScriptChange ?
                <ListItemIcon>
                  <Check />
                </ListItemIcon>
                : <></>
              }
              Reload on Change
            </MenuItem>
          </Menu>
        </div>
        : <></>
      }
      <ControlledInput
        label="Name"
        value={props.name}
        onChange={(e) => updatePropHandler("name", e.target.value)}
        size="small"
        sx={{ top: "10px" }}
      />
      <br/>
      {!props.private ?
        <div>
          <br/>
          <FormGroup>
            <Stack spacing={2} direction="row" sx={{ margin: "0 auto", marginTop: "10px" }}>
              <FormControlLabel
                control={
                  <Switch
                    checked={props.module}
                    onChange={(e) => updatePropHandler("module", e.target.checked)}
                    size="small"
                  />
                }
                label="Is Module?"
                labelPlacement="start"
              />
              {("url" in props) ?
                <TextField
                  label="Execution"
                  value={props.execution}
                  onChange={(e) => updatePropHandler("execution", e.target.value)}
                  size="small"
                  select
                  sx={{ float: "right", marginLeft: "20px" }}
                >
                  <MenuItem value="normal">Normal</MenuItem>
                  <MenuItem value="async">Async</MenuItem>
                  <MenuItem value="defer">Defer</MenuItem>
                </TextField>
                : <></>
              }
            </Stack>
          </FormGroup>
          {("url" in props) ?
            <ControlledInput
              label="URL"
              value={props.url}
              onChange={(e) => updatePropHandler("url", e.target.value)}
              size="small"
              fullWidth
              sx={{ top: "20px" }}
            />
            : <ControlledEditor
              value={props.script}
              onChange={(value) => updatePropHandler("script", value)}
              extensions={[javascript()]}
              style={{
                width: "600px",
                height: "80vh",
                textAlign: "left",
                overflow: "auto"
              }}
            />
          }
        </div>
        : <p>{props.description}</p>
      }
    </div>
  )
}

export function OwnedScriptViewer({ props, addScriptHandler, goToListingHandler }) {
  return (
    <div key={props.id}>
      <ControlledInput
        label="Name"
        value={props.name}
        disabled
        size="small"
        sx={{ top: "10px" }}
      />
      <br/>
      <p
        className={styles.unselectable}
      >
        {props.description}
      </p>
      <Button
        variant="outlined"
        onClick={() => {
          props.data.name = props.name;
          props.data.description = props.description;
          addScriptHandler(props.data);
        }}
      >
        Add To Scene
      </Button>
      <br />
      <Button
        variant="outlined"
        onClick={() => goToListingHandler(props.listing) }
      >
        {"Listing >"}
      </Button>
    </div>
  )
}

export function AssetPropEditor({ props, updateAssetNameHandler, addEntityFromAssetHandler,
    getPublicURL, privateURL, goToListingHandler }) {
  const url = privateURL ? props.data?.asset : getPublicURL(props.name);
  const type = privateURL ? "" : props.metadata.mimetype;

  return (
    <div key={props.id}>
      <ControlledInput
        label="Name"
        value={props.name}
        disabled={privateURL}
        onChange={!privateURL ? (e) => updateAssetNameHandler(props.name, e.target.value) : undefined}
        size="small"
        sx={{ top: "10px" }}
      />
      <br/>
      {privateURL ?
        <p
          style={{ marginTop: "25px" }}
          className={styles.unselectable}
        >
          {props.description}
        </p>
        : <br />
      }
      <Button
        variant="outlined"
        onClick={() => addEntityFromAssetHandler(props.name, url, type, privateURL)}
      >
        Add To Scene
      </Button>
      {!privateURL ?
        <Button
          variant="outlined"
          onClick={() => { navigator.clipboard.writeText(url); }}
        >
          Copy URL
        </Button>
        : <></>
      }
      {goToListingHandler ?
        <>
          <br />
          <Button
            variant="outlined"
            onClick={() => goToListingHandler(props.listing) }
          >
            {"Listing >"}
          </Button>
        </>
        : <></>
      }
    </div>
  )
}

export function AppPropEditor({ props, updatePropHandler, addAppHandler, goToListingHandler, updateFrameAPI,
    currentSite, scenePropertyMap, entityPropertyMap, overlayElements, cssProps, scripts, apps }) {
  const marketplaceApp = !!goToListingHandler;
  const propsData = marketplaceApp ? props.data : props;

  const modes = propsData.private ? ["App"] : ["App", "HTML", "CSS", "JavaScript"];
  const [mode, setMode] = useState(modes[0]);

  const appFrameRef = useRef(undefined);
  const [scriptUpdateCounter, setScriptUpdateCounter] = useState(0);
  const incrementScriptUpdateCounterDebounced = useCallback(
    debounce(() => setScriptUpdateCounter(scriptUpdateCounter + 1), 2000)
  , [scriptUpdateCounter]);

  useEffect(() => {
    incrementScriptUpdateCounterDebounced();
  }, [props]);

  useEffect(() => {
    if (appFrameRef.current) {
      updateFrameAPI(appFrameRef.current);
    }
  }, [currentSite, scenePropertyMap, entityPropertyMap, overlayElements, cssProps, scripts, apps, appFrameRef]);

  const updateAppFrameScriptsLoaded = useCallback(() => {
    const message = {
      type: "scripts-loaded"
    };
    appFrameRef.current.contentWindow.postMessage(message);
  }, [appFrameRef]);

  const updateAppFrameScripts = useCallback(() => {
    {
      const scriptElement = appFrameRef.current.contentWindow.document.createElement("script");
      scriptElement.type = "text/javascript";
      scriptElement.id = "api";
      scriptElement.className = "api";
      scriptElement.innerHTML = gydenceAPI({
        currentSite: currentSite,
        isEditing: true,
        isApp: true,
        isPublic: false,
        scenePropertyMap: scenePropertyMap,
        entityPropertyMap: entityPropertyMap,
        html: overlayElements,
        css: cssProps,
        scripts: scripts,
        apps: apps,
      });
      appFrameRef.current.contentWindow.document.head.appendChild(scriptElement);
    }

    {
      const injectedScriptID = "injectedScript";
      const scriptElement = appFrameRef.current.contentWindow.document.createElement("script");
      scriptElement.type = "module";
      scriptElement.id = injectedScriptID;
      scriptElement.className = injectedScriptID;
      scriptElement.innerHTML = propsData.script;
      appFrameRef.current.contentWindow.document.head.appendChild(scriptElement);

      updateAppFrameScriptsLoaded();
    }
  }, [appFrameRef, propsData, currentSite, scenePropertyMap, entityPropertyMap, overlayElements, cssProps, scripts, apps]);

  const updateAppFrame = useCallback(() => {
    let message = {
      type: "data-update",
      html: propsData.html,
      css: propsData.css
    };
    appFrameRef.current.contentWindow.postMessage(message);
  }, [appFrameRef, propsData]);

  const handleIFrameLoad = useCallback(() => {
    updateAppFrameScripts();
    if (scriptUpdateCounter > 0) {
      updateAppFrame();
    }
  }, [updateAppFrameScripts, scriptUpdateCounter, updateAppFrame]);

  return (
    <div key={props.id}>
      <ControlledInput
        label="Name"
        value={props.name}
        disabled={marketplaceApp}
        onChange={!marketplaceApp ? (e) => updatePropHandler("name", e.target.value) : undefined}
        size="small"
      />
      <br />
      <br />
      {marketplaceApp ?
        <>
          <Button
            variant="outlined"
            onClick={() => {
              props.data.name = props.name;
              props.data.description = props.description;
              addAppHandler(props.data);
            }}
          >
            Add To Scene
          </Button>
          <br />
          <Button
            variant="outlined"
            onClick={() => goToListingHandler(props.listing) }
          >
            {"Listing >"}
          </Button>
          <br />
          <br />
        </>
        : <></>
      }
      <TabContext value={mode}>
        {modes.length > 1 ?
          <Tabs value={mode} onChange={(_, value) => setMode(value)} centered>
            {modes.map((modeValue) => {
              return <Tab key={modeValue} label={modeValue} value={modeValue} />
            })}
          </Tabs>
          : <></>
        }
        <TabPanel value="App">
          <iframe
            key={scriptUpdateCounter}
            className={styles.unselectable}
            title="Gydence App View"
            src={window.location.origin + "/app" + window.location.search}
            allow="geolocation; microphone; camera; midi; encrypted-media; xr-spatial-tracking; fullscreen"
            onLoad={handleIFrameLoad}
            ref={appFrameRef}
            style={{
              width: "600px",
              height: "80vh",
              textAlign: "left",
              overflow: "auto"
            }}
          />
        </TabPanel>
        <TabPanel value="HTML">
          <ControlledEditor
            value={propsData.html}
            readOnly={marketplaceApp}
            onChange={!marketplaceApp ? (value) => updatePropHandler("html", value) : undefined}
            extensions={[html()]}
            style={{
              width: "600px",
              height: "80vh",
              textAlign: "left",
              overflow: "auto"
            }}
          />
        </TabPanel>
        <TabPanel value="CSS">
          <ControlledEditor
            value={propsData.css}
            readOnly={marketplaceApp}
            onChange={!marketplaceApp ? (value) => updatePropHandler("css", value) : undefined}
            extensions={[css()]}
            style={{
              width: "600px",
              height: "80vh",
              textAlign: "left",
              overflow: "auto"
            }}
          />
        </TabPanel>
        <TabPanel value="JavaScript">
          <ControlledEditor
            value={propsData.script}
            readOnly={marketplaceApp}
            onChange={!marketplaceApp ? (value) => updatePropHandler("script", value) : undefined}
            extensions={[javascript()]}
            style={{
              width: "600px",
              height: "80vh",
              textAlign: "left",
              overflow: "auto"
            }}
          />
        </TabPanel>
      </TabContext>
    </div>
  )
}