if (console.everything === undefined) {

    console.everything = [];

    function addMessage(message) {
      console.everything.push(message);

      const debug = document.querySelector("#debug");
      if (debug) {
        debug.setAttribute("text", "value", JSON.stringify(console.everything));
        debug.innerHTML = JSON.stringify(console.everything);
      }
    }

    window.onerror = function (error, url, line) {
      addMessage({
        type: "exception",
        value: { error, url, line },
      });
      return false;
    };
    window.onunhandledrejection = function (e) {
      addMessage({
        type: "promiseRejection",
        value: JSON.stringify(e, ["message", "arguments", "type", "name"]),
      });
    };

    function hookLogType(logType) {
      const original = console[logType].bind(console);
      return function () {
        addMessage({
          type: logType,
          value: Array.from(arguments),
        });
        original.apply(console, arguments);
      };
    }

    [
      // "log",
      "error",
      // "warn",
      // "debug"
    ].forEach((logType) => {
      console[logType] = hookLogType(logType);
    });
  }

   // Debug with this:
   // <a-entity id="debug" position="0 2 -1" scale="0.5 0.5 0.5" text="value: text; color: #444; align: center"></a-entity>