import "aframe";
import "./rhino3dModel";
import "./gydenceModel";

import * as zip from "@zip.js/zip.js";

const getURLExtension = (url) => {
  return url.split(/[#?]/)[0].split('.').pop().trim();
};

AFRAME.registerComponent("model", {
  schema: {
    url: {
      type: "model"
    },
    format: {
      type: "string"
    }
  },

  unzip: async (url) => {
    zip.configure({ chunkSize: 128, useWebWorkers: true });
    const zipReader = new zip.ZipReader(new zip.HttpReader(url, { preventHeadRequest: true }));
    const entries = await zipReader.getEntries();
    const dataBlobWriter = new zip.TextWriter(zip.getMimeType(entries[0].filename));
    const data = await entries[0].getData(dataBlobWriter);
    await zipReader.close();
    zip.terminateWorkers();
    return [data, getURLExtension(entries[0].filename)];
  },

  clearModel: function () {
    if (this.format) {
      switch (this.format) {
        case "gltf":
        case "glb":
          this.el.removeAttribute("gltf-model");
          break;
        case "obj":
          this.el.removeAttribute("obj-model");
          break;
        case "3dm":
          this.el.removeAttribute("rhino3dm-model");
          break;
        case "gyde":
          this.el.removeAttribute("gydence-model");
          break;
        default:
          break;
      }
      this.format = null;
    }
  },

  update: function () {
    this.clearModel();

    this.format = this.data.format;
    if (!this.format && this.data.url) {
      this.format = getURLExtension(this.data.url);
    }

    if (this.data.url) {
      const self = this;
      switch (this.format) {
        case "gltf":
        case "glb":
          this.el.setAttribute("gltf-model", this.data.url);
          break;
        case "obj":
          this.el.setAttribute("obj-model", "obj", this.data.url);
          break;
        case "3dm":
          this.el.setAttribute("rhino3dm-model", this.data.url);
          break;
        case "gyde":
          this.el.setAttribute("gydence-model", this.data.url);
          break;
        case "zip":
          this.unzip(this.data.url).then(([result, ext]) => {
            const onLoad = undefined;
            switch (ext) {
              case "gtlf":
              case "glb":
              case "obj":
              case "3dm":
                // TODO
                break;
              case "gyde":
                onLoad = function () {
                  self.el.removeEventListener("componentinitialized", onLoad);
                  const modelComponent = self.el.components["gydence-model"];
                  if (modelComponent && modelComponent.parse) {
                    modelComponent.parse(JSON.parse(result));
                  }
                };
                self.el.addEventListener("componentinitialized", onLoad);

                self.el.setAttribute("gydence-model", "");
                break;
              default:
                break;
            }
          });
          break;
        default:
          break;
      }
    }
  },

  parse: function (file) {
    this.clearModel();

    this.format = this.data.format;
    if (!this.format && file) {
      this.format = getURLExtension(file.name);
    }

    if (file) {
      const self = this;
      const reader = new FileReader();
      switch (this.format) {
        case "gtlf":
        case "glb":
        case "obj":
        case "zip":
          // TODO
          break;
        case "gyde":
          reader.addEventListener("load", async function (event) {
            const onLoad = function () {
              self.el.removeEventListener("componentinitialized", onLoad);
              const modelComponent = self.el.components["gydence-model"];
              if (modelComponent && modelComponent.parse) {
                modelComponent.parse(JSON.parse(event.target.result));
              }
            };
            self.el.addEventListener("componentinitialized", onLoad);

            self.el.setAttribute("gydence-model", "");
          });
          reader.readAsText(file);
          break;
        case "3dm":
          reader.addEventListener("load", async function (event) {
            const onLoad = function () {
              self.el.removeEventListener("componentinitialized", onLoad);
              const modelComponent = self.el.components["rhino3dm-model"];
              if (modelComponent && modelComponent.parse) {
                modelComponent.parse(event.target.result);
              }
            };
            self.el.addEventListener("componentinitialized", onLoad);

            self.el.setAttribute("rhino3dm-model", "");
          });
          reader.readAsArrayBuffer(file);
          break;
        default:
          break;
      }
    }
  },

  remove: function () {
    this.clearModel();
  },
});