import "aframe";

import { Rhino3dmLoader } from "./fileLoader";

AFRAME.registerComponent("rhino3dm-model", {
  schema: { type: "model" },

  init: function () {
    this.model = null;
    this.loader = new Rhino3dmLoader();
    this.loader.setLibraryPath("https://cdn.jsdelivr.net/npm/rhino3dm@7.15.0/");
  },

  modelLoaded: function (self, model) {
    self.clearModel();

    // FIXME: handle this rotation somehow
    model.rotation.set(-0.5 * Math.PI, 0, 0);
    self.model = model;
    self.el.setObject3D("mesh", self.model);
    self.el.emit("model-loaded", { format: "3dm", model: self.model });
  },

  modelFailed: function (self, error) {
    var message =
      error && error.message ? error.message : "Failed to load 3dm model";
    console.warn(message);
    self.el.emit("model-error", { format: "3dm", src: self.data });
  },

  clearModel: function () {
    if (this.model) {
      this.el.removeObject3D("mesh");
    }
  },

  update: function () {
    if (!this.data) {
      this.clearModel();
      return;
    }

    const self = this;
    this.loader.load(
      this.data,
      function (model) { self.modelLoaded(self, model) },
      undefined,
      function (error) { self.modelFailed(self, error) },
    );
  },

  parse: function (data) {
    const self = this;
    this.loader.parse(
      data,
      function (model) { self.modelLoaded(self, model) },
      function (error) { self.modelFailed(self, error) },
    );
  },

  remove: function () {
    this.loader.dispose();
    this.clearModel();
  },
});
