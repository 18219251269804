import React, { useEffect, useRef, useState } from "react";
import { IconButton, Menu, MenuItem } from "@mui/material";
import { Menu as MenuIcon } from "@mui/icons-material";

import { supabase } from "../pages/signin";
import DelayLink from "./delayLink";
import SEO from "./seo";

import styles from "../pages/index.module.css";

export default function SitePage({ page, title, description, forceHamburger }) {
  const frameRef = useRef(undefined);
  const [hamburgerMenu, setHamburgerMenu] = useState(forceHamburger || window.innerWidth <= 500);
  const [primaryText, setPrimaryText] = useState("Waitlist");

  useEffect(() => {
    const calcHamburgerMenu = () => {
      setHamburgerMenu(forceHamburger || window.innerWidth <= 500);
    };

    supabase.auth.getUser().then((user) => {
      if (user?.data?.user?.email !== undefined) {
        setPrimaryText("Editor");
      }
    });

    window.addEventListener("resize", calcHamburgerMenu);

    return () => {
      window.removeEventListener("resize", calcHamburgerMenu);
    }
  }, []);

  const [menuAnchorEl, setMenuAnchorEl] = useState(undefined);
  const menuOpen = Boolean(menuAnchorEl);
  const menuClick = (e) => {
    setMenuAnchorEl(e.currentTarget);
  };
  const menuClose = () => {
    setMenuAnchorEl(undefined);
  };

  const isHome = location.pathname === "/";

  return (
    <>
      <SEO
        title={"Gydence " + title}
        description={description}
      />
      {!isHome ?
        <a
          href="/"
          style={{
            position: "absolute",
            bottom: "5px",
            left: "5px",
            zIndex: 1
          }}
        >
          <img src="/assets/logo.png" style={{ width: "10vh", height: "10vh" }}></img>
        </a>
        : <></>
      }
      {!hamburgerMenu ?
        <div
          style={{
            width: "100vw",
            zIndex: 1,
            top: "10px"
          }}
          className={[styles.topMiddle, styles.block].join(" ")}
        >
          <div
            className={[styles.block, styles.ui].join(" ")}
            style={{
              width: "fit-content",
              margin: "0 auto",
              backgroundColor: "rgb(255, 255, 255, 0.1)"
            }}
          >
            <DelayLink
              to="/editor"
              style={{
                fontSize: "20px",
                color: "#ddd"
              }}
              delay={750}
              onDelayStart={() => {
                frameRef?.current?.contentWindow?.postMessage({ type: "close" }, "*");
              }}
            >
              {primaryText}
            </DelayLink>
            <span
              style={{
                padding: "0px 20px",
                fontSize: "20px",
                color: "#ddd"
              }}
            >|</span>
            <DelayLink
              to="/info"
              delay={750}
              style={{
                fontSize: "20px",
                color: "#ddd"
              }}
              onDelayStart={() => {
                frameRef?.current?.contentWindow?.postMessage({ type: "close" }, "*");
              }}
            >
              Info
            </DelayLink>
            <span
              style={{
                padding: "0px 20px",
                fontSize: "20px",
                color: "#ddd"
              }}
            >|</span>
            <a
              href="https://www.github.com/gydence/gydence-public/issues"
              style={{
                fontSize: "20px",
                color: "#ddd"
              }}
            >
              Discussion
            </a>
            <span
              style={{
                padding: "0px 20px",
                fontSize: "20px",
                color: "#ddd"
              }}
            >|</span>
            <a
              href="https://docs.gydence.com/#"
              style={{
                fontSize: "20px",
                color: "#ddd"
              }}
            >
              Documentation
            </a>
          </div>
        </div>
        : <div
            className={styles.topRight}
            style={{
              zIndex: 1
            }}
          >
            <IconButton
              aria-label="more"
              id="more-button"
              aria-controls={menuOpen ? 'basic-menu' : undefined}
              aria-expanded={menuOpen ? 'true' : undefined}
              aria-haspopup="true"
              color="secondary"
              onClick={menuClick}
            >
              <MenuIcon fontSize="large" />
            </IconButton>
            <Menu
              id="basic-menu"
              anchorEl={menuAnchorEl}
              open={menuOpen}
              onClose={menuClose}
              MenuListProps={{
                'aria-labelledby': 'more-button',
              }}
            >
              <MenuItem
                onClick={() => {
                  frameRef?.current?.contentWindow?.postMessage({ type: "close" }, "*");
                  setTimeout(() => {
                    window.location = window.location.origin + "/editor";
                  }, 750);
                }}
              >
                {primaryText}
              </MenuItem>
              <MenuItem
                onClick={() => {
                  frameRef?.current?.contentWindow?.postMessage({ type: "close" }, "*");
                  setTimeout(() => {
                    window.location = window.location.origin + "/info";
                  }, 750);
                }}
              >
                Info
              </MenuItem>
              <MenuItem
                onClick={() => {
                  window.location = "https://www.github.com/gydence/gydence-public/issues";
                }}
              >
                Discussion
              </MenuItem>
              <MenuItem
                onClick={() => {
                  window.location = "https://docs.gydence.com/#";
                }}
              >
                Documentation
              </MenuItem>
            </Menu>
          </div>
      }
      <iframe
        ref={frameRef}
        className={styles.unselectable}
        title={"Gydence " + title}
        src={page}
        allow="accelerometer; ambient-light-sensor; camera; encrypted-media; fullscreen; geolocation; gyroscope; hid; microphone; midi; payment; usb; vr; xr-spatial-tracking"
        style={{
          position: "absolute",
          width: "100dvw",
          height: "100dvh"
        }}
      />
    </>
  )
}