import "aframe";

import { isMobileOrVR } from "./utils";

function degToRad(degrees) {
  return degrees * (Math.PI / 180);
}

AFRAME.registerComponent("billboard", {
  schema: {
    enabled: {
      type: "boolean",
      default: true,
    },
    mode: {
      default: "billboard",
      oneOf: ["billboard", "axis-y"],
    },
    offsetRotation: {
      type: "vec3",
      default: { x: 0, y: 0, z: 0 }
    }
  },

  init: function () {
    this.vector = new THREE.Vector3();
    this.offsetRotationEuler = new THREE.Euler();
    this.offsetRotation = new THREE.Quaternion();
  },

  update: function() {
    if (this.data.mode === "axis-y") {
      this.el.object3D.rotation.order = "YXZ";
    }
    this.offsetRotationEuler.set(degToRad(this.data.offsetRotation.x),
                                 degToRad(this.data.offsetRotation.y),
                                 degToRad(this.data.offsetRotation.z));
    this.offsetRotation.setFromEuler(this.offsetRotationEuler);
  },

  tick: function () {
    if (!this.data.enabled) {
      return;
    }

    const target = this.el.sceneEl.camera;
    const object3D = this.el.object3D;

    if (target) {
      target.updateMatrixWorld();
      this.vector.setFromMatrixPosition(target.matrixWorld);
      if (object3D.parent) {
        object3D.parent.updateMatrixWorld();
      }
      object3D.lookAt(this.vector);

      if (this.data.mode === "axis-y") {
        object3D.rotation.x = 0;
      }

      object3D.quaternion.multiply(this.offsetRotation);
    }
  },
});

AFRAME.registerComponent("follow-hit-test", {
  dependencies: ["position"],

  schema: {
    enabled: {
      type: "boolean",
      default: true,
    },
  },

  init: function () {
    this.initialPos = new THREE.Vector3();
    this.initialPos.copy(this.el.object3D.position);
  },

  tick: function () {
    if (!this.data.enabled) {
      return;
    }

    const target = this.el.sceneEl.components["ar-hit-test"];
    if (target && target.data.enabled) {
      const bbox = target.bboxMesh;
      if (bbox) {
        this.el.object3D.position.copy(bbox.position);
        this.el.object3D.position.add(this.initialPos);
      }
    }
  },
});
