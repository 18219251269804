import "aframe";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { replaceHTMLIncludes } from "../gydence/utils";
import "../gydence/anchor";
import "../gydence/animations";
import "../gydence/camera";
import "../gydence/cameraSource";
import "../gydence/container";
import "../gydence/depth-estimation";
import "../gydence/environmentMap";
import "../gydence/follow";
import "../gydence/grid";
import "../gydence/html";
import "../gydence/imageSource";
import "../gydence/list";
import "../gydence/logo";
import "../gydence/material";
import "../gydence/model";
import "../gydence/networking";
import "../gydence/ring";
import "../gydence/selectionTool";
import "../gydence/spawner";
import "../gydence/stencil";
import "../gydence/testing";
import "../gydence/text3d";
import "../gydence/tfjs-hand-tracking";

import SignIn, { supabase } from "./signin";
import NotFound from "./404";
import { EntityHierarchyRender } from "./entityList";
import gydenceAPI from "./api";

import styles from "./index.module.css";

export default function GydencePreview() {
  const [setup, setSetup] = useState(false);
  const [failedToLoad, setFailedToLoad] = useState(false);
  const { siteID } = useParams();

  // Login state
  const [loggedIn, setLoggedIn] = useState(true);

  // Scene/entity state maps
  const [scenePropertyMap, setScenePropertyMap] = useState({});
  const [entityPropertyMap, setEntityPropertyMap] = useState({});
  const [entityParentMap,  setEntityParentMap] = useState({});
  const [cameraEntityChildren, setCameraEntityChildren] = useState(new Set());

  // 2D UI
  const [overlayElements, setOverlayElements] = useState(undefined);
  const [cssProps, setCSSProps] = useState(undefined);

  // Scripts
  const [scripts, setScripts] = useState(undefined);
  const [hasLoadedScripts, setHasLoadedScripts] = useState(false);

  useEffect(() => {
    if (hasLoadedScripts) {
      const event = new Event("DOMContentLoaded");
      document.dispatchEvent(event);
    }
  }, [hasLoadedScripts]);

  useEffect(() => {
    const injectedCSSID = "injectedCSS";
    const existingStyleSheet = document.querySelector("#" + injectedCSSID);
    let stylesheet = existingStyleSheet ?? document.createElement("style");
    stylesheet.id = injectedCSSID;
    stylesheet.innerHTML = cssProps;
    if (!existingStyleSheet) {
      document.head.appendChild(stylesheet);
    }
  }, [cssProps]);

  useEffect(() => {
    if (!scripts) {
      return;
    }

    if (!document.querySelector("#api")) {
      const scriptElement = document.createElement("script");
      scriptElement.type = "text/javascript";
      scriptElement.id = "api";
      scriptElement.className = "api";
      scriptElement.innerHTML = gydenceAPI({
        currentSite: siteID,
        isEditing: false,
        isApp: false,
        isPublic: false,
      });
      document.head.appendChild(scriptElement);
    }

    let scriptsNeedingLoad = 0;
    {
      const injectedScriptID = "injectedScript";

      let scriptsLoaded = 0;
      const handleScriptLoad = () => {
        scriptsLoaded++;
        if (scriptsLoaded === scriptsNeedingLoad) {
          setHasLoadedScripts(true);
        }
      }

      for (const script of scripts) {
        const scriptElement = document.createElement("script");
        if (script.module) {
          scriptElement.type = "module";
        } else {
          scriptElement.type = "text/javascript";
        }
        scriptElement.id = script.id;
        scriptElement.className = injectedScriptID;
        if (script.url) {
          scriptElement.src = script.url;

          if (script.execution === "async") {
            scriptElement.setAttribute("async", true);
          } else if (script.execution === "defer") {
            scriptElement.setAttribute("defer", true);
          }

          scriptsNeedingLoad++;
          scriptElement.onload = handleScriptLoad;
          scriptElement.onerror = handleScriptLoad;

        } else if (script.script) {
          scriptElement.innerHTML = script.script;
        }

        document.head.appendChild(scriptElement);
      }

      if (scriptsNeedingLoad === 0) {
        setHasLoadedScripts(true);
      }
    }
  }, [scripts]);

  useEffect(() => {
    if (!setup) {
      const fetchData = async () => {
        {
          const { data } = await supabase.auth.getUser();
          setLoggedIn(data?.user?.email);
        }

        {
          const { data } = await supabase.from("sites").select("name,data").eq("id", siteID).limit(1);
          if (data?.length > 0) {
            document.title = data[0].name;
            let scenePropertyMapData = {};
            let entityPropertyMapData = {};
            let entityParentMapData = {};

            let overlayElementsData = undefined;
            let cssPropsData = undefined;

            let scriptsData = [];

            if (data[0].data?.scene) {
              scenePropertyMapData = data[0].data.scene;
            }

            if (data[0].data?.entities) {
              for (let entity of data[0].data.entities) {
                const parentID = entity.parent;
                if (!(parentID in entityParentMapData)) {
                  entityParentMapData[parentID] = [];
                }
                entityParentMapData[parentID].push(entity.id);
                entityPropertyMapData[entity.id] = entity;
              }

              const cameraEntityChildrenData = new Set();
              for (const entityProps of Object.values(entityPropertyMapData)) {
                const parent = entityProps.parent;
                if (parent !== undefined && parent.toLowerCase() === "camera") {
                  cameraEntityChildrenData.add(entityProps.parent);
                }
              }
              setCameraEntityChildren(cameraEntityChildrenData);
            }

            if (data[0].data?.overlay) {
              overlayElementsData = data[0].data.overlay;
            }

            if (data[0].data?.css) {
              cssPropsData = data[0].data.css;
            }

            if (data[0].data?.scripts) {
              scriptsData = data[0].data.scripts ?? [];
            }

            setScenePropertyMap(scenePropertyMapData);
            setEntityPropertyMap(entityPropertyMapData);
            setEntityParentMap(entityParentMapData);
            setOverlayElements(overlayElementsData);
            setCSSProps(cssPropsData);

            if (scriptsData.length > 0) {
              setScripts(scriptsData);
            } else {
              setHasLoadedScripts(true);
            }
          } else {
            setFailedToLoad(true);
          }
        }
      };
      fetchData();

      supabase.auth.getSession().then((result) => {
        let currentSession = result?.data?.session;
        supabase.auth.onAuthStateChange((event, session) => {
          // Supabase sends a SIGNED_IN event if you've switched to a different
          // tab or window and back.  Filter those out.
          if (session?.user?.id === currentSession?.user?.id) {
            return;
          }

          currentSession = session;
          fetchData();
        });
      });

      setSetup(true);
      replaceHTMLIncludes();
    }
  }, []);

  if (!loggedIn) {
    return (
      <SignIn/>
    )
  }

  if (failedToLoad) {
    return (
      <NotFound />
    )
  }

  return (
    <>
      {/* TODO: handle scene error */}
      {hasLoadedScripts ?
        <a-scene
          {...scenePropertyMap}
        >
          {/* <!-- Camera(s) + UI --> */}
          <a-entity id="camera-container">
            <a-entity id="camera" camera="">
              {[...cameraEntityChildren].map((child) => {
                  return <EntityHierarchyRender
                    key={child}
                    entity={child}
                    entityPropertyMap={entityPropertyMap}
                    entityParentMap={entityParentMap}
                  />
                })
              }
            </a-entity>
          </a-entity>

          {/* <!-- Content --> */}
          {entityPropertyMap ?
            <EntityHierarchyRender
              entity={undefined}
              entityPropertyMap={entityPropertyMap}
              entityParentMap={entityParentMap}
            />
            : <></>
          }
        </a-scene>
        : <></>
      }

      <div
        id="overlayDOM"
        className={styles.block}
        style={{ position: "absolute", top: "0px", width: "100vw", height: "100vh", padding: "0px" }}
      >
        {(hasLoadedScripts && overlayElements) ?
          <div
            className={styles.block}
            dangerouslySetInnerHTML={{ __html: overlayElements }}
          />
          : <></>
        }
      </div>
    </>
  )
}