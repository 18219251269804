import { useEffect, useState } from "react";

import { supabase } from "./signin";

export default function EnforceOnboarded({ children }) {
  const [setup, setSetup] = useState(false);
  const [approved, setApproved] = useState(undefined);

  useEffect(() => {
    if (!setup) {
      const init = async () => {
        const { data } = await supabase.from("user_data_private").select("*").limit(1);
        setApproved(data?.[0]?.approved === true);
      }
      init();

      setSetup(true);
    }
  }, []);

  if (approved === undefined) {
    return (
      <></>
    )
  } else if (!approved) {
    return (
      <div
        style={{
          width: "100vw",
          height: "100vh",
          background: "rgb(168,22,54)",
          background: "linear-gradient(320deg, rgba(168,22,54,1) 0%, rgba(139,55,80,1) 15%, rgba(2,0,36,1) 50%, rgba(9,9,121,1) 85%, rgba(0,212,255,1) 100%)"
        }}
      >
        <div
          style={{
            position: "relative",
            width: "500px",
            maxWidth: "90vw",
            margin: "auto",
            top: "45vh",
            padding: "20px",
            backgroundColor: "#111",
            borderStyle: "solid",
            borderRadius: "20px",
            borderWidth: "2px",
            borderColor: "#333"
          }}
        >
        <p
          style={{
            color: "#aaa"
          }}
        >
          Thank you for joining our waitlist!  You will be notified when you are approved.
        </p>
        </div>
      </div>
    )
  }

  return (
    <>
      {children}
    </>
  )
}