import { useEffect, useState, useMemo, useCallback } from "react";

import { Button, IconButton, TextField } from "@mui/material";
import { Close } from "@mui/icons-material";
import { ReactMultiEmail } from "react-multi-email";
import "react-multi-email/dist/style.css";

import { supabase } from "./signin";
import GridList from "../components/gridList";

import styles from "./index.module.css";

const NUM_COLUMNS = 4;

export default function EditSiteUI({ newSite, user, sites, currentSite, siteName, isCreator, siteEditors, templates, environment, environments,
    getSitesHandler, setCurrentSiteHandler, updateSiteNameHandler, updateSiteEditorsHandler, setEditorModeHandler,
    setSelectedObjectHandler, addEntityHandler, updateScenePropHandler, setEnvironmentHandler,
    updateOverlayElementHandler, updateCSSHandler, addScriptHandler, addAppHandler, setTargetListing }) {
  const [name, setName] = useState(!newSite ? siteName : undefined);
  const [editors, setEditors] = useState(!newSite ? siteEditors : undefined);
  const [selectedTemplate, setSelectedTemplate] = useState(undefined);
  const [selectedEnvironment, setSelectedEnvironment] = useState(!newSite ? environment : undefined);

  const allTemplates = useMemo(() => {
    let allTemplatesData = templates.slice();
    allTemplatesData.unshift({
      id: undefined,
      name: "No Template"
    });
    return allTemplatesData;
  }, [templates]);

  useEffect(() => {
    if (newSite && environments.length > 0) {
      const defaultEnvironment = environments.find((environment) => environment.name === "Grid (Default)");
      if (defaultEnvironment) {
        setSelectedEnvironment(defaultEnvironment.id);
      } else {
        setSelectedEnvironment(environments[0].id);
      }
    }
  }, [environments]);

  const createSite = useCallback(() => {
    supabase.rpc("add_site", {
      name: name,
      editors: editors?.join(",") ?? "",
    }).then(async (result) => {
      console.log(result);
      if (!result.error) {
        await getSitesHandler();

        if (allTemplates.length > 0 && selectedTemplate) {
          const { data } = await supabase.from("marketplace_data").select("data").eq("id", selectedTemplate).limit(1);
          const template = data[0].data;

          if ("entities" in template) {
            await addEntityHandler(template.entities, result.data, () => setSelectedObjectHandler(undefined));
          }

          if ("scene" in template) {
            for (const scenePropName of Object.keys(template.scene)) {
              updateScenePropHandler(scenePropName, template.scene[scenePropName], result.data);
            }
          }

          if ("overlay" in template) {
            updateOverlayElementHandler(template.overlay, result.data);
          }

          if ("css" in template) {
            updateCSSHandler(template.css, result.data);
          }

          if ("scripts" in template) {
            for (const script of template.scripts) {
              await addScriptHandler(script, () => setSelectedObjectHandler(undefined), result.data);
            }
          }

          if ("apps" in template) {
            for (const app of template.apps) {
              await addAppHandler(app, () => setSelectedObjectHandler(undefined), result.data);
            }
          }
        }

        if (environments.length > 0 && selectedEnvironment) {
          const environment = environments.find((environment) => environment.id === selectedEnvironment);
          setEnvironmentHandler(environment.id, result.data);
        }

        setCurrentSiteHandler(result.data);
        setEditorModeHandler("Entities");
      }
    });
  }, [name, editors, getSitesHandler, selectedTemplate, allTemplates, selectedEnvironment, setSelectedObjectHandler,
    addEntityHandler, updateScenePropHandler, updateOverlayElementHandler, updateCSSHandler, addScriptHandler,
    addAppHandler, setEnvironmentHandler, setCurrentSiteHandler, setEditorModeHandler]);

  const deleteAssets = useCallback(async (folders) => {
    let assets = [];
    for (const folder of folders) {
      const { data } = await supabase.storage.from("storage").list("sites/" + currentSite + "/" + folder + "/");
      console.log(data);
      if (data) {
        data.map((asset) => {
          assets.push("sites/" + currentSite + "/" + folder + "/" + asset.name);
        });
      }
    }

    if (assets.length > 0) {
      return supabase.storage.from("storage").remove(assets);
    }

    return undefined;
  }, [currentSite]);

  const removePublicSite = useCallback(async () => {
    deleteAssets(["public"]);

    await supabase.from("public").delete().eq("id", currentSite).order("id", { ascending: false }).limit(1)
      .then((result) => {
        console.log(result);
      });
  }, [deleteAssets, currentSite]);

  const deleteSite = useCallback(async () => {
    let promise = deleteAssets(["public", "private"]);

    if (promise) {
      // We need to actually wait for the assets to be deleted first, otherwise
      // the site might not be in our sites list anymore and the asset delete RLS policy will
      // fail.
      promise.then(() => {
        // Deleting from sites will cascade delete from public
        supabase.from("sites").delete().eq("id", currentSite)
          .order("id", { ascending: false }).limit(1)
          .then(async (result) => {
            console.log(result);
            if (!result.error) {
              const newSites = await getSitesHandler();
              setCurrentSiteHandler(newSites?.[0]?.id);
              setEditorModeHandler("Entities");
            }
          });
        }
      );
    }
  }, [deleteAssets, currentSite, getSitesHandler, setCurrentSiteHandler, setEditorModeHandler]);

  const [numAllowedSites, setNumAllowedSites] = useState(0);

  useEffect(() => {
    if (newSite) {
      const fetchData = async () => {
        const { data } = await supabase.rpc("get_num_allowed_sites");
        setNumAllowedSites(data ?? 0);
      };
      fetchData();
    } else {
      setNumAllowedSites(0);
    }
  }, [newSite, user]);

  const canCreateSite = useMemo(() => {
    const numMySites = sites.filter((site) => { return site.creator === user?.user.email; }).length;
    return numMySites < numAllowedSites;
  }, [sites, user, numAllowedSites]);

  return (
    <div
      className={styles.ui}
      style={{
        width: "90vw",
        height: "90vh",
        maxWidth: "1000px",
        maxHeight: "1000px",
        color: "#CCCCCC",
        background: "rgba(50, 50, 50, 0.7)",
        overflow: "auto",
        position: "relative"
      }}
      onClick={(e) => e.stopPropagation()}
    >
      {sites.length > 0 ?
        <div
          className={styles.topRight}
        >
          <IconButton
            onClick={() => setEditorModeHandler("Entities")}
            sx={{
              color: "rgba(255, 255, 255, 0.4)"
            }}
          >
            <Close />
          </IconButton>
        </div>
        : <></>
      }
      <p className={styles.unselectable}>{newSite ? "Create a New Project" : "Edit Project"}</p>
      <TextField
        label="Site Name"
        type="text"
        value={!newSite ? siteName : undefined}
        onChange={(e) => { setName(e.target.value); }}
        onBlur={!newSite ? () => { updateSiteNameHandler(name) } : undefined}
        size="small"
        sx={{
          input: {
            backgroundColor: "white"
          }
        }}
      />
      <br className={styles.unselectable} />
      <br className={styles.unselectable} />
      <label className={styles.unselectable} htmlFor="siteEditors" >Editors (Optional): </label>
      <ReactMultiEmail
        placeholder="Input editors' emails."
        emails={editors}
        onChange={(emails) => { setEditors(emails); }}
        onBlur={!newSite ? () => { updateSiteEditorsHandler(editors?.join(",") ?? "") } : undefined}
        getLabel={(email, index, removeEmail) => {
          return (
            <div data-tag key={index}>
              <div data-tag-item>{email}</div>
              <span data-tag-handle onClick={() => removeEmail(index)}>
                ×
              </span>
            </div>
          );
        }}
      />
      {newSite ?
        <>
          <p className={styles.unselectable}>Template:</p>
          <GridList
            selected={selectedTemplate}
            list={allTemplates}
            numColumns={NUM_COLUMNS}
            onSelectHandler={setSelectedTemplate}
            getGridItem={(item) => {
              return (
                <div
                  style={{
                    width: "100%",
                    height: "100%"
                  }}
                >
                  {item.name}

                  {item.id ?
                    <div
                      className={[styles.bottomRight].join(" ")}
                    >
                      <Button
                        variant="contained"
                        size="small"
                        color="secondary"
                        onClick={(e) => {
                          e.stopPropagation();
                          setEditorModeHandler("Marketplace");
                          setTargetListing(item.listing);
                        }}
                      >
                        {"Listing >"}
                      </Button>
                    </div>
                    : <></>
                  }
                </div>
              )
            }}
            style={{
              height: "fit-content",
              maxHeight: "30vh",
              overflow: "auto"
            }}
          />
        </>
        : <></>
      }
      <p className={styles.unselectable}>Environment:</p>
      <GridList
        selected={selectedEnvironment}
        list={environments}
        numColumns={NUM_COLUMNS}
        onSelectHandler={(id) => {
          if (!newSite) {
            setEnvironmentHandler(id);
          }
          setSelectedEnvironment(id);
        }}
        getGridItem={(item) => {
          return (
            <div
              style={{
                width: "100%",
                height: "100%"
              }}
            >
              {item.name}

              <div
                className={[styles.bottomRight].join(" ")}
              >
                <Button
                  variant="contained"
                  size="small"
                  color="secondary"
                  onClick={(e) => {
                    e.stopPropagation();
                    setEditorModeHandler("Marketplace");
                    setTargetListing(item.listing);
                  }}
                >
                  {"Listing >"}
                </Button>
              </div>
            </div>
          )
        }}
        style={{
          height: "fit-content",
          maxHeight: "30vh",
          overflow: "auto"
        }}
      />
      {newSite ?
        <>
          <Button
            variant="contained"
            size="small"
            onClick={createSite}
            disabled={!canCreateSite}
          >
            Create Project
          </Button>
          {!canCreateSite ?
            <>
              <br />
              <span>You have reached the limit of allowed sites for your plan.</span>
            </>
            : <></>
          }
        </>
        :
        <>
          <Button
            variant="contained"
            size="small"
            onClick={removePublicSite}
          >
            Remove Public Project
          </Button>
          <br />
          <br />
          {isCreator ?
            <Button
              variant="contained"
              size="small"
              style={{
                backgroundColor: "red"
              }}
              onClick={deleteSite}
            >
              Delete Project
            </Button>
            : <></>
          }
        </>
      }
    </div>
  )
}