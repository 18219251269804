import { useState, useMemo, useCallback } from "react";

import styles from "./index.module.css";

export default function EntityMenu({ editorMode, setEditorModeHandler, entityPropertyMap, addEntityHandler }) {
  const [currentMenu, setCurrentMenu] = useState(undefined);

  const menuOptions = useMemo(() => [
    {
      name: "Layout",
    },
    {
      name: "Elements",
    },
    {
      name: "Scripts",
      func: (wasSelected) => { setEditorModeHandler(wasSelected ? "Entities" : "Scripts"); }
    },
    {
      name: "Assets",
      func: (wasSelected) => { setEditorModeHandler(wasSelected ? "Entities" : "Assets"); }
    },
    {
      name: "Apps",
      func: (wasSelected) => { setEditorModeHandler(wasSelected ? "Entities" : "Apps"); }
    },
    {
      name: "Marketplace",
    }
  ], [setEditorModeHandler]);

  const getNextHighestName = useCallback((name) => {
    let nextHighestCount = 0;
    let existingValues = [];
    for (const entityProps of Object.values(entityPropertyMap)) {
      const entityName = entityProps.name;
      if (entityName !== undefined && entityName.startsWith(name + " ")) {
        const value = entityName.replace(name + " ", "");
        if (!isNaN(value)) {
          existingValues.push(parseInt(value));
        }
      }
    }
    existingValues.sort((a, b) => { return a - b });
    for (const value of existingValues) {
      if (value > 0 && value > nextHighestCount + 1) {
        break;
      } else {
        nextHighestCount = Math.max(nextHighestCount, value);
      }
    }
    return name + " " + (nextHighestCount + 1);
  }, [entityPropertyMap]);

  const subMenuOptions = useMemo(() => { return {
    "Layout": [
      {
        name: "Tri-Fold",
        func: () => {
          addEntityHandler([{
              position: "0 1.6 -1",
              scale: "1 1 1",
              rotation: "0 0 0",
              container: "size:3 1 0.25",
              name: getNextHighestName("Tri-Fold Container"),
            },
            {
              position: "-1 0 0.1",
              scale: "1 1 1",
              rotation: "0 30 0",
              container: "size:1 1 0.1",
              parent: -1,
              name: getNextHighestName("Left Container"),
            },
            {
              position: "0 0 0",
              scale: "1 1 1",
              rotation: "0 0 0",
              container: "size:1 1 0.1",
              parent: -2,
              name: getNextHighestName("Center Container"),
            },
            {
              position: "1 0 0.1",
              scale: "1 1 1",
              rotation: "0 -30 0",
              container: "size:1 1 0.1",
              parent: -3,
              name: getNextHighestName("Right Container"),
            }
          ]);
        }
      }
    ],
    "Elements": [
      {
        name: "Text",
        func: () => {
          addEntityHandler([{
            position: "0 0 -1",
            scale: "4 4 4",
            rotation: "0 0 0",
            text: "value:I'm a text entity!;align:center;color:pink;",
            name: getNextHighestName("Text"),
          }]);
        }
      },
      {
        name: "Button",
      },
      {
        name: "Shape",
        func: () => {
          addEntityHandler([{
            position: "0 0 -1",
            scale: "1 1 1",
            rotation: "0 0 0",
            geometry: "primitive:box",
            material: "color:pink",
            name: getNextHighestName("Shape"),
          }]);
        }
      },
      {
        name: "Model",
      },
      {
        name: "Image",
        func: () => {
          addEntityHandler([{
            position: "0 0 -1",
            scale: "1 1 1",
            rotation: "0 0 0",
            geometry: "primitive:plane",
            material: "src:" + process.env.PUBLIC_URL + "/assets/imageEntity.jpeg",
            name: getNextHighestName("Image"),
          }]);
        }
      },
      {
        name: "Video",
      },
      {
        name: "HTML",
        func: () => {
          addEntityHandler([{
            position: "0 0 -1",
            scale: "1 1 1",
            rotation: "0 0 0",
            html3d: "srcdoc:<iframe src='https://gydence.com' width='300px' height='300px' />;pointsPerMeter:300",
            name: getNextHighestName("HTML"),
          }]);
        }
      },
      {
        name: "Container",
        func: () => {
          addEntityHandler([{
            position: "0 0 -1",
            scale: "1 1 1",
            rotation: "0 0 0",
            container: "size:1 1 1",
            name: getNextHighestName("Container"),
          }]);
        }
      },
      {
        name: "Light",
        func: () => {
          addEntityHandler([{
            position: "0 1 0",
            scale: "1 1 1",
            rotation: "0 0 0",
            light: "type:point;distance:2",
            name: getNextHighestName("Light"),
          }]);
        }
      }
    ],
    "Scripts": [
    ],
    "Assets": [
    ],
    "Apps": [
    ],
    "Marketplace": [
      {
        name: "Browse",
        func: () => { setEditorModeHandler("Marketplace"); }
      },
      {
        name: "Inventory",
        func: () => { setEditorModeHandler("Inventory"); }
      },
      {
        name: "My Listings",
        func: () => { setEditorModeHandler("Listings"); }
      },
      {
        name: "Upload",
        func: () => { setEditorModeHandler("Upload"); }
      }
    ],
  }}, [addEntityHandler, getNextHighestName, setEditorModeHandler]);

  return (
    <div>
      {menuOptions.map((option) => {
        return <span
          key={option.name}
          value={option.name}
          className={styles.unselectable}
          style={{
            padding: "2px 10px",
            backgroundColor: (currentMenu === option.name || editorMode === option.name) ? "#dddddd" : undefined,
            borderRadius: (currentMenu === option.name || editorMode === option.name) ? "10px" : undefined,
            color: (subMenuOptions[option.name]?.length === 0 && !option.func) ? "#AAAAAA" : undefined,
            cursor: "pointer"
          }}
          onClick={() => {
            if (option.func) {
              option.func(editorMode === option.name);
            } else {
              setCurrentMenu(currentMenu === option.name ? undefined : option.name);
            }
          }}
        >{option.name}</span>
      })}
      {(currentMenu && subMenuOptions[currentMenu]?.length > 0) ?
        <div style={{paddingTop: "10px"}}>
          {subMenuOptions[currentMenu].map((option) => {
            return <span
              key={option.name}
              value={option.name}
              className={styles.unselectable}
              style={{
                padding: "0 10px",
                color: (!option.func) ? "#AAAAAA" : undefined,
                cursor: "pointer"
              }}
              onClick={() => {
                if (option.func) {
                  option.func();
                }
              }}
            >{option.name}</span>
          })}
        </div>
          : <></>
      }
    </div>
  )
}