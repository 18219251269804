import { useEffect, useState, useCallback } from "react";

import { createClient } from "@supabase/supabase-js";
import { Auth } from "@supabase/auth-ui-react";
import { ThemeSupa } from "@supabase/auth-ui-shared";

export const supabase = createClient(
  process.env.REACT_APP_NEXT_PUBLIC_SUPABASE_URL,
  process.env.REACT_APP_NEXT_PUBLIC_SUPABASE_ANON_KEY
);

export default function SignIn() {
  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        background: "rgb(168,22,54)",
        background: "linear-gradient(320deg, rgba(168,22,54,1) 0%, rgba(139,55,80,1) 15%, rgba(2,0,36,1) 50%, rgba(9,9,121,1) 85%, rgba(0,212,255,1) 100%)"
      }}
    >
      <div
        style={{
          position: "relative",
          width: "500px",
          maxWidth: "90vw",
          margin: "auto",
          top: "10vh",
          padding: "20px",
          backgroundColor: "#111",
          borderStyle: "solid",
          borderRadius: "20px",
          borderWidth: "2px",
          borderColor: "#333"
        }}
      >
        <Auth
          supabaseClient={supabase}
          appearance={{
            theme: ThemeSupa,
            style: {
              message: { color: "#aaa" }
            }
          }}
          theme="dark"
          providers={["google", "facebook", "github"]}
          magicLink
          redirectTo="/editor"
        />
      </div>
    </div>
  )
}

export function EnforceSignedIn({ children }) {
  const [setup, setSetup] = useState(false);
  const [loggedIn, setLoggedIn] = useState(undefined);

  const determineLoggedIn = useCallback(async () => {
    const { data } = await supabase.auth.getUser();
    setLoggedIn(data?.user?.email !== undefined);
  }, []);

  useEffect(() => {
    if (!setup) {
      const init = async () => {
        determineLoggedIn();

        supabase.auth.getSession().then((result) => {
          let currentSession = result?.data?.session;
          supabase.auth.onAuthStateChange(async (event, session) => {
            // Supabase sends a SIGNED_IN event if you've switched to a different
            // tab or window and back.  Filter those out.
            if (session?.user?.id === currentSession?.user?.id) {
              return;
            }

            currentSession = session;

            determineLoggedIn();
          });
        });
      }
      init();

      setSetup(true);
    }
  }, []);

  if (loggedIn === undefined) {
    return (
      <></>
    )
  } else if (!loggedIn) {
    return (
      <SignIn />
    )
  }

  return (
    <>
      {children}
    </>
  )
}