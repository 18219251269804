import { useEffect, useState, useRef, useCallback } from "react";

import { IconButton, MenuItem, TextField } from "@mui/material";
import { Add, Settings } from "@mui/icons-material";

import { makeElementsDraggable, replaceHTMLIncludes, debounce, convertKeyName } from "../gydence/utils";

import { EnforceSignedIn, supabase } from "./signin";
import EnforceOnboarded from "./onboard";

import EntityList from "./entityList";
import EntityPropertyList, { UIEditor, ScriptPropEditor, AssetPropEditor, OwnedScriptViewer, AppPropEditor } from "./propertyList";
import EntityMenu from "./entityMenu";
import EditSiteUI from "./editSite";
import Marketplace from "./marketplace";
import UploadManager from "./marketplaceUpload";
import PublishSite from "./publish";
import { ItemListPanel, NUM_ITEMS_PER_PAGE } from "../components/itemListEditor";
import SEO from "../components/seo";
import gydenceAPI from "./api";

import styles from "./index.module.css";

function deepEquals(x, y) {
  const keys = Object.keys;
  const typeX = typeof x;
  const typeY = typeof y;

  return x && y && typeX === "object" && typeX === typeY ? (
    keys(x).length === keys(y).length &&
      keys(x).every((key) => deepEquals(x[key], y[key]))
  ) : (x === y);
}

const buildEntityUnknownParentSet = (entityPropertyMap) => {
  let entityUnknownParentSet = new Set();

  for (const entityProps of Object.values(entityPropertyMap)) {
    const parent = entityProps.parent;
    if (parent != undefined && !(parent in entityPropertyMap)) {
      entityUnknownParentSet.add(parent);
    }
  }

  return entityUnknownParentSet;
};

const parseSiteProperties = (siteData) => {
  let scenePropertyMapData = {};
  let environmentData = undefined;
  let entityPropertyMapData = {};
  let entityParentMapData = {};

  let overlayElementsData = undefined;
  let cssPropsData = undefined;

  let scriptsData = [];
  let appsData = [];

  if (siteData) {
    if (siteData.scene) {
      scenePropertyMapData = siteData.scene;
    }

    if (siteData.environment) {
      environmentData = siteData.environment;
    }

    if (siteData.entities) {
      for (const entity of siteData.entities) {
        const parentID = entity.parent;
        if (!(parentID in entityParentMapData)) {
          entityParentMapData[parentID] = [];
        }
        entityParentMapData[parentID].push(entity.id);
        entityPropertyMapData[entity.id] = entity;
      }
    }

    if (siteData.overlay) {
      overlayElementsData = siteData.overlay;
    }

    if (siteData.css) {
      cssPropsData = siteData.css;
    }

    if (siteData.scripts) {
      scriptsData = siteData.scripts;
    }

    if (siteData.apps) {
      appsData = siteData.apps;
    }
  }

  return [scenePropertyMapData, environmentData, entityPropertyMapData,
          entityParentMapData, overlayElementsData, cssPropsData, scriptsData, appsData];
};

const siteModes = ["New Project", "Edit Project"];
const marketplaceModes = ["Marketplace", "Inventory", "Listings"];
const itemListModes = ["Scripts", "Assets", "Apps"];
const publishModes = ["Preview", "Publish"];

let actionStack = [];
let actionIndex = 0;

const recordAction = (action) => {
  if (actionIndex < actionStack.length) {
    actionStack = actionStack.splice(0, actionIndex);
  }
  actionStack.push(action);
  actionIndex++;
}

function EditorMain() {
  const [setup, setSetup] = useState(false);

  /// Login state + user data
  const [user, setUser] = useState([]);

  // iFrame
  const editorViewFrameRef = useRef(undefined);

  // Owned items
  const [ownedItems, setOwnedItems] = useState([]);
  const [ownedListings, setOwnedListings] = useState([]);
  const [ownedTemplates, setOwnedTemplates] = useState([]);
  const [ownedEnvironments, setOwnedEnvironments] = useState([]);
  const [ownedScripts, setOwnedScripts] = useState([]);
  const [ownedAssets, setOwnedAssets] = useState([]);
  const [ownedApps, setOwnedApps] = useState([]);

  const [targetListing, setTargetListing] = useState(undefined);

  useEffect(() => {
    if (ownedItems?.length > 0) {
      const fetchOwnedItems = async () => {
        const { data } = await supabase.from("marketplace_items").select("*")
          .in("id", ownedItems).limit(ownedItems.length);
        if (data) {
          let listingsData = [];
          let templatesData = [];
          let environmentsData = [];
          let scriptsData = [];
          let assetsData = [];
          let appsData = [];
          for (let item of data) {
            if (item.listing) {
              listingsData.push(item.listing);
            }
            switch (item.type) {
              case "template":
                templatesData.push(item);
                break;
              case "environment":
                environmentsData.push(item);
                break;
              case "script":
                scriptsData.push(item);
                break;
              case "asset":
                assetsData.push(item);
                break;
              case "app":
                appsData.push(item);
                break;
              default:
                break;
            }
          }
          setOwnedListings(listingsData);
          setOwnedTemplates(templatesData);
          setOwnedEnvironments(environmentsData);
          // Data is stored separately, so we grab it and populate it in the arrays
          {
            const { data } = await supabase.from("marketplace_data").select("*")
              .in("id", ownedItems).limit(ownedItems.length);
            const arrays = [scriptsData, assetsData, appsData];
            for (let item of data) {
              for (let array of arrays) {
                const index = array.findIndex((arrayItem) => { return arrayItem.id === item.id });
                if (index !== -1) {
                  array[index].data = item.data;
                  break;
                }
              }
            }
          }
          setOwnedScripts(scriptsData);
          setOwnedAssets(assetsData);
          setOwnedApps(appsData);

          return;
        }

        setOwnedListings([]);
        setOwnedTemplates([]);
        setOwnedEnvironments([]);
        setOwnedScripts([]);
        setOwnedAssets([]);
        setOwnedApps([]);
      };
      fetchOwnedItems();
    } else {
      setOwnedListings([]);
      setOwnedTemplates([]);
      setOwnedEnvironments([]);
      setOwnedScripts([]);
      setOwnedAssets([]);
      setOwnedApps([]);
    }
  }, [ownedItems]);

  const getUserDataAndRefreshOwnedItems = useCallback(async () => {
    const { data } = await supabase.from("user_data_private").select("*").limit(1);
    let userData = data?.[0];
    setOwnedItems(userData?.owned_items);
    return userData;
  }, []);

  // Site/entity/UI/script selection
  const [sites, setSites] = useState([]);
  const [currentSite, setCurrentSite] = useState(undefined);
  const [siteName, setSiteName] = useState("");
  const [siteCreator, setSiteCreator] = useState("");
  const [siteEditors, setSiteEditors] = useState("");
  const [selectedObject, setSelectedObject] = useState(undefined);

  useEffect(() => {
    const currentSites = [...sites];
    for (let site of currentSites) {
      if (site.id === currentSite) {
        site.name = siteName;
      }
    }
  }, [siteName]);

  const refreshSites = useCallback(async () => {
    const { data } = await supabase.from("sites").select("*");
    if (data) {
      // Sort site names alphabetically
      data.sort((siteA, siteB) => {
        if (siteA.name === siteB.name) {
          // Use ID as tie breaker
          return siteA.id < siteB.id ? -1 : 1;
        }
        return siteA.name < siteB.name ? -1 : 1;
      });
      setSites(data);
    }
    return data;
  }, []);

  const updateSiteNameHandler = useCallback((name) => {
    setSiteName(name);
    supabase.rpc("update_site_name", {
      site: currentSite,
      value: name,
    }).then(async (result) => {
      if (result.error) {
        console.log(result);
      }
    });
  }, [currentSite]);

  const updateSiteEditorsHandler = useCallback((editors) => {
    setSiteEditors(editors);
    supabase.rpc("update_site_editors", {
      site: currentSite,
      value: editors,
    }).then(async (result) => {
      if (result.error) {
        console.log(result);
      }
    });
  }, [currentSite]);

  // Scene/entity state maps
  const [scenePropertyMap, setScenePropertyMap] = useState({});
  const [environment, setEnvironment] = useState(undefined);
  const [environmentEntities, setEnvironmentEntities] = useState(undefined);
  const [entityPropertyMap, setEntityPropertyMap] = useState({});
  const [entityParentMap, setEntityParentMap] = useState({});
  const [entityUnknownParentSet, setEntityUnknownParentSet] = useState(new Set());
  const [unknownParentChildren, setUnknownParentChildren] = useState([]);
  const [cameraChildren, setCameraChildren] = useState([]);

  useEffect(() => {
    if (environment) {
      supabase.from("marketplace_data").select("data").eq("id", environment).limit(1)
        .then((result) => {
          if (!result.error && result.data.length > 0) {
            setEnvironmentEntities(result.data[0].data?.environment);
          }
        });
    } else {
      setEnvironmentEntities(undefined);
    }
  }, [environment]);

  useEffect(() => {
    const unknownParentChildrenData = [];
    const cameraChildrenData = [];
    entityUnknownParentSet.forEach((child) => {
      if (child.toLowerCase() === "camera") {
        cameraChildrenData.push(child);
      } else {
        unknownParentChildrenData.push(child);
      }
    });
    setCameraChildren(cameraChildrenData);
    setUnknownParentChildren(unknownParentChildrenData);
  }, [entityUnknownParentSet]);

  // 2D UI
  const [overlayElements, setOverlayElements] = useState(undefined);
  const [cssProps, setCSSProps] = useState(undefined);
  const [reloadOn2DChange, setReloadOn2DChange] = useState(false);

  useEffect(() => {
    setEntityUnknownParentSet(buildEntityUnknownParentSet(entityPropertyMap));
  }, [entityPropertyMap]);

  // Scripts
  const [scripts, setScripts] = useState([]);
  const [reloadOnScriptChange, setReloadOnScriptChange] = useState(true);
  const [scriptUpdateCounter, setScriptUpdateCounter] = useState(0);
  const incrementScriptUpdateCounterDebounced = useCallback(
    debounce(() => setScriptUpdateCounter(scriptUpdateCounter + 1), 2000)
  , [scriptUpdateCounter]);

  // Apps
  const [apps, setApps] = useState([]);

  useEffect(() => {
    if (reloadOn2DChange) {
      incrementScriptUpdateCounterDebounced();
    }
  }, [overlayElements, cssProps]);

  useEffect(() => {
    if (reloadOnScriptChange) {
      incrementScriptUpdateCounterDebounced();
    }
  }, [scripts]);

  // FIXME: organize all of this so this is closer to other iFrame functions
  const updateEditorViewFrame = useCallback((entityPropertyMap) => {
    if (editorViewFrameRef.current) {
      const message = {
        type: "data-update",
        scenePropertyMap: scenePropertyMap,
        environment: environment,
        entityPropertyMap: entityPropertyMap,
        entityParentMap: entityParentMap,
        overlayElements: overlayElements,
        cssProps: cssProps
      };
      editorViewFrameRef.current.contentWindow.postMessage(message);
    }
  }, [scenePropertyMap, environment, entityParentMap, overlayElements, cssProps, editorViewFrameRef]);

  // FIXME: a lot of these handlers should take an array and set multiple values, like addEntityHandler
  const updateScenePropHandler = useCallback((prop, value, targetSite, record = true) => {
    if (record) {
      recordAction({
        type: "sceneProp",
        time: Date.now(),
        prop: prop,
        prevValue: scenePropertyMap[prop] ? JSON.parse(JSON.stringify(scenePropertyMap[prop])) : undefined,
        value: value
      });
    }

    // Update the scene's property in the property map
    let currentScenePropertyMap = {...scenePropertyMap};
    currentScenePropertyMap[prop] = value;
    setScenePropertyMap(currentScenePropertyMap);

    const site = targetSite ?? currentSite;
    supabase.rpc("update_scene_property", {
      site: site,
      property: prop,
      value: value,
      forceupdate: "false",
    }).then((result) => {
      if (result.error) {
        console.log(result);
      }
    });
  }, [currentSite, scenePropertyMap]);

  const removeScenePropHandler = useCallback((prop, record = true) => {
    if (record) {
      recordAction({
        type: "sceneProp",
        time: Date.now(),
        prop: prop,
        prevValue: scenePropertyMap[prop] ? JSON.parse(JSON.stringify(scenePropertyMap[prop])) : undefined,
        value: undefined
      });
    }

    // Remove this prop from the scene's property map
    let currentScenePropertyMap = {...scenePropertyMap};
    delete currentScenePropertyMap[prop];
    setScenePropertyMap(currentScenePropertyMap);

    supabase.rpc("remove_scene_property", {
      site: currentSite,
      property: prop,
      forceupdate: "false",
    }).then((result) => {
      if (result.error) {
        console.log(result);
      }
    });
  }, [currentSite, scenePropertyMap]);

  const setEnvironmentHandler = useCallback((newEnvironment, targetSite, record = true) => {
    if (record) {
      recordAction({
        type: "environment",
        time: Date.now(),
        prevValue: environment,
        value: newEnvironment
      });
    }

    setEnvironment(newEnvironment);

    const site = targetSite ?? currentSite;
    supabase.rpc("set_environment", {
      site: site,
      value: newEnvironment,
    }).then((result) => {
      if (result.error) {
        console.log(result);
      }
    });
  }, [currentSite, environment]);

  const addEntityHandler = useCallback(async (propsArray, targetSite, afterFunc, record = true) => {
    const site = targetSite ?? currentSite;
    return supabase.rpc("add_entities", {
      site: site,
      propsarray: propsArray,
      forceupdate: "false",
    }).then((result) => {
      if (result.error) {
        console.log(result);
      } else {
        if (record) {
          recordAction({
            type: "addEntities",
            time: Date.now(),
            entities: propsArray.map((props, i) => {
              props["id"] = result.data[i];
              return props;
            })
          });
        }

        let currentEntityParentMap = {...entityParentMap};
        let currentEntityPropertyMap = {...entityPropertyMap};

        for (let i = 0; i < propsArray.length; i++) {
          const id = result.data[i];
          let props = propsArray[i];
          // id is only set on the server
          props.id = id;

          // FIXME: this way of specifying relative parent by offset is kinda funky
          if ("parent" in props && props.parent < 0 && (i + props.parent) >= 0) {
            props.parent = result.data[i + props.parent];
          }

          // Add this entity to its parent's children
          const parentID = props.parent;
          if (!(parentID in currentEntityParentMap)) {
            currentEntityParentMap[parentID] = [];
          }
          currentEntityParentMap[parentID].push(props.id);

          // Add this entity to the property map
          currentEntityPropertyMap[id] = props;
        }

        setEntityParentMap(currentEntityParentMap);
        setEntityPropertyMap(currentEntityPropertyMap);
        updateEditorViewFrame(currentEntityPropertyMap);

        // Set this entity to the selected entity
        setSelectedObject(result.data[0]);

        if (afterFunc) {
          afterFunc();
        }
      }
    });
  }, [currentSite, entityParentMap, entityPropertyMap, updateEditorViewFrame]);

  const deleteEntityHandler = useCallback((entity, record = true) => {
    if (record) {
      recordAction({
        type: "deleteEntity",
        time: Date.now(),
        entity: {...entityPropertyMap[entity]}
      });
    }

    let currentEntityParentMap = {...entityParentMap};
    // Remove this entity from its parent's children
    let parentEntity = entityPropertyMap[entity].parent;
    currentEntityParentMap[parentEntity] =
      currentEntityParentMap[parentEntity].filter(function(currEntity) {
        return entity !== currEntity;
      });
    setEntityParentMap(currentEntityParentMap);

    // Remove this entity from the property map
    let currentEntityPropertyMap = {...entityPropertyMap};
    delete currentEntityPropertyMap[entity];
    setEntityPropertyMap(currentEntityPropertyMap);
    updateEditorViewFrame(currentEntityPropertyMap);

    // If this was the selected entity, clear it
    if (selectedObject === entity) {
      setSelectedObject(undefined);
    }

    return supabase.rpc("delete_entity", {
      site: currentSite,
      entity: entity,
      forceupdate: "false",
    }).then((result) => {
      if (result.error) {
        console.log(result);
      }
    });
  }, [currentSite, entityParentMap, entityPropertyMap, selectedObject, updateEditorViewFrame]);

  const updatePropHandler = useCallback((entity, prop, value, record = true, updateFrame = true) => {
    if (record) {
      recordAction({
        type: "entityProp",
        time: Date.now(),
        entity: entity,
        prop: prop,
        prevValue: entityPropertyMap[entity][prop] ? JSON.parse(JSON.stringify(entityPropertyMap[entity][prop])) : undefined,
        value: value
      });
    }

    if (prop === "parent") {
      let currentEntityParentMap = {...entityParentMap};
      // Remove this entity from its old parent's children
      let parentEntity = entityPropertyMap[entity].parent;
      if (currentEntityParentMap[parentEntity]) {
        currentEntityParentMap[parentEntity] =
          currentEntityParentMap[parentEntity].filter((el) => {
            return el !== entity;
          });
      }

      // Add this entity to its new parent's children
      if (!(value in currentEntityParentMap)) {
        currentEntityParentMap[value] = [];
      }
      currentEntityParentMap[value].push(entity);
      setEntityParentMap(currentEntityParentMap);
    }

    // Update this entity's property in the property map
    let currentEntityPropertyMap = {...entityPropertyMap};
    // HACK: deep copy the inner props to force a downstream update
    let currentEntityProperties = {...currentEntityPropertyMap[entity]};
    currentEntityProperties[prop] = value;
    currentEntityPropertyMap[entity] = currentEntityProperties;
    setEntityPropertyMap(currentEntityPropertyMap);
    if (updateFrame) {
      updateEditorViewFrame(currentEntityPropertyMap);
    }

    supabase.rpc("update_entity_property", {
      site: currentSite,
      entity: entity,
      property: prop,
      value: value,
      forceupdate: "false",
    }).then((result) => {
      if (result.error) {
        console.log(result);
      }
    });
  }, [currentSite, entityParentMap, entityPropertyMap, updateEditorViewFrame]);

  const removePropHandler = useCallback((entity, prop, record = true) => {
    if (record) {
      recordAction({
        type: "entityProp",
        time: Date.now(),
        entity: entity,
        prop: prop,
        prevValue: entityPropertyMap[entity][prop] ? JSON.parse(JSON.stringify(entityPropertyMap[entity][prop])) : undefined,
        value: undefined
      });
    }

    if (prop === "parent") {
      let parentID = entityPropertyMap[entity].parent;
      // Remove this entity from its parent's children
      let currentEntityParentMap = {...entityParentMap};
      currentEntityParentMap[parentID] =
        currentEntityParentMap[parentID].filter(function(el) {
          return el !== entity;
        });

      // Add this entity to its new parent's children
      if (!(undefined in currentEntityParentMap)) {
        currentEntityParentMap[undefined] = [];
      }
      currentEntityParentMap[undefined].push(entity);
      setEntityParentMap(currentEntityParentMap);
    }

    // Remove this prop from this entity's property map
    let currentEntityPropertyMap = {...entityPropertyMap};
    // HACK: deep copy the inner props to force a downstream update
    let currentEntityProperties = {...currentEntityPropertyMap[entity]};
    delete currentEntityProperties[prop];
    currentEntityPropertyMap[entity] = currentEntityProperties;
    setEntityPropertyMap(currentEntityPropertyMap);
    updateEditorViewFrame(currentEntityPropertyMap);

    supabase.rpc("remove_entity_property", {
      site: currentSite,
      entity: entity,
      property: prop,
      forceupdate: "false"
    }).then((result) => {
      if (result.error) {
        console.log(result);
      }
    });
  }, [currentSite, entityParentMap, entityPropertyMap, updateEditorViewFrame]);

  const updateOverlayElementHandler = useCallback((value, targetSite, record = true) => {
    if (record) {
      recordAction({
        type: "overlay",
        time: Date.now(),
        prevValue: overlayElements,
        value: value
      });
    }

    setOverlayElements(value);

    const site = targetSite ?? currentSite;
    supabase.rpc("update_overlay_elements", {
      site: site,
      value: value,
      forceupdate: "false",
    }).then((result) => {
      if (result.error) {
        console.log(result);
      }
    });
  }, [currentSite, overlayElements]);

  const updateCSSHandler = useCallback((value, targetSite, record = true) => {
    if (record) {
      recordAction({
        type: "css",
        time: Date.now(),
        prevValue: cssProps,
        value: value
      });
    }

    setCSSProps(value);

    const site = targetSite ?? currentSite;
    supabase.rpc("update_css", {
      site: site,
      value: value,
      forceupdate: "false",
    }).then((result) => {
      if (result.error) {
        console.log(result);
      }
    });
  }, [currentSite, cssProps]);

  const addScriptHandler = useCallback(async (props, afterFunc, targetSite, record = true) => {
    const site = targetSite ?? currentSite;
    return supabase.rpc("add_script", {
      site: site,
      props: props
    }).then((result) => {
      if (result.error) {
        console.log(result);
      } else {
        // id is only set on the server
        props.id = result.data;

        if (record) {
          recordAction({
            type: "addScript",
            time: Date.now(),
            props: props
          });
        }

        // Add this script to the list
        setScripts(scripts => scripts.concat(props));

        // Set this script to the selected script
        setSelectedObject(result.data);

        if (afterFunc) {
          afterFunc(result.data);
        }
      }
    });
  }, [currentSite, scripts]);

  const deleteScriptHandler = useCallback((script, record = true) => {
    const scriptIndex = scripts.findIndex((el) => el.id === script);

    if (record) {
      recordAction({
        type: "deleteScript",
        time: Date.now(),
        props: {...scripts[scriptIndex]}
      });
    }

    // Remove this script from the list
    setScripts(scripts => {
      scripts.splice(scriptIndex, 1);
      return [...scripts];
    });

    // If this was the selected script, clear it
    if (selectedObject === script) {
      setSelectedObject(undefined);
    }

    supabase.rpc("delete_script", {
      site: currentSite,
      scriptindex: scriptIndex
    }).then((result) => {
      if (result.error) {
        console.log(result);
      }
    });
  }, [scripts, currentSite, selectedObject]);

  const updateScriptPropHandler = useCallback((script, prop, value, record = true) => {
    const scriptIndex = scripts.findIndex((el) => el.id === script);

    if (record) {
      recordAction({
        type: "scriptProp",
        time: Date.now(),
        script: script,
        prop: prop,
        prevValue: scripts[scriptIndex][prop] ? JSON.parse(JSON.stringify(scripts[scriptIndex][prop])) : undefined,
        value: value
      });
    }

    // Update this script's property in the list
    setScripts(scripts => {
      // HACK: deep copy the inner props to force a downstream update
      let currentScripts = scripts.map((script) => { return {...script}; });
      currentScripts[scriptIndex][prop] = value;
      return currentScripts;
    });

    supabase.rpc("update_script_property", {
      site: currentSite,
      script: script,
      property: prop,
      value: value,
      forceupdate: "false",
    }).then((result) => {
      if (result.error) {
        console.log(result);
      }
    });
  }, [currentSite, scripts]);

  const addAppHandler = useCallback(async (props, afterFunc, targetSite, record = true) => {
    const site = targetSite ?? currentSite;
    return supabase.rpc("add_app", {
      site: site,
      props: props
    }).then((result) => {
      if (result.error) {
        console.log(result);
      } else {
        // id is only set on the server
        props.id = result.data;

        if (record) {
          recordAction({
            type: "addApp",
            time: Date.now(),
            props: props
          });
        }

        // Add this app to the list
        setApps(apps => apps.concat(props));

        // Set this app to the selected app
        setSelectedObject(result.data);

        if (afterFunc) {
          afterFunc(result.data);
        }
      }
    });
  }, [currentSite, apps]);

  const deleteAppHandler = useCallback((app, record = true) => {
    const appIndex = apps.findIndex((el) => el.id === app);

    if (record) {
      recordAction({
        type: "deleteApp",
        time: Date.now(),
        props: {...apps[appIndex]}
      });
    }

    // Remove this app from the list
    setApps(apps => {
      apps.splice(appIndex, 1);
      return [...apps];
    });

    // If this was the selected app, clear it
    if (selectedObject === app) {
      setSelectedObject(undefined);
    }

    supabase.rpc("delete_app", {
      site: currentSite,
      appindex: appIndex
    }).then((result) => {
      if (result.error) {
        console.log(result);
      }
    });
  }, [apps, currentSite, selectedObject]);

  const updateAppPropHandler = useCallback((app, prop, value, record = true) => {
    const appIndex = apps.findIndex((el) => el.id === app);

    if (record) {
      recordAction({
        type: "appProp",
        time: Date.now(),
        app: app,
        prop: prop,
        prevValue: apps[appIndex][prop] ? JSON.parse(JSON.stringify(apps[appIndex][prop])) : undefined,
        value: value
      });
    }

    // Update this app's property in the list
    setApps(apps => {
      // HACK: deep copy the inner props to force a downstream update
      let currentApps = apps.map((app) => { return {...app}; });
      currentApps[appIndex][prop] = value;
      return currentApps;
    });

    supabase.rpc("update_app_property", {
      site: currentSite,
      app: app,
      property: prop,
      value: value,
      forceupdate: "false",
    }).then((result) => {
      if (result.error) {
        console.log(result);
      }
    });
  }, [currentSite, apps]);

  const setSelectedObjectHandler = useCallback((newSelection, record = true) => {
    if (document.isKeyPressed("P") && newSelection in entityPropertyMap && selectedObject in entityPropertyMap) {
      updatePropHandler(selectedObject, "parent", newSelection);
    } else {
      if (record) {
        recordAction({
          type: "selection",
          time: Date.now(),
          prevValue: selectedObject,
          value: newSelection
        });
      }

      setSelectedObject(newSelection);
    }
  }, [selectedObject, entityPropertyMap, updatePropHandler]);

  const copyPaste = useCallback(async (copy, shifted) => {
    if (copy) {
      if (selectedObject) {
        const write = (data) => {
          const type = "text/plain";
          const blob = new Blob([JSON.stringify(data)], { type });
          navigator.clipboard.write([new ClipboardItem({ [type]: blob })]);
        };
        if (selectedObject in entityPropertyMap) {
          write({
            type: "entity",
            props: entityPropertyMap[selectedObject]
          });
        } else if (selectedObject === "Scene") {
          write({
            type: "scene",
            props: scenePropertyMap
          });
        } else if (selectedObject === "2D UI") {
          write({
            type: "overlayAndCSS",
            html: overlayElements,
            css: cssProps
          });
        } else if (scripts.find((script) => script.id === selectedObject)) {
          const scriptIndex = scripts.findIndex((el) => el.id === selectedObject);
          write({
            type: "script",
            props: scripts[scriptIndex]
          });
        } else if (apps.find((app) => app.id === selectedObject)) {
          const appIndex = apps.findIndex((el) => el.id === selectedObject);
          write({
            type: "app",
            props: apps[appIndex]
          });
        }
      }
    } else {
      // TODO: handle other media types
      navigator.clipboard.readText().then((result) => {
        if (result) {
          let json = JSON.parse(result);
          if ("type" in json) {
            if (json.type === "entity") {
              if (shifted) {
                if (selectedObject === undefined) {
                  delete json.props.parent;
                } else if (selectedObject in entityPropertyMap) {
                  json.props.parent = selectedObject;
                }
              }
              addEntityHandler([json.props]);
            } else if (json.type === "scene") {
              // TODO: make this undoable as a single action?
              for (let prop of Object.keys(json.props)) {
                updateScenePropHandler(prop, json.props[prop]);
              }
            } else if (json.type === "overlayAndCSS") {
              updateOverlayElementHandler(json.html);
              updateCSSHandler(json.css);
            } else if (json.type === "script") {
              addScriptHandler(json.props);
            } else if (json.type === "app") {
              addAppHandler(json.props);
            }
          }
        }
      });
    }
  }, [selectedObject, entityPropertyMap, scenePropertyMap, overlayElements, cssProps, scripts, apps,
      updateScenePropHandler, addEntityHandler, updateOverlayElementHandler, updateCSSHandler, addScriptHandler, addAppHandler]);

  const undoRedo = useCallback(async (backwards) => {
    if (backwards) {
      if (actionIndex > 0) {
        const action = actionStack[--actionIndex];
        switch (action.type) {
          case "sceneProp":
            if (action.prevValue !== undefined) {
              updateScenePropHandler(action.prop, action.prevValue, undefined, false);
            } else {
              removeScenePropHandler(action.prop, false);
            }
            break;
          case "environment":
            setEnvironmentHandler(action.prevValue, undefined, false);
            break;
          case "addEntities":
            for (let props of action.entities) {
              // FIXME: this isn't working, make it support multiple entities?
              await deleteEntityHandler(props.id, false);
            }
            break;
          case "deleteEntity":
            addEntityHandler([action.entity], undefined, undefined, false);
            break;
          case "entityProp":
            if (action.prevValue !== undefined) {
              updatePropHandler(action.entity, action.prop, action.prevValue, false);
            } else {
              removePropHandler(action.entity, action.prop, false);
            }
            break;
          case "overlay":
            updateOverlayElementHandler(action.prevValue, undefined, false);
            break;
          case "css":
            updateCSSHandler(action.prevValue, undefined, false);
            break;
          case "addScript":
            deleteScriptHandler(action.props.id, false);
            break;
          case "deleteScript":
            addScriptHandler(action.props, undefined, undefined, false);
            break;
          case "scriptProp":
            updateScriptPropHandler(action.script, action.prop, action.prevValue, false);
            break;
          case "addApp":
            deleteAppHandler(action.props.id, false);
            break;
          case "deleteApp":
            addAppHandler(action.props, undefined, undefined, false);
            break;
          case "appProp":
            updateAppPropHandler(action.app, action.prop, action.prevValue, false);
            break;
          case "selection":
            setSelectedObjectHandler(action.prevValue, false);
            break;
          default:
            break;
        }
      }
    } else {
      if (actionIndex <= actionStack.length - 1) {
        const action = actionStack[actionIndex++];
        switch (action.type) {
          case "sceneProp":
            if (action.value !== undefined) {
              updateScenePropHandler(action.prop, action.value, undefined, false);
            } else {
              removeScenePropHandler(action.prop, false);
            }
            break;
          case "environment":
            setEnvironmentHandler(action.value, undefined, false);
            break;
          case "addEntities":
            addEntityHandler(action.entities, undefined, undefined, false);
            break;
          case "deleteEntity":
            deleteEntityHandler(action.entity.id, false);
            break;
          case "entityProp":
            if (action.value !== undefined) {
              updatePropHandler(action.entity, action.prop, action.value, false);
            } else {
              removePropHandler(action.entity, action.prop, false);
            }
            break;
          case "overlay":
            updateOverlayElementHandler(action.value, undefined, false);
            break;
          case "css":
            updateCSSHandler(action.value, undefined, false);
            break;
          case "addScript":
            addScriptHandler(action.props, undefined, undefined, false);
            break;
          case "deleteScript":
            deleteScriptHandler(action.props.id, false);
            break;
          case "scriptProp":
            updateScriptPropHandler(action.script, action.prop, action.value, false);
            break;
          case "addApp":
            addAppHandler(action.props, undefined, undefined, false);
            break;
          case "deleteApp":
            deleteAppHandler(action.props.id, false);
            break;
          case "appProp":
            updateAppPropHandler(action.app, action.prop, action.value, false);
            break;
          case "selection":
            setSelectedObjectHandler(action.value, false);
            break;
          default:
            break;
        }
      }
    }
  }, [updateScenePropHandler, removeScenePropHandler, addEntityHandler, deleteEntityHandler, updatePropHandler, removePropHandler,
      updateOverlayElementHandler, updateCSSHandler, addScriptHandler, updateScriptPropHandler, addAppHandler, updateAppPropHandler,
      setEnvironmentHandler, setSelectedObjectHandler]);

  const deleteObject = useCallback(() => {
    if (selectedObject) {
      if (selectedObject in entityPropertyMap) {
        deleteEntityHandler(selectedObject);
      } else if (scripts.find((script) => script.id === selectedObject)) {
        deleteScriptHandler(selectedObject);
      } else if (apps.find((app) => app.id === selectedObject)) {
        deleteAppHandler(selectedObject);
      }
    }
  }, [selectedObject, entityPropertyMap, scripts, apps, deleteEntityHandler, deleteScriptHandler, deleteAppHandler]);

  // Assets
  const [assets, setAssets] = useState([]);
  const [assetsOffset, setAssetsOffset] = useState(0);

  const getAssets = useCallback(async (site) => {
    const { data } = await supabase.storage.from("storage")
      .list("sites/" + site + "/private/", {
        limit: NUM_ITEMS_PER_PAGE + 1,
        offset: assetsOffset,
        sortBy: { column: "name", order: "asc" },
      });
    setAssets(data);
  }, [assetsOffset]);

  const dropAssetHandler = useCallback((event, func) => {
    event.preventDefault();

    if (event.dataTransfer.items) {
      for (const item of event.dataTransfer.items) {
        if (item.kind === "file") {
          const file = item.getAsFile();
          func(file);
        }
      }
    } else {
      for (const file of event.dataTransfer.files) {
        func(file);
      }
    }
  }, []);

  const addAssetHandler = useCallback(async (file) => {
    const { data, error } = await supabase.storage.from("storage")
      .upload("sites/" + currentSite + "/private/" + file.name, file, {
        cacheControl: "3600",
        upsert: true
    });

    if (!error) {
      getAssets(currentSite);
    } else {
      console.log(error);
    }

    return data;
  }, [currentSite, getAssets]);

  const deleteAssetHandler = useCallback(async (fileName) => {
    const { error } = await supabase.storage.from("storage")
      .remove(["sites/" + currentSite + "/private/" + fileName]);

    if (!error) {
      getAssets(currentSite);
    }
  }, [currentSite, getAssets]);

  const getPublicURL = useCallback((fileName) => {
    const { data } = supabase.storage.from("storage")
      .getPublicUrl("sites/" + currentSite + "/private/" + fileName);

    return data?.publicUrl;
  }, [currentSite]);

  const addEntityFromAssetHandler = useCallback(async (fileName, fileURL, fileType, privateURL) => {
    let props = undefined;

    const ext = fileURL.split(/[#?]/)[0].split('.').pop().trim();
    const imageExts = ["png", "jpg", "jpeg"];
    const videoExts = ["mp4", "mov"];
    const modelExts = ["gyde", "gltf", "glb", "obj", "3dm"];

    if (fileType.startsWith("image") || imageExts.indexOf(ext) !== -1) {
      props = [{
        position: "0 0 -1",
        scale: "1 1 1",
        rotation: "0 0 0",
        geometry: "primitive:plane",
        material: "src:" + fileURL,
        name: fileName,
      }];

      if (privateURL) {
        props[0].privateProps = ["material.src"];
      }
    } else if (fileType.startsWith("video") || videoExts.indexOf(ext) !== -1) {
      props = [{
        position: "0 0 -1",
        scale: "1 1 1",
        rotation: "0 0 0",
        geometry: "primitive:plane",
        material: "src:" + fileURL,
        name: fileName,
      }];

      if (privateURL) {
        props[0].privateProps = ["material.src"];
      }
    } else if (modelExts.indexOf(ext) !== -1) {
      props = [{
        position: "0 0 -1",
        scale: "1 1 1",
        rotation: "0 0 0",
        model: "url:" + fileURL,
        name: fileName,
      }];

      if (privateURL) {
        props[0].privateProps = ["model.url"];
      }
    }

    if (props) {
      addEntityHandler(props);
    }
  }, [addEntityHandler]);

  const addAssetAndEntityHandler = useCallback(async (file) => {
    const asset = addAssetHandler(file);
    if (asset) {
      addEntityFromAssetHandler(file.name, getPublicURL(file.name), file.type, false);
    }
  }, [addAssetHandler, addEntityFromAssetHandler, getPublicURL]);

  const updateAssetNameHandler = useCallback(async (oldName, newName) => {
    supabase.storage.from("storage")
      .move("sites/" + currentSite + "/private/" + oldName, "sites/" + currentSite + "/private/" + newName)
      .then(async (result) => {
        if (result) {
          getAssets(currentSite);
        }
      });
  }, [currentSite, getAssets]);

  useEffect(() => {
    getAssets(currentSite);
  }, [assetsOffset]);

  // iFrame
  const updateFrameAPI = useCallback((frame) => {
    const message = {
      type: "api-update",
      currentSite: currentSite,
      scenePropertyMap: scenePropertyMap,
      entityPropertyMap: entityPropertyMap,
      overlayElements: overlayElements,
      cssProps: cssProps,
      scripts: scripts,
      apps: apps
    };
    frame.contentWindow.postMessage(message);
  }, [currentSite, scenePropertyMap, entityPropertyMap, overlayElements, cssProps, scripts, apps]);

  useEffect(() => {
    if (editorViewFrameRef.current) {
      updateFrameAPI(editorViewFrameRef.current);
    }
  }, [currentSite, scenePropertyMap, entityPropertyMap, overlayElements, cssProps, scripts, apps]);

  const updateEditorViewFrameScriptsLoaded = useCallback(() => {
    const message = {
      type: "scripts-loaded"
    };
    editorViewFrameRef.current.contentWindow.postMessage(message);
  }, []);

  const updateEditorViewFrameScripts = useCallback(() => {
    if (!document.querySelector("#api")) {
      const scriptElement = editorViewFrameRef.current.contentWindow.document.createElement("script");
      scriptElement.type = "text/javascript";
      scriptElement.id = "api";
      scriptElement.className = "api";
      scriptElement.innerHTML = gydenceAPI({
        currentSite: currentSite,
        isEditing: true,
        isApp: false,
        isPublic: false,
        scenePropertyMap: scenePropertyMap,
        entityPropertyMap: entityPropertyMap,
        html: overlayElements,
        css: cssProps,
        scripts: scripts,
        apps: apps,
      });
      editorViewFrameRef.current.contentWindow.document.head.appendChild(scriptElement);
    }

    let scriptsNeedingLoad = 0;
    {
      // Add new scripts
      const injectedScriptID = "injectedScript";

      let scriptsLoaded = 0;
      const handleScriptLoad = () => {
        scriptsLoaded++;
        if (scriptsLoaded === scriptsNeedingLoad) {
          updateEditorViewFrameScriptsLoaded();
        }
      }

      for (const script of scripts) {
        const scriptElement = editorViewFrameRef.current.contentWindow.document.createElement("script");
        if (script.module) {
          scriptElement.type = "module";
        } else {
          scriptElement.type = "text/javascript";
        }
        scriptElement.id = script.id;
        scriptElement.className = injectedScriptID;
        if (script.url) {
          scriptElement.src = script.url;

          if (script.execution === "async") {
            scriptElement.setAttribute("async", true);
          } else if (script.execution === "defer") {
            scriptElement.setAttribute("defer", true);
          }

          scriptsNeedingLoad++;
          scriptElement.onload = handleScriptLoad;
          scriptElement.onerror = handleScriptLoad;

        } else if (script.script) {
          scriptElement.innerHTML = script.script;
        }

        editorViewFrameRef.current.contentWindow.document.head.appendChild(scriptElement);
      }

      if (scriptsNeedingLoad === 0) {
        updateEditorViewFrameScriptsLoaded();
      }
    }
  }, [editorViewFrameRef, currentSite, scenePropertyMap, entityPropertyMap, overlayElements, cssProps, scripts, apps]);

  const updateEditorViewFrameSelection = useCallback(() => {
    const message = {
      type: "select-object",
      selectedObject: selectedObject
    };
    editorViewFrameRef.current.contentWindow.postMessage(message);
  }, [selectedObject, editorViewFrameRef]);

  const updateEditorViewFrameEnvironment = useCallback(() => {
    const message = {
      type: "environment-update",
      environment: environmentEntities
    };
    editorViewFrameRef.current.contentWindow.postMessage(message);
  }, [environmentEntities, editorViewFrameRef]);

  const messageHandler = useCallback((event) => {
    if (!event.data.type) {
      return;
    }

    switch (event.data.type) {
      case "select-object":
        setSelectedObjectHandler(event.data.selectedObject);
        break;
      case "entity-edit":
        updatePropHandler(event.data.entity, event.data.prop, event.data.value, true, false);
        break;
      default:
        break;
    }
  }, [setSelectedObjectHandler, updatePropHandler]);

  const handleKeyPress = useCallback((e) => {
    if (document.activeElement.nodeName === "INPUT" ||
        document.activeElement.nodeName === "SELECT" ||
        document.activeElement.nodeName === "TEXTAREA" ||
        document.activeElement.className === "cm-content") {
      return;
    }

    switch (convertKeyName(e.key)) {
      case "C":
        if (e.ctrlKey) {
          copyPaste(true, e.shiftKey);
        }
        break;
      case "V":
        if (e.ctrlKey) {
          copyPaste(false, e.shiftKey);
        }
        break;
      case "Z":
        if (e.ctrlKey) {
          if (e.shiftKey) {
            undoRedo(false);
          } else {
            undoRedo(true);
          }
        }
        break;
      case "Delete":
        deleteObject();
        break;
      default:
        break;
    }
  }, [undoRedo, copyPaste, deleteObject]);

  useEffect(() => {
    window.addEventListener("message", messageHandler);
    document.addEventListener("keydown", handleKeyPress);
    if (editorViewFrameRef.current) {
      editorViewFrameRef.current.contentWindow.document.onkeydown = handleKeyPress;
    }

    return () => {
      window.removeEventListener("message", messageHandler);
      document.removeEventListener("keydown", handleKeyPress);
    }
  }, [editorViewFrameRef, messageHandler, handleKeyPress]);

  const handleIFrameLoad = useCallback(() => {
    // TODO: is there a better way to do this?  bad to depend on
    // all the different types + codemirror
    if (document.activeElement.nodeName !== "INPUT" &&
        document.activeElement.nodeName !== "SELECT" &&
        document.activeElement.nodeName !== "TEXTAREA" &&
        document.activeElement.className !== "cm-content") {
      editorViewFrameRef.current.contentWindow.focus();
    }

    // When we drag over the iframe, we need to disable pointerEvents to allow
    // our main div to receive the drop.  This is reset on dragleave/drop on the main
    // div.  ondragenter doesn't work here for some reason; it works the first time
    // but then misses every other enter event
    editorViewFrameRef.current.contentWindow.document.ondragover = () => {
      editorViewFrameRef.current.style.pointerEvents = "none";
    };

    editorViewFrameRef.current.contentWindow.document.onkeydown = handleKeyPress;

    updateEditorViewFrame(entityPropertyMap);
    updateEditorViewFrameSelection();
    updateEditorViewFrameEnvironment();
    updateEditorViewFrameScripts();
  }, [editorViewFrameRef, entityPropertyMap, messageHandler, handleKeyPress, updateEditorViewFrameScripts,
    updateEditorViewFrame, updateEditorViewFrameSelection, updateEditorViewFrameEnvironment]);

  useEffect(() => {
    // Note: when entityPropertyMap changes, we do this manually if need be,
    // so entityPropertyMap is NOT a dependency
    updateEditorViewFrame(entityPropertyMap);
  }, [scenePropertyMap, overlayElements, cssProps]);

  useEffect(() => {
    updateEditorViewFrameSelection();
  }, [selectedObject]);

  useEffect(() => {
    updateEditorViewFrameEnvironment();
  }, [environmentEntities]);

  // Mode state
  const [editorMode, setEditorMode] = useState("Entities");

  useEffect(() => {
    // TODO: can get rid of this if we can respond to asset changes
    if (editorMode === "Assets") {
      getAssets(currentSite);
    }
  }, [editorMode]);

  // Realtime data
  const [realtimeChannel, setRealtimeChannel] = useState(undefined);
  const [onlineUsers, setOnlineUsers] = useState([]);

  // The postgres_changes handler is a closure, which means the scene/entity maps
  // would be stale by the time it is called.  As a workaround, we have a separate
  // state variable for the incoming data change, which is immediately cleared.
  const [forceAcceptSiteData, setForceAcceptSiteData] = useState(true);
  const [newSiteData, setNewSiteData] = useState(undefined);

  useEffect(() => {
    if (newSiteData) {
      if (forceAcceptSiteData || newSiteData?.last_updated_by !== user?.user?.email) {
        setSiteName(newSiteData.name);
        setSiteCreator(newSiteData.creator);
        setSiteEditors(newSiteData.editors);

        const [scenePropertyMapData, environmentData, entityPropertyMapData,
               entityParentMapData, overlayElementsData, cssPropsData, scriptsData, appsData] =
          parseSiteProperties(newSiteData.data);

        setScenePropertyMap(scenePropertyMapData);
        setEnvironment(environmentData);
        setEntityPropertyMap(entityPropertyMapData);
        setEntityParentMap(entityParentMapData);

        setOverlayElements(overlayElementsData);
        setCSSProps(cssPropsData);

        // === will always return false here because they are different objects.
        // We need to check deep equality to avoid reloading the iframe everytime
        // another user makes any edit.
        if (!deepEquals(scripts, scriptsData)) {
          setScripts(scriptsData);
        }

        if (!deepEquals(apps, appsData)) {
          setApps(appsData);
        }
      }
      setNewSiteData(undefined);
      setForceAcceptSiteData(false);
    }
  }, [newSiteData]);

  const onCurrentSiteChange = useCallback(async (newSite, currentEmail, siteData, siteIndex) => {
    if (realtimeChannel) {
      realtimeChannel.unsubscribe();
    }

    let email = currentEmail ?? user?.user?.email;
    if (email) {
      const channel = supabase.channel(newSite, {
        config: {
          presence: {
            key: email,
          },
        },
      });

      channel.on("presence", { event: "sync" }, () => {
        let presenceState = {...channel.presenceState()};
        console.log("Online users: ", presenceState);
        // Ignore our own email
        delete presenceState[email];
        let onlineUsersData = Object.keys(presenceState).sort((left, right) => {
          return (presenceState[left][0].online_at < presenceState[right][0].online_at) ? -1 : 1;
        });
        setOnlineUsers(onlineUsersData);
      });

      channel.on("postgres_changes", {
          event: "UPDATE",
          schema: "public",
          table: "sites",
          filter: `id=eq.${newSite}`,
        }, (payload) => { setNewSiteData(payload?.new); }
      );

      // TODO: figure out if it's possible to enable realtime for this
      // TODO: figure out the right filter here to listen to storage changes
      // and refresh assets
      // channel.on("postgres_changes", {
      //     event: "UPDATE",
      //     schema: "storage",
      //     table: "objects",
      // multiple filters aren't possible: https://github.com/supabase/supabase/issues/11190
      //     filter: `pathtokens[1]=eq.${newSite} && pathtokens[2]=eq.private`,
      //   }, (payload) => { console.log(payload); }
      // );

      channel.subscribe(async (status) => {
        if (status === "SUBSCRIBED") {
          channel.track({
            online_at: new Date().toISOString()
          });
        } else {
          console.log("Channel status: " + status);
        }
      });

      setRealtimeChannel(channel);
    }

    setSelectedObject(undefined);
    setCurrentSite(newSite);

    // TODO: refresh when other editor uploads
    getAssets(newSite);

    supabase.rpc("update_last_visited_site", {
      site: newSite
    }).then((result) => {
      if (result.error) {
        console.log(result);
      }
    });

    if (!siteData) {
      const { data } = await supabase.from("sites").select("*").eq("id", newSite).limit(1);
      siteData = data;
      siteIndex = 0;
    }
    if (siteData && siteData.length > siteIndex) {
      setForceAcceptSiteData(true);
      setNewSiteData(siteData[siteIndex]);
    }
  }, [realtimeChannel, user, getAssets]);

  useEffect(() => {
    if (!setup) {
      const fetchData = async () => {
        let currentEmail = undefined;
        {
          const { data } = await supabase.auth.getUser();
          setUser(data);
          currentEmail = data?.user?.email;
        }

        let lastVisitedSite = undefined;
        {
          let userData = await getUserDataAndRefreshOwnedItems();
          lastVisitedSite = userData?.last_visited_site;
        }

        {
          let siteData = await refreshSites();
          if (siteData) {
            if (!currentSite) {
              let found = false;
              let siteIndex = 0;
              if (lastVisitedSite) {
                for (let site of siteData) {
                  if (site.id === lastVisitedSite) {
                    found = true;
                    break;
                  }
                  siteIndex++;
                }
              }

              if (found) {
                onCurrentSiteChange(lastVisitedSite, currentEmail, siteData, siteIndex);
              } else if (siteData.length > 0) {
                onCurrentSiteChange(siteData[0].id, currentEmail, siteData, 0);
              }
            }
          }
        }
      };
      fetchData();

      setSetup(true);
      replaceHTMLIncludes();
      makeElementsDraggable();
    }
  }, []);

  return (
    <>
      <div
        onDrop={(e) => {
          dropAssetHandler(e, addAssetAndEntityHandler);
          // Allow the frame to receive pointerEvents again
          editorViewFrameRef.current.style.removeProperty("pointer-events");
        }}
        onDragLeave={(e) => {
          // Allow the frame to receive pointerEvents again
          editorViewFrameRef.current.style.removeProperty("pointer-events");
        }}
        onDragEnter={(e) => e.preventDefault()}
        onDragOver={(e) => e.preventDefault()}
        style={{
          width: "100vw",
          height: "100vh"
        }}
      >
        <iframe
          id="editorView"
          key={scriptUpdateCounter}
          className={styles.unselectable}
          title="Gydence 3D Editor View"
          src={window.location.origin + "/editorView" + window.location.search}
          allow="accelerometer; ambient-light-sensor; camera; encrypted-media; fullscreen; geolocation; gyroscope; hid; microphone; midi; payment; usb; vr; xr-spatial-tracking"
          onLoad={handleIFrameLoad}
          ref={editorViewFrameRef}
          style={{
            position: "absolute",
            width: "100vw",
            height: "100vh",
            zIndex: 0
          }}
        />

        <div
          id="mainPanel"
          className={["draggable", styles.topLeft, styles.block, styles.ui].join(" ")}
          style={{
            zIndex: 2,
            maxHeight: "95%",
            overflow: "auto"
          }}
        >
          {currentSite ?
            <>
              <TextField
                name="siteSelection"
                id="siteSelection"
                label="Current Project"
                value={currentSite}
                onChange={ (e) => { onCurrentSiteChange(e.target.value) } }
                size="small"
                select
              >
                {sites.map((site) => (
                  <MenuItem key={site.id} value={site.id}>{site.name}</MenuItem>
                ))}
              </TextField>
              <IconButton
                onClick={() => setEditorMode("Edit Project")}
              >
                <Settings />
              </IconButton>
              <IconButton
                onClick={() => setEditorMode("New Project")}
              >
                <Add />
              </IconButton>
            </>
            : <></>
          }
          <div style={{ paddingTop: "5px" }}>
            <span
              className={styles.unselectable}
              style={{
                padding: "2px 10px",
                backgroundColor: ("Scene" === selectedObject) ? "#dddddd" : undefined,
                borderRadius: ("Scene" === selectedObject) ? "10px" : undefined,
                cursor: "pointer",
              }}
              onClick={() => setSelectedObjectHandler((selectedObject === "Scene") ? undefined : "Scene")}
            >
              Scene
            </span>
            <span
              className={styles.unselectable}
              style={{
                padding: "2px 10px",
                backgroundColor: ("2D UI" === selectedObject) ? "#dddddd" : undefined,
                borderRadius: ("2D UI" === selectedObject) ? "10px" : undefined,
                cursor: "pointer",
              }}
              onClick={() => setSelectedObjectHandler((selectedObject === "2D UI") ? undefined : "2D UI")}
            >
              2D UI
            </span>
          </div>
          <EntityList
            entity={undefined}
            entityPropertyMap={entityPropertyMap}
            entityParentMap={entityParentMap}
            selectedEntity={selectedObject}
            selectHandler={setSelectedObjectHandler}
            deleteEntityHandler={deleteEntityHandler}
          />
          {unknownParentChildren.length > 0 ?
            <>
              <p>Unknown Parent:</p>
              {unknownParentChildren.map((entity) => {
                return <EntityList
                  key={entity}
                  entity={entity}
                  entityPropertyMap={entityPropertyMap}
                  entityParentMap={entityParentMap}
                  selectedEntity={selectedObject}
                  selectHandler={setSelectedObjectHandler}
                  deleteEntityHandler={deleteEntityHandler}
                />
              })}
            </>
            : <></>
          }
          {cameraChildren.length > 0 ?
            <>
              <p>Camera:</p>
              {cameraChildren.map((entity) => {
                return <EntityList
                  key={entity}
                  entity={entity}
                  entityPropertyMap={entityPropertyMap}
                  entityParentMap={entityParentMap}
                  selectedEntity={selectedObject}
                  selectHandler={setSelectedObjectHandler}
                  deleteEntityHandler={deleteEntityHandler}
                />
              })}
            </>
            : <></>
          }
        </div>

        <div
          id="menuPanel"
          style={{
            width: "100vw",
            zIndex: 1,
            pointerEvents: "none"
          }}
          className={[styles.topMiddle, styles.block].join(" ")}
        >
          <div
            className={[styles.block, styles.ui].join(" ")}
            style={{
              width: "fit-content",
              margin: "0 auto",
              pointerEvents: "auto",
            }}
          >
            <EntityMenu
              editorMode={editorMode}
              setEditorModeHandler={setEditorMode}
              entityPropertyMap={entityPropertyMap}
              addEntityHandler={addEntityHandler}
            />
          </div>
        </div>

        <div
          id="subPanel"
          className={[styles.topRight, styles.block].join(" ")}
          style={{ zIndex: 1 }}
        >
          {onlineUsers?.length > 0 ?
            <p
              className={[styles.block, styles.unselectable, styles.ui].join(" ")}
              style={{ float: "left" }}
              title={onlineUsers.join("\n")}
            >
              {onlineUsers.length} other user{onlineUsers.length > 1 ? "s" : ""} editing.
            </p>
            : <></>
          }
          {(user?.user?.email) ?
            <button
              className={[styles.block, styles.unselectable, styles.ui].join(" ")}
              onClick={() => { supabase.auth.signOut(); }}
              style={{ cursor: "pointer" }}
            >
              {user.user.email} / Log Out</button>
            : <></>
          }
          {(entityPropertyMap?.[selectedObject] || selectedObject === "Scene") ?
            <div className={[styles.block, styles.ui].join(" ")} style={{ maxHeight: "90vh", overflow: "auto" }}>
              <EntityPropertyList
                key={selectedObject}
                props={selectedObject === "Scene" ? scenePropertyMap : entityPropertyMap[selectedObject]}
                updatePropHandler={selectedObject === "Scene" ? updateScenePropHandler : (prop, value) => { updatePropHandler(selectedObject, prop, value); }}
                removePropHandler={selectedObject === "Scene" ? removeScenePropHandler : (prop) => { removePropHandler(selectedObject, prop); }}
                isScene={selectedObject === "Scene"}
                editorViewFrame={editorViewFrameRef.current}
              />
            </div>
            : (selectedObject === "2D UI") ?
              <div className={[styles.block, styles.ui].join(" ")} style={{ maxHeight: "90vh", overflow: "auto" }}>
                <UIEditor
                  overlayElements={overlayElements}
                  cssProps={cssProps}
                  updateOverlayElementHandler={updateOverlayElementHandler}
                  updateCSSHandler={updateCSSHandler}
                  refreshScriptsHandler={() => { setScriptUpdateCounter(scriptUpdateCounter + 1); }}
                  reloadOn2DChange={reloadOn2DChange}
                  setReloadOn2DChangeHandler={setReloadOn2DChange}
                />
              </div>
            : (assets.find((asset) => asset.id === selectedObject)) ?
              <div className={[styles.block, styles.ui].join(" ")} style={{ maxHeight: "90vh", overflow: "auto" }}>
                <AssetPropEditor
                  key={selectedObject}
                  props={assets.find((asset) => asset.id === selectedObject)}
                  updateAssetNameHandler={updateAssetNameHandler}
                  addEntityFromAssetHandler={addEntityFromAssetHandler}
                  getPublicURL={getPublicURL}
                />
              </div>
            : (ownedAssets.find((asset) => asset.id === selectedObject)) ?
              <div className={[styles.block, styles.ui].join(" ")} style={{ maxHeight: "90vh", overflow: "auto" }}>
                <AssetPropEditor
                  key={selectedObject}
                  props={ownedAssets.find((asset) => asset.id === selectedObject)}
                  updateAssetNameHandler={updateAssetNameHandler}
                  addEntityFromAssetHandler={addEntityFromAssetHandler}
                  getPublicURL={getPublicURL}
                  privateURL={true}
                  goToListingHandler={(id) => {
                    setEditorMode("Marketplace");
                    setTargetListing(id);
                  }}
                />
              </div>
            : scripts.find((script) => script.id === selectedObject) ?
              <div className={[styles.block, styles.ui].join(" ")} style={{ maxHeight: "90vh", overflow: "auto" }}>
                <ScriptPropEditor
                  key={selectedObject}
                  props={scripts.find((script) => script.id === selectedObject)}
                  updatePropHandler={(prop, value) => { updateScriptPropHandler(selectedObject, prop, value); }}
                  refreshScriptsHandler={() => { setScriptUpdateCounter(scriptUpdateCounter + 1); }}
                  reloadOnScriptChange={reloadOnScriptChange}
                  setReloadOnScriptChangeHandler={setReloadOnScriptChange}
                />
              </div>
            : ownedScripts.find((script) => script.id === selectedObject) ?
              <div className={[styles.block, styles.ui].join(" ")} style={{ maxHeight: "90vh", overflow: "auto" }}>
                <OwnedScriptViewer
                  key={selectedObject}
                  props={ownedScripts.find((script) => script.id === selectedObject)}
                  addScriptHandler={addScriptHandler}
                  goToListingHandler={(id) => {
                    setEditorMode("Marketplace");
                    setTargetListing(id);
                  }}
                />
              </div>
              : apps.find((app) => app.id === selectedObject) ?
              <div className={[styles.block, styles.ui].join(" ")} style={{ maxHeight: "90vh", overflow: "auto" }}>
                <AppPropEditor
                  key={selectedObject}
                  props={apps.find((app) => app.id === selectedObject)}
                  updatePropHandler={(prop, value) => { updateAppPropHandler(selectedObject, prop, value); }}
                  updateFrameAPI={updateFrameAPI}
                  currentSite={currentSite}
                  scenePropertyMap={scenePropertyMap}
                  entityPropertyMap={entityPropertyMap}
                  overlayElements={overlayElements}
                  cssProps={cssProps}
                  scripts={scripts}
                  apps={apps}
                />
              </div>
              : ownedApps.find((app) => app.id === selectedObject) ?
              <div className={[styles.block, styles.ui].join(" ")} style={{ maxHeight: "90vh", overflow: "auto" }}>
                <AppPropEditor
                  key={selectedObject}
                  props={ownedApps.find((app) => app.id === selectedObject)}
                  addAppHandler={addAppHandler}
                  goToListingHandler={(id) => {
                    setEditorMode("Marketplace");
                    setTargetListing(id);
                  }}
                  updateFrameAPI={updateFrameAPI}
                  currentSite={currentSite}
                  scenePropertyMap={scenePropertyMap}
                  entityPropertyMap={entityPropertyMap}
                  overlayElements={overlayElements}
                  cssProps={cssProps}
                  scripts={scripts}
                  apps={apps}
                />
              </div>
              : <></>
          }
        </div>

        <div
          id="publishPanel"
          className={[styles.bottomRight, styles.block].join(" ")}
          style={{ zIndex: 1 }}
        >
          <button
              className={[styles.block, styles.unselectable, styles.ui].join(" ")}
              style={{ float: "left", cursor: "pointer" }}
              onClick={() => { setEditorMode("Preview"); }}
            >
              Preview
            </button>
          <button
              className={[styles.block, styles.unselectable, styles.ui].join(" ")}
              onClick={() => { setEditorMode("Publish"); }}
              style={{ cursor: "pointer" }}
            >
              Publish
            </button>
        </div>

        {itemListModes.indexOf(editorMode) !== -1 ?
          <div
            id="itemListPanel"
            className={[styles.bottomLeft, styles.block, styles.unselectable, styles.ui].join(" ")}
            style={{ zIndex: 2 }}
          >
            <ItemListPanel
              key={editorMode + "ItemListPanel"}
              currentSite={currentSite}
              editorMode={editorMode}
              selectedObject={selectedObject}
              setSelectedObject={setSelectedObjectHandler}
              items={editorMode === "Scripts" ? scripts : editorMode === "Assets" ? assets : apps}
              ownedItems={editorMode === "Scripts" ? ownedScripts : editorMode === "Assets" ? ownedAssets : ownedApps}
              addHandler={editorMode === "Scripts" ? addScriptHandler : editorMode === "Assets" ? addAssetHandler : addAppHandler}
              deleteItemHandler={editorMode === "Scripts" ? deleteScriptHandler : editorMode === "Assets" ? deleteAssetHandler : deleteAppHandler}
              dropHandler={editorMode === "Assets" ? dropAssetHandler : undefined}
              setOffsetHandler={editorMode === "Assets" ? setAssetsOffset : undefined}
            />
          </div>
          : <></>
        }

        {siteModes.indexOf(editorMode) !== -1 ?
          <div
            className={styles.centered}
            style={{
              zIndex: 3
            }}
            onClick={() => setEditorMode("Entities")}
          >
            <EditSiteUI
              newSite={editorMode === "New Project"}
              user={user}
              sites={sites}
              currentSite={currentSite}
              siteName={siteName}
              isCreator={user?.user?.email === siteCreator}
              siteEditors={siteEditors}
              templates={ownedTemplates}
              environment={environment}
              environments={ownedEnvironments}
              getSitesHandler={refreshSites}
              setCurrentSiteHandler={onCurrentSiteChange}
              updateSiteNameHandler={updateSiteNameHandler}
              updateSiteEditorsHandler={updateSiteEditorsHandler}
              setEditorModeHandler={setEditorMode}
              setSelectedObjectHandler={setSelectedObject}
              addEntityHandler={addEntityHandler}
              updateScenePropHandler={updateScenePropHandler}
              setEnvironmentHandler={setEnvironmentHandler}
              updateOverlayElementHandler={updateOverlayElementHandler}
              updateCSSHandler={updateCSSHandler}
              addScriptHandler={addScriptHandler}
              addAppHandler={addAppHandler}
              setTargetListing={setTargetListing}
            />
          </div>
          : <></>
        }

        {marketplaceModes.indexOf(editorMode) !== -1 ?
          <div
            className={styles.centered}
            style={{
              zIndex: 3
            }}
            onClick={() => {
              setEditorMode("Entities");
              setTargetListing(undefined);
            }}
          >
            <Marketplace
              mode={editorMode}
              startingSortMode={editorMode === "Inventory" ? "owned" :
                                editorMode === "Listings" ? "mine" : undefined}
              ownedItems={ownedItems}
              ownedListings={ownedListings}
              user={user}
              setEditorModeHandler={setEditorMode}
              targetListing={targetListing}
              setTargetListingHandler={setTargetListing}
              refreshOwnedItemsHandler={getUserDataAndRefreshOwnedItems}
            />
          </div>
          : <></>
        }

        {editorMode === "Upload" ?
          <div
            className={styles.centered}
            style={{
              zIndex: 3
            }}
            onClick={() => {
              setEditorMode("Entities");
              setTargetListing(undefined);
            }}
          >
            <UploadManager
              user={user}
              currentSite={currentSite}
              setEditorModeHandler={setEditorMode}
              targetListing={targetListing}
              setTargetListingHandler={setTargetListing}
              refreshOwnedItemsHandler={getUserDataAndRefreshOwnedItems}
              scenePropertyMap={scenePropertyMap}
              entityPropertyMap={entityPropertyMap}
              entityParentMap={entityParentMap}
              overlayElements={overlayElements}
              cssProps={cssProps}
              scripts={scripts}
              apps={apps}
              getPublicURL={getPublicURL}
            />
          </div>
          : <></>
        }

        {publishModes.indexOf(editorMode) !== -1 ?
          <div
            className={styles.centered}
            style={{
              zIndex: 3
            }}
            onClick={() => { setEditorMode("Entities"); }}
          >
            <PublishSite
              preview={editorMode === "Preview"}
              currentSite={currentSite}
              editorViewFrame={editorViewFrameRef.current}
              scenePropertyMap={scenePropertyMap}
              entityPropertyMap={entityPropertyMap}
              overlayElements={overlayElements}
              cssProps={cssProps}
              scripts={scripts}
              setEditorModeHandler={setEditorMode}
            />
          </div>
          : <></>
        }
      </div>
    </>
  )
}

export default function Editor() {
  return (
    <EnforceSignedIn>
      <SEO
        title="Gydence Editor"
        description="Gydence 3D web editor."
      />
      <EnforceOnboarded>
        <EditorMain />
      </EnforceOnboarded>
    </EnforceSignedIn>
  )
}