import "aframe";

import { rematerial } from "./utils";

let globalOverlayRenderOrder = 50;

AFRAME.registerComponent("rematerial", {
  schema: {
    material: { type: "string", default: "#FFFFFF" },
  },

  init: function () {
    const self = this;
    this.el.addEventListener("model-loaded", function () { self.rematerial(self); });
    this.el.addEventListener("gltf-loaded", function () { self.rematerial(self); });
    this.el.addEventListener("object3dset", function () { self.rematerial(self); });
  },

  rematerial: function (self) {
    rematerial(self, (node) => { return node.material; },
      (node, self) => {
        if (self.color) {
          node.material.color = self.color;
        }
        if (self.invisible) {
          node.material.visible = false;
        }
        if (self.occlude) {
          node.material.colorWrite = false;
        }
        if (self.overlay) {
          node.material.depthTest = false;
          node.renderOrder = globalOverlayRenderOrder++;
        }
        if (self.renderOrder) {
          node.renderOrder = self.renderOrder;
        }
        if (self.emissive) {
          node.material.emissive = self.emissive;
        }
      }, self
    );
  },

  update: function () {
    this.color = null;
    this.emissive = null;
    this.invisible = null;
    this.occlude = null;
    this.overlay = null;
    this.renderOrder = null;

    const options = this.data.material.split(",");
    for (let i = 0; i < options.length; i++) {
      const settings = options[i].split(" ");
      const filteredSettings = settings.filter(s => s !== "");
      if (filteredSettings[0].startsWith("#")) {
        this.color = new THREE.Color(filteredSettings[0]);
      } else if (filteredSettings[0] === "invisible") {
        this.invisible = true;
      } else if (filteredSettings[0] === "occlude") {
        this.occlude = true;
      } else if (filteredSettings[0] === "overlay") {
        this.overlay = true;
      } else if (filteredSettings[0] === "renderOrder" && filteredSettings.length > 1) {
        this.renderOrder = parseInt(filteredSettings[1]);
      } else if (filteredSettings[0] === "emissive" && filteredSettings.length > 1) {
        this.emissive = new THREE.Color(filteredSettings[1]);
      }
    }

    this.rematerial(this);
  },
});