import { useState, useCallback } from "react";

import { Collapse, IconButton, List, ListItemButton, ListItemText } from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

import EntityError from "./entityError";

export function EntityHierarchyErrorRender({ entity, entityPropertyMap, entityParentMap, entityErrorMap, setEntityErrorHandler }) {
  return (
    <>
      {entityParentMap?.[entity]?.map((child) => {
        let props = {...entityPropertyMap[child]};
        props.id = "entity_" + props.id;
        delete props.parent;
        return (
          <EntityError
            key={["error_", child].join("")}
            entityProps={entityPropertyMap[child]}
            entityErrors={entityErrorMap[child]}
            setEntityError={setEntityErrorHandler}
          >
            <a-entity key={child} {...props}>
              <EntityHierarchyErrorRender
                entity={child}
                entityPropertyMap={entityPropertyMap}
                entityParentMap={entityParentMap}
                entityErrorMap={entityErrorMap}
                setEntityErrorHandler={setEntityErrorHandler}
              />
            </a-entity>
          </EntityError>
        )
      })}
    </>
  )
}

export function EntityHierarchyRender({ entity, entityPropertyMap, entityParentMap }) {
  return (
    <>
      {entityParentMap?.[entity]?.map((child) => {
        let props = {...entityPropertyMap[child]};
        props.id = "entity_" + props.id;
        delete props.parent;
        return (
          <a-entity key={child} {...props}>
            <EntityHierarchyRender
              entity={child}
              entityPropertyMap={entityPropertyMap}
              entityParentMap={entityParentMap}
            />
          </a-entity>
        )
      })}
    </>
  )
}

function EntityListHelper({ entity, childLevel, entityPropertyMap, entityParentMap,
  selectedEntity, selectHandler, deleteEntityHandler }) {
  const [isExpandedMap, setIsExpandedMap] = useState({});
  const expand = useCallback((id) => {
    let currIsExpandedMap = {...isExpandedMap};
    if (!(id in currIsExpandedMap)) {
      currIsExpandedMap[id] = true;
    } else {
      currIsExpandedMap[id] = !currIsExpandedMap[id];
    }
    setIsExpandedMap(currIsExpandedMap);
  }, [isExpandedMap]);

  return (
    <>
      {entityParentMap?.[entity]?.map((child) => {
        const hasChildren = entityParentMap?.[child]?.length > 0;
        return (
          <div
            key={["div_", child].join("")}
          >
            <ListItemButton
              key={["item_", child].join("")}
              onClick={() => selectHandler((selectedEntity === child) ? undefined : child)}
              selected={selectedEntity === child}
              sx={{ pl: 4 * childLevel }}
            >
              <ListItemText
                key={["text_", child].join("")}
                primary={entityPropertyMap?.[child]?.name ?? child}
              />
              {hasChildren ?
                <IconButton
                  key={["expand_", child].join("")}
                  onClick={hasChildren ?
                    (e) => {
                      e.stopPropagation();
                      expand(child);
                    } : undefined}
                >
                  {isExpandedMap[child] ? <ExpandLess /> : <ExpandMore />}
                </IconButton>
                : <></>
              }
            </ListItemButton>
            {hasChildren ?
              <Collapse
                key={["collapse_", child].join("")}
                in={isExpandedMap[child]}
                timeout="auto"
              >
                <EntityList
                  key={child}
                  entity={child}
                  childLevel={childLevel + 1}
                  entityPropertyMap={entityPropertyMap}
                  entityParentMap={entityParentMap}
                  selectedEntity={selectedEntity}
                  selectHandler={selectHandler}
                  deleteEntityHandler={deleteEntityHandler}
                />
              </Collapse>
              : <></>
            }
          </div>
        )
      })}
    </>
  );
}

export default function EntityList({ entity, childLevel, ...rest }) {
  return (
    <List
      key={["list_", entity].join("")}
      sx={{ minWidth: 200 }}
    >
      <EntityListHelper
        key={["helper_", entity].join("")}
        entity={entity}
        childLevel={childLevel ?? 0}
        {...rest}
      />
    </List>
  )
}