import "aframe";

let htmlIndex = 0;

// Adaptation of https://github.com/pmndrs/drei/blob/master/src/web/Html.tsx

const v1 = new THREE.Vector3();
const v2 = new THREE.Vector3();
const v3 = new THREE.Vector3();

// FIXME: should check if any part of plane is in front of camera, not just object position
function isObjectBehindCamera(el, camera) {
  const objectPos = v1.setFromMatrixPosition(el.matrixWorld);
  const cameraPos = v2.setFromMatrixPosition(camera.matrixWorld);
  const deltaCamObj = objectPos.sub(cameraPos);
  const camDir = camera.getWorldDirection(v3);
  return deltaCamObj.angleTo(camDir) > 0.5 * Math.PI;
}

function objectScale(el, camera) {
  if (camera instanceof THREE.OrthographicCamera) {
    return camera.zoom;
  } else if (camera instanceof THREE.PerspectiveCamera) {
    const objectPos = v1.setFromMatrixPosition(el.matrixWorld);
    const cameraPos = v2.setFromMatrixPosition(camera.matrixWorld);
    const vFOV = (camera.fov * Math.PI) / 180;
    const dist = objectPos.distanceTo(cameraPos);
    const scaleFOV = 2 * Math.tan(vFOV / 2) * dist;
    return 1 / scaleFOV;
  } else {
    return 1;
  }
}

function objectZIndex(el, camera, zIndexRange) {
  const objectPos = v1.setFromMatrixPosition(el.matrixWorld);
  const cameraPos = v2.setFromMatrixPosition(camera.matrixWorld);
  const dist = objectPos.distanceTo(cameraPos);
  const A = (zIndexRange[1] - zIndexRange[0]) / (camera.far - camera.near);
  const B = zIndexRange[1] - A * camera.far;
  return Math.round(A * dist + B);
}

const epsilon = (value) => (Math.abs(value) < 1e-10 ? 0 : value);

function getCSSMatrix(matrix, multipliers, prepend = '') {
  let matrix3d = 'matrix3d(';
  for (let i = 0; i !== 16; i++) {
    matrix3d += epsilon(multipliers[i] * matrix.elements[i]) + (i !== 15 ? ',' : ')');
  }
  return prepend + matrix3d;
}

const getCameraCSSMatrix = ((multipliers) => {
  return (matrix) => getCSSMatrix(matrix, multipliers);
})([1, -1, 1, 1, 1, -1, 1, 1, 1, -1, 1, 1, 1, -1, 1, 1])

const getObjectCSSMatrix = ((scaleMultipliers) => {
  return (matrix, factor) => getCSSMatrix(matrix, scaleMultipliers(factor), 'translate(-50%,-50%)');
})((f) => [1 / f, 1 / f, 1 / f, 1, -1 / f, -1 / f, -1 / f, -1, 1 / f, 1 / f, 1 / f, 1, 1, 1, 1, 1])

AFRAME.registerComponent("html3d", {
  schema: {
    src: {
      type: "string",
      default: ""
    },
    srcdoc: {
      type: "string",
      default: ""
    },
    center: {
      type: "boolean",
      default: true
    },
    fullscreen: {
      type: "boolean",
      default: false
    },
    pointsPerMeter: {
      type: "number",
      default: 40
    },
    transform: {
      type: "boolean",
      default: true
    },
    zIndexRange: {
      type: "array",
      default: [16777271, 0]
    }
  },

  init: function () {
    this.htmlIndex = htmlIndex++;

    this.mesh = new THREE.Mesh();
    this.mesh.geometry = new THREE.PlaneGeometry(1, 1);
    this.el.setObject3D("mesh", this.mesh);

    this.ref = document.createElement("div");
    this.ref.id = "html" + this.htmlIndex;
    {
      this.transformInnerRef = document.createElement("div");
      this.transformInnerRef.id = "transformInner" + this.htmlIndex;
      this.transformInnerRef.style.position = "absolute";
      this.transformInnerRef.style.pointerEvents = "auto";
    }
    {
      this.transformOuterRef = document.createElement("div");
      this.transformOuterRef.id = "transformOuter" + this.htmlIndex;
    }
    {
      this.htmlCameraRef = document.createElement("div");
      this.htmlCameraRef.id = "htmlCamera" + this.htmlIndex;
    }

    this.transformInnerRef.appendChild(this.ref);
    this.transformOuterRef.appendChild(this.transformInnerRef);
    this.htmlCameraRef.appendChild(this.transformOuterRef);
    document.querySelector("#overlayDOM").appendChild(this.htmlCameraRef);

    const self = this;
    window.addEventListener("resize", () => {
      self.updateCameraCSS(self);
      self.updateTransformOuterCSS(self);
    });

    this.visible = true;
  },

  calculatePosition : (el, camera) => {
    const objectPos = v1.setFromMatrixPosition(el.matrixWorld);
    objectPos.project(camera);
    const widthHalf = window.innerWidth / 2;
    const heightHalf = window.innerHeight / 2;
    return [objectPos.x * widthHalf + widthHalf, -(objectPos.y * heightHalf) + heightHalf];
  },

  updateCameraCSS: (self) => {
    if (self.data.transform) {
      self.htmlCameraRef.style.cssText = "position:absolute;top:0px;left:0px;pointer-events:none;overflow:hidden;";
    } else {
      const vec = self.calculatePosition(self.el.object3D, self.el.sceneEl.camera);
      self.htmlCameraRef.style.cssText = `position:absolute;top:0px;left:0px;transform:translate3d(${vec[0]}px,${vec[1]}px,0);transform-origin:0 0;`;
    }
  },

  updateTransformOuterCSS: (self) => {
    if (self.data.transform) {
      self.transformOuterRef.style.cssText = `position:absolute;top:0px;left:0px;width:${window.innerWidth}px;height:${window.innerHeight}px;transform-style:preserve-3d;pointer-events:none`;
    } else {
       let cssText = "position:absolute;";
       cssText += "transform:" + self.data.center ? "translate3d(-50%,-50%,0)" : "none" + ";";
       if (self.data.fullscreen) {
        cssText += `top:${-window.innerHeight / 2}px;left:${-window.innerWidth / 2}px;width:${window.innerWidth}px;height:${window.innerHeight}px;`;
       }
       self.transformOuterRef.style.cssText = cssText;
    }
  },

  update: function (oldData) {
    if (this.data.src) {
      if (this.data.src !== oldData.src) {
        this.ref.innerHTML = "<iframe src=\"" + this.data.src + "\" />";
        this.prevMode = "src";
      }
    } else if (this.data.srcdoc) {
      if (this.prevMode === "src" || this.data.srcdoc !== oldData.srcdoc) {
        this.ref.innerHTML = this.data.srcdoc;
        this.prevMode = "srcdoc";
      }
    }

    const self = this;
    const transformChanged = this.data.transform !== oldData.transform;
    if (transformChanged) {
      this.updateCameraCSS(self);

      const fragmentShader = `
        void main() {
          gl_FragColor = vec4(0.0, 0.0, 0.0, 0.0);
        }
      `;
      if (!this.data.transform) {
        this.mesh.material = new THREE.ShaderMaterial({
          vertexShader: `
            /*
              This shader is from the THREE's SpriteMaterial.
              We need to turn the backing plane into a Sprite
              (make it always face the camera) if "transfrom"
              is false.
            */
            #include <common>

            void main() {
              vec2 center = vec2(0., 1.);
              float rotation = 0.0;

              // This is somewhat arbitrary, but it seems to work well
              // Need to figure out how to derive this dynamically if it even matters
              float size = 0.03;

              vec4 mvPosition = modelViewMatrix * vec4( 0.0, 0.0, 0.0, 1.0 );
              vec2 scale;
              scale.x = length( vec3( modelMatrix[ 0 ].x, modelMatrix[ 0 ].y, modelMatrix[ 0 ].z ) );
              scale.y = length( vec3( modelMatrix[ 1 ].x, modelMatrix[ 1 ].y, modelMatrix[ 1 ].z ) );

              bool isPerspective = isPerspectiveMatrix( projectionMatrix );
              if ( isPerspective ) scale *= - mvPosition.z;

              vec2 alignedPosition = ( position.xy - ( center - vec2( 0.5 ) ) ) * scale * size;
              vec2 rotatedPosition;
              rotatedPosition.x = cos( rotation ) * alignedPosition.x - sin( rotation ) * alignedPosition.y;
              rotatedPosition.y = sin( rotation ) * alignedPosition.x + cos( rotation ) * alignedPosition.y;
              mvPosition.xy += rotatedPosition;

              gl_Position = projectionMatrix * mvPosition;
            }
          `,
          fragmentShader: fragmentShader
        });
      } else {
        this.mesh.material = new THREE.ShaderMaterial({
          fragmentShader: fragmentShader
        });
      }

      this.mesh.material.side = THREE.DoubleSide;
    }

    if (transformChanged || (!this.data.transform &&
        (this.data.fullscreen !== oldData.fullscreen || this.data.center !== oldData.center))) {
      this.updateTransformOuterCSS(self);
    }

    document.querySelector("canvas").style.zIndex = `${Math.floor(this.data.zIndexRange[0] / 2)}`;
    document.querySelector("canvas").style.pointerEvents = "none";
  },

  tick: function () {
    const camera = this.el.sceneEl.camera;
    camera.updateWorldMatrix();
    this.el.object3D.updateWorldMatrix(true, false);
    const vec = this.calculatePosition(this.el.object3D, camera);

    const eps = 1e-6;
    if (this.data.transform || !this.oldPosition ||
      Math.abs(this.oldZoom - camera.zoom) > eps ||
      Math.abs(this.oldPosition[0] - vec[0]) > eps ||
      Math.abs(this.oldPosition[1] - vec[1]) > eps
    ) {
      const isBehindCamera = isObjectBehindCamera(this.el.object3D, camera);

      const previouslyVisible = this.visible;
      this.visible = !isBehindCamera;

      if (previouslyVisible !== this.visible) {
        this.transformOuterRef.style.display = this.visible ? "block" : "none";
      }

      const halfRange = Math.floor(this.data.zIndexRange[0] / 2);
      const zRange = [halfRange - 1, 0];
      this.htmlCameraRef.style.zIndex = `${objectZIndex(this.el.object3D, camera, zRange)}`

      if (this.data.transform) {
        const [widthHalf, heightHalf] = [window.innerWidth / 2, window.innerHeight / 2];
        const fov = camera.projectionMatrix.elements[5] * heightHalf;
        const { isOrthographicCamera, top, left, bottom, right } = camera;
        const cameraMatrix = getCameraCSSMatrix(camera.matrixWorldInverse);
        const cameraTransform = isOrthographicCamera
          ? `scale(${fov})translate(${epsilon(-(right + left) / 2)}px,${epsilon((top + bottom) / 2)}px)`
          : `translateZ(${fov}px)`;
        let matrix = this.el.object3D.matrixWorld;

        this.htmlCameraRef.style.width = window.innerWidth + 'px';
        this.htmlCameraRef.style.height = window.innerHeight + 'px';
        this.htmlCameraRef.style.perspective = isOrthographicCamera ? '' : `${fov}px`;

        this.transformOuterRef.style.transform = `${cameraTransform}${cameraMatrix}translate(${widthHalf}px,${heightHalf}px)`;
        this.transformInnerRef.style.transform = getObjectCSSMatrix(matrix, this.data.pointsPerMeter);
      } else {
        const scale = objectScale(this.el.object3D, camera) * this.data.pointsPerMeter;
        this.htmlCameraRef.style.transform = `translate3d(${vec[0]}px,${vec[1]}px,0) scale(${scale})`;
      }

      this.oldPosition = vec;
      this.oldZoom = camera.zoom;
    }

    if (this.data.transform) {
      const metersPerPoint = 1.0 / this.data.pointsPerMeter;
      const w = this.transformInnerRef.clientWidth * metersPerPoint;
      const h = this.transformInnerRef.clientHeight * metersPerPoint;
      this.mesh.scale.set(w, h, 0.01);
    } else {
      // TODO: do this
    }
  },

  remove: function () {
    this.htmlCameraRef.parentNode.removeChild(this.htmlCameraRef);
  }
});