import "aframe";

const DEBUG_POSITION = { x: 0, y: 1.6, z: 0 };
const DEBUG_PIXELS_TO_METERS = 0.0003;

AFRAME.registerSystem("image-source", {
  schema: {
    enabled: {
      type: "boolean",
      default: false
    },
    src: {
      type: "string",
      default: ""
    },
    zoom: {
      type: "number",
      default: 1.0
    },
    zoomTarget: {
      type: "vec2",
      default: { x: -1.0, y: -1.0 }
    }
  },

  init: function () {
    this.hasImage = false;
    this.hasResizedImage = false;

    const self = this;
    window.addEventListener("resize", () => {
        self.hasResizedImage = false;
      }, true
    );
  },

  update: function (oldData) {
    if (this.data.enabled !== oldData.enabled) {
      if (!this.data.enabled) {
        if (this.imageElement) {
          document.body.removeChild(this.imageElement);
          this.imageElement = undefined;
        }
        if (this.imageEntity) {
          this.imageEntity.parentNode.removeChild(this.imageEntity);
          this.imageEntity = undefined;
        }
        this.hasImage = false;
        this.hasResizedImage = false;
      } else {
        if (this.imageElement) {
          this.imageElement.src = this.data.src;
        }
        if (this.imageEntity) {
          this.imageEntity.setAttribute("material", "src", this.data.src);
        }
      }
    }

    if (this.imageElement && this.data.src !== oldData.src) {
      this.imageElement.src = this.data.src;
    }
    if (this.imageEntity && this.data.src !== oldData.src) {
      this.imageEntity.setAttribute("material", "src", this.data.src);
    }

    if (this.data.zoom !== oldData.zoom || this.data.zoomTarget !== oldData.zoomTarget) {
      this.hasResizedImage = false;
    }
  },

  resizeImage: function () {
    if (!this.imageElement) {
      return;
    }

    this.windowWidth = window.innerWidth;//this.el.sceneEl.clientWidth;
    this.windowHeight = window.innerHeight;//this.el.sceneEl.clientHeight;
    this.windowAspect = this.windowWidth / this.windowHeight;
    this.imageWidth = this.imageElement.naturalWidth;
    this.imageHeight = this.imageElement.naturalHeight;
    this.imageAspect = this.imageWidth / this.imageHeight;
    if (this.windowAspect < this.imageAspect) {
      this.imageElement.width = this.data.zoom * this.windowHeight * this.imageAspect;
      this.imageElement.height = this.data.zoom * this.windowHeight;
    } else {
      this.imageElement.width = this.data.zoom * this.windowWidth;
      this.imageElement.height = this.data.zoom * this.windowWidth / this.imageAspect;
    }

    const left = 0.5 * (this.windowWidth - this.imageElement.width);
    const top = 0.5 * (this.windowHeight -  this.imageElement.height);
    let leftOffset = 0;
    let topOffset = 0;
    if (this.data.zoom > 1.0) {
      const maxOffset = 0.5 / this.data.zoom;
      if (this.data.zoomTarget.x >= 0) {
        const clampedTarget = Math.max(this.data.zoomTarget.x / this.imageElement.naturalWidth, maxOffset);
        leftOffset += (0.5 - clampedTarget) * this.imageElement.width;
      }
      if (this.data.zoomTarget.y >= 0) {
        const clampedTarget = Math.max(this.data.zoomTarget.y / this.imageElement.naturalHeight, maxOffset);
        topOffset += (0.5 - clampedTarget) * this.imageElement.height;
      }
    }

    this.imageElement.style.top = top + topOffset + "px";
    this.imageElement.style.left = left + leftOffset + "px";

    if (this.imageEntity) {
      this.imageEntity.setAttribute("width", DEBUG_PIXELS_TO_METERS * this.imageElement.width);
      this.imageEntity.setAttribute("height", DEBUG_PIXELS_TO_METERS * this.imageElement.height);
      this.imageEntity.setAttribute("position", (DEBUG_POSITION.x + DEBUG_PIXELS_TO_METERS * leftOffset) + " " +
                                                (DEBUG_POSITION.y - DEBUG_PIXELS_TO_METERS * topOffset) + " " +
                                                DEBUG_POSITION.z);
    }
  },

  tick: async function () {
    if (!this.data.enabled) {
      return;
    }

    if (!this.imageElement) {
      this.imageElement = document.createElement("img");
      this.imageElement.style = "z-index: -1; position: absolute";
      this.imageElement.crossOrigin = "anonymous";
      document.body.appendChild(this.imageElement);
      const self = this;
      this.imageElement.onload = () => { self.hasResizedImage = false; };
      if (this.imageElement) {
        this.imageElement.src = this.data.src;
      } else {
        return;
      }
    }

   if (!this.imageElement.complete) {
      return;
    }

    if (GYDENCE.isEditing && !GYDENCE.isApp) {
      if (!this.imageEntity) {
        this.imageEntity = document.createElement("a-image");
        this.imageEntity.setAttribute("src", this.data.src);
        this.imageEntity.setAttribute("position", DEBUG_POSITION.x + " " + DEBUG_POSITION.y + " " + DEBUG_POSITION.z);
        this.imageEntity.setAttribute("rotation", "0 0 0");
        this.el.sceneEl.appendChild(this.imageEntity);
      }
      this.imageElement.style.display = "none";
    }

    if (!this.hasResizedImage) {
      this.resizeImage();
      this.hasResizedImage = true;
    }
  },

  keypointToNDC: function (point) {
    const xRatio = this.imageElement.width / this.windowWidth;
    const yRatio = this.imageElement.height / this.windowHeight;

    let offsetX = 0;
    let offsetY = 0;
    if (this.data.zoom > 1.0) {
      const maxOffset = 0.5 / this.data.zoom;
      if (this.data.zoomTarget.x >= 0) {
        const clampedTarget = Math.max(this.data.zoomTarget.x / this.imageElement.naturalWidth, maxOffset);
        offsetX = 0.5 - clampedTarget;
      }
      if (this.data.zoomTarget.y >= 0) {
        const clampedTarget = Math.max(this.data.zoomTarget.y / this.imageElement.naturalHeight, maxOffset);
        offsetY = 0.5 - clampedTarget;
      }
    }

    let pointNDCX = 2.0 * (point.x / this.imageElement.naturalWidth + offsetX) - 1.0;
    let pointNDCY = 1.0 - 2.0 * (point.y / this.imageElement.naturalHeight + offsetY);

    if (this.windowAspect < this.imageAspect) {
      pointNDCX *= xRatio;
      pointNDCY *= this.data.zoom;
    } else {
      pointNDCY *= yRatio;
      pointNDCX *= this.data.zoom;
    }

    return { x: pointNDCX, y: pointNDCY };
  }
});

AFRAME.registerComponent("track-image-pixel", {
  schema: {
    enabled: {
      type: "boolean",
      default: false
    },
    target: {
      type: "vec2",
      default: 	{ x: 0, y: 0 }
    },
    depth: {
      type: "number",
      default: 0.01
    }
  },

  init: function () {
    this.point3D = new THREE.Vector3();
  },

  tick: async function () {
    if (!this.data.enabled) {
      return;
    }

    if (!this.imageSource) {
      this.imageSource = this.el.sceneEl?.systems["image-source"];
    }

    if (!this.imageSource?.imageElement) {
      return;
    }

    if (!this.imageSource.imageEntity) {
      const pointNDC = this.imageSource.keypointToNDC(this.data.target);
      this.point3D.set(pointNDC.x, pointNDC.y, this.data.depth);
      if (this.point3D.x !== undefined && this.point3D.y !== undefined && this.point3D.z !== undefined) {
        this.point3D.unproject(this.el.sceneEl.camera);
        this.el.object3D.position.copy(this.point3D);
      }
    } else {
      const position = this.imageSource.imageEntity.object3D.position;
      this.point3D.set(
        position.x + this.imageSource.imageEntity.getAttribute("width") * ((this.data.target.x / this.imageSource.imageWidth) - 0.5),
        position.y + this.imageSource.imageEntity.getAttribute("height") * (0.5 - (this.data.target.y / this.imageSource.imageHeight)),
        position.z
      );
      this.el.object3D.position.copy(this.point3D);
    }
  }
});