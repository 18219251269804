import "aframe";

let instanceCount = 0;
let spawnCount = 0;

AFRAME.registerComponent("spawner", {
  schema: {
    enabled: {
      type: "boolean",
      default: true,
    },
    object: {
      type: "string",
      default: "",
    },
    spawnTime: {
      type: "number",
      default: 1000
    },
    lifetime: {
      type: "number",
      default: 10000
    }
  },

  init: function () {
    this.lastUpdate = 0;
    this.childElements = [];
  },

  update: function () {
    if (this.model) {
      removeObject3D(this.model.object3D, true);
      this.el.removeChild(this.model);
    }

    var el = document.createElement("a-entity");
    el.id = "instance" + instanceCount++;
    el.setAttribute("gltf-model", this.data.object);
    el.setAttribute("instanced-mesh", { capacity: this.data.lifetime / this.data.spawnTime, });
    this.el.appendChild(el);
    this.model = el;
  },

  updateOpacity: function (el, opacity) {
    el.object3D.traverse(function(child) {
      child.scale.set(opacity, opacity, opacity);
      // if (child.material) {
      //   child.material.opacity = opacity;
      //   el.emit("object3DUpdated");
      // }
    });
    el.emit("object3DUpdated");
  },

  tick: function () {
    const now = Date.now();

    if (!this.data.enabled && this.childElements.length === 0) {
      this.el.object3D.visible = false;
    } else {
      this.el.object3D.visible = true;
    }

    if (this.data.enabled) {
      if (now - this.lastUpdate > this.data.spawnTime) {
        var el = document.createElement("a-entity");
        el.id = "spawn" + spawnCount++;
        el.setAttribute("instanced-mesh-member", { mesh: "#" + this.model.id });
        el.setAttribute("rematerial", { material: "#00FF00" });
        el.object3D.position.set(Math.random(), Math.random(), Math.random());
        el.object3D.position.subScalar(0.5);
        el.object3D.position.multiplyScalar(5.0);
        el.object3D.rotation.set(2.0 * Math.PI * Math.random(), 2.0 * Math.PI * Math.random(), 2.0 * Math.PI * Math.random());

        this.updateOpacity(el, 1.0);

        this.el.appendChild(el);
        this.childElements.push({ el: el, spawnTime: now });
        this.lastUpdate = now;
        spawnCount++;
      }
    }

    let lastRemovedIndex = -1;
    let i = 0;
    for (let elementPair of this.childElements) {
      const time = now - elementPair.spawnTime;
      const frac = time / this.data.lifetime;
      this.updateOpacity(elementPair.el, 2.0 * (frac < 0.5 ? frac : (1.0 - frac)));
      if (time > this.data.lifetime) {
        removeObject3D(elementPair.el.object3D, false);
        this.el.removeChild(elementPair.el);
        lastRemovedIndex = i;
      }
      i++;
    }

    if (lastRemovedIndex > -1) {
      this.childElements.splice(lastRemovedIndex, lastRemovedIndex + 1);
    }
  },

});
