import { createRoot } from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";

import { Analytics } from '@vercel/analytics/react';
import { SpeedInsights } from "@vercel/speed-insights/react";

import "./gydence/debug"
import SitePage from "./components/sitePage"
import SignIn from "./pages/signin";
import Editor from "./pages/editor";
import EditorView from "./pages/editorView";
import GydenceView from "./pages/view";
import GydencePreview from "./pages/preview";
import AppView from "./pages/app";
import NotFound from "./pages/404";

import "./index.module.css";

const root = createRoot(document.querySelector("#root"));
root.render(
  <>
  {/* <React.StrictMode> */}
    <HelmetProvider>
      <BrowserRouter>
        <Routes>
          <Route index element={
            <SitePage
              page="https://www.gydence.com/view/11859ebb-86c4-4f88-aade-29b0f1000cec"
              title="Homepage"
              description="Gydence is a collaborative 3D web editor for building mixed reality web experiences."
            />}
          />
          <Route path="/editor" element={<Editor />} />
          <Route path="/editorView" element={<EditorView />} />
          <Route path="/view/:siteID" element={<GydenceView />} />
          <Route path="/preview/:siteID" element={<GydencePreview />} />
          <Route path="/signin" element={<SignIn />} />
          <Route path="/app" element={<AppView />} />
          <Route path="/info" element={
            <SitePage
              page="https://www.gydence.com/view/42ddc2a7-3a34-4a67-8d3e-d1b057e63f6d"
              title="Info"
              description="Find out how Gydence can be used to build experiences for industries such as jewelry, rugs, and art. Contact us for more info."
            />}
          />
          <Route path="/rugs" element={
            <SitePage
              page="https://www.gydence.com/view/3de7e9c5-3497-4df7-9a40-446ab6922342?pageURL=http%3A%2F%2Fgydence.com%2Frugs"
              title="Virtual Rug Try On"
              description="Upload a photo of any rug or floor covering and see it live in your room with Augmented Reality."
              forceHamburger
            />}
          />
          <Route path="/art" element={
            <SitePage
              page="https://www.gydence.com/view/c35d492f-2e1c-476a-b4bd-c4c18cfefcba?pageURL=http%3A%2F%2Fgydence.com%2Fart"
              title="Virtual Art Try On"
              description="Upload a photo of any art or wall covering and see it live in your room with Augmented Reality."
              forceHamburger
            />}
          />
          <Route path="*" element={<NotFound />}/>
        </Routes>
      </BrowserRouter>
    </HelmetProvider>
    <Analytics />
    <SpeedInsights />
  {/* </React.StrictMode> */}
  </>
);