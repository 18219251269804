import "aframe";

import "@tensorflow/tfjs-core";
import "@tensorflow/tfjs-converter";
import "@tensorflow/tfjs-backend-webgl";
import "@mediapipe/hands";
import * as handPoseDetection from "@tensorflow-models/hand-pose-detection";

import { isMobileOrVR } from "./utils";

const NUM_HAND_KEYPOINTS = 21;
const JOINT_NAMES = [
  "wrist",
  "thumb1", "thumb2", "thumb3", "thumb_null",
  "index1", "index2", "index3", "index_null",
  "middle1", "middle2", "middle3", "middle_null",
  "ring1", "ring2", "ring3", "ring_null",
  "pinky1", "pinky2", "pinky3", "pinky_null",
];

const neighborConnections = [
  [0, 9, [5, 9]],
  [1, 2, [2, 5]],
  [5, 6, [6, 9]],
  [9, 10, [5, 10]],
  [13, 14, [9, 14]],
  [17, 18, [13, 18]],
];

const unusedJoints = [
  ["index0", "index1"],
  ["middle0", "middle1"],
  ["ring0", "ring1"],
  ["pinky0", "pinky1"],
];

const lookAt = (eye, target, yAxis) => {
  let eyex = eye.x, eyey = eye.y, eyez = eye.z,
    upx = yAxis.x, upy = yAxis.y, upz = yAxis.z;

  let z0 = eyex - target.x, z1 = eyey - target.y, z2 = eyez - target.z;

  let len = z0 * z0 + z1 * z1 + z2 * z2;
  if (len > 0) {
    len = 1 / Math.sqrt(len);
    z0 *= len;
    z1 *= len;
    z2 *= len;
  }

  let x0 = upy * z2 - upz * z1,
    x1 = upz * z0 - upx * z2,
    x2 = upx * z1 - upy * z0;
  len = x0 * x0 + x1 * x1 + x2 * x2;
  if (len > 0) {
    len = 1 / Math.sqrt(len);
    x0 *= len;
    x1 *= len;
    x2 *= len;
  }

  let result = new THREE.Matrix4();
  result.set(
    x0, z1 * x2 - z2 * x1, z0, 0,
    x1, z2 * x0 - z0 * x2, z1, 0,
    x2, z0 * x1 - z1 * x0, z2, 0,
    eyex, eyey, eyez, 1
  );
  return result;
};

function setInfo(text) {
  const info = document.querySelector("#info");
  if (info) {
    if (text) {
      info.style.display = "";
      info.innerHTML = text;
    } else {
      info.style.display = "none";
      info.innerHTML = "";
    }
  }
}

AFRAME.registerSystem("tfjs-hand-tracking", {
  schema: {
    enabled: {
      type: "boolean",
      default: false
    },
    numHands: {
      type: "number",
      default: 1
    }
  },

  init: function () {
    this.uiHasLoaded = false;
    this.firstCall = true;

    this.debugPoints =
      localStorage.debugPoints !== undefined
        ? localStorage.debugPoints === "true"
        : false;

    this.denoisedKeypoints = [];
    this.points = [];
    this.cyls = [];

    this.p0 = new THREE.Vector3();
    this.p1 = new THREE.Vector3();

    const self = this;
    this.hasResized = false;
    window.addEventListener("resize", () => {
        self.hasResized = true;
      }, true
    );
  },

  update: function () {
    if (this.data.enabled) {
      // Start hand tracking
      // FIXME: platform detection, performance comparison
      const useMediaPipe = !window.navigator.platform?.includes("iPhone");
      const detectorConfig = {
        runtime: !useMediaPipe ? "tfjs" : "mediapipe",
        modelType: "lite",
        maxHands: this.data.numHands,
        solutionPath: useMediaPipe ? "https://cdn.jsdelivr.net/npm/@mediapipe/hands/" : undefined
      };
      const self = this;
      handPoseDetection.createDetector(
        handPoseDetection.SupportedModels.MediaPipeHands,
        detectorConfig
      ).then((detector) => {
        self.detector = detector;
      });

      this.firstCall = true;
    } else {
      // Stop hand tracking
      this.detector = undefined;
      this.videoSource = undefined;

      // Cleanup bones
      for (let pointIndex = 0; pointIndex < this.points.length; pointIndex++) {
        const point = this.points[pointIndex];
        point.parentNode.removeChild(point);

        const cyl = this.cyls[pointIndex];
        if (cyl) {
          cyl.parentNode.removeChild(cyl);
        }
      }
      this.points = [];
      this.cyls = [];

      // Cleanup debug view
      this.cleanupDebugHand();
    }
  },

  toggleDebug: function () {
    this.debugPoints = !this.debugPoints;
    localStorage.debugPoints = this.debugPoints;
  },

  cleanupDebugHand: function () {
    if (this.debugHand) {
      this.debugHand.parentNode.removeChild(this.debugHand);
      this.debugHand = undefined;
    }
    this.hasSetupDebugView = false;
  },

  tick: async function () {
    if (!this.detector) {
      return;
    }

    if (!this.uiHasLoaded) {
      const queryString = window.location.search;
      let debugButton = document.querySelector("#debugButton");
      if (queryString && debugButton) {
        const urlParams = new URLSearchParams(queryString);
        const debugParam = urlParams.get("debug");
        if (debugParam) {
          debugButton.style.display = "block";
        }
        this.uiHasLoaded = true;
      }
    }

    if (!this.videoSource?.videoElement) {
      this.videoSource = document.querySelector("[camera-video-source]")?.systems["camera-video-source"];
      if (this.videoSource?.videoElement) {
        setInfo("Initializing Hand Tracking...");
      } else {
        return;
      }
    }

    if (this.videoSource.streamFailed && !this.hasSetupDebugView) {
      if (!GYDENCE.isEditing && !GYDENCE.isApp) {
        this.hasSetupDebugView = true;
        setInfo(null);
        return;
      }

      this.debugHand = document.createElement("a-entity");
      this.debugHand.setAttribute("gltf-model", "/assets/hand.glb");
      this.debugHand.setAttribute("position", "0 1.6 0.5");
      this.debugHand.setAttribute("rotation", "0 180 0");
      this.debugHand.setAttribute("scale", "0.75 0.75 0.75");

      let joint1 = document.createElement("a-entity");
      joint1.setAttribute("id", "b_r_ring1");
      joint1.setAttribute("position", "-0.037 -0.025 -0.01");
      joint1.setAttribute("rotation", "-90 0 0");
      joint1.setAttribute("scale", "0.01 0.01 0.01");
      joint1.setAttribute("visible", false);
      // joint1.setAttribute("geometry", "primitive:cone;radiusBottom:0.5");
      this.debugHand.appendChild(joint1);

      let joint2 = document.createElement("a-entity");
      joint2.setAttribute("id", "b_r_ring2");
      joint2.setAttribute("position", "-0.045 0.01 0.01");
      joint2.setAttribute("rotation", "-60 -10 10");
      joint2.setAttribute("scale", "0.01 0.01 0.01");
      joint2.setAttribute("visible", false);
      // joint2.setAttribute("geometry", "primitive:cone;radiusBottom:0.5");
      this.debugHand.appendChild(joint2);

      this.el.sceneEl.appendChild(this.debugHand);
      setInfo("Hand Tracking Debug Initialized!");
      setTimeout(function () {
        setInfo(null);
      }, 3000);
      this.hasSetupDebugView = true;
      return;
    } else if (!this.videoSource.streamFailed && this.hasSetupDebugView) {
      this.cleanupDebugHand();
    }

    if (this.videoSource.videoElement.readyState === 0) {
      return;
    }

    if (this.videoSource.paused && !this.hasResized) {
      return;
    }
    this.hasResized = false;

    const self = this;
    this.detector.estimateHands(this.videoSource.videoElement).then((hands) => {
      if (!self.data.enabled) {
        return;
      }

      if (self.firstCall) {
        setInfo("Hand Tracking Initialized!");
        setTimeout(function () {
          setInfo(null);
        }, 3000);
        self.firstCall = false;
      }

      if (!hands || hands.length === 0) {
        self.denoisedKeypoints = [];
      }

      let pointIndex = 0;
      let viableScores = 0;
      if (hands) {
        const getRay = function (point) {
          const pointNDC = self.videoSource.keypointToNDC(point);

          self.p0.set(pointNDC.x, pointNDC.y, 0.0);
          self.p0.unproject(self.el.camera);
          self.p1.set(pointNDC.x, pointNDC.y, 1.0);
          self.p1.unproject(self.el.camera);

          let v = self.p1.clone();
          v.sub(self.p0);
          v.normalize();

          return [self.p0, v];
        };

        for (let handIndex = 0; handIndex < hands.length; handIndex++) {
          let hand = hands[handIndex];
          let left = hand.handedness === "Left";
          if (self.videoSource.backCamera) {
            left = !left;
          }

          const HAND_SCORE_CUTOFF = 0.9;
          if (hand.score > HAND_SCORE_CUTOFF) {
            viableScores++;
          }

          const bonePrefix = "b_" + (left ? "l_" : "r_");

          for (
            let keypointIndex = 0;
            keypointIndex < hand.keypoints.length;
            keypointIndex++
          ) {
            const keypoint = hand.keypoints[keypointIndex];
            const denoisedKeypointIndex = keypointIndex + handIndex * NUM_HAND_KEYPOINTS;
            if (denoisedKeypointIndex === self.denoisedKeypoints.length) {
              self.denoisedKeypoints.push(keypoint);
            } else {
              let denoisedKeypoint = self.denoisedKeypoints[denoisedKeypointIndex];
              const DISTANCE_THRESHOLD = 50;
              const factor = Math.min(1.0, Math.sqrt(Math.sqrt(Math.pow(denoisedKeypoint.x - keypoint.x, 2.0) + Math.pow(denoisedKeypoint.y - keypoint.y, 2.0)) / DISTANCE_THRESHOLD));
              denoisedKeypoint.x = (1.0 - factor) * denoisedKeypoint.x + factor * keypoint.x;
              denoisedKeypoint.y = (1.0 - factor) * denoisedKeypoint.y + factor * keypoint.y;
            }
          }

          let firstEl = null;
          let scaleFactor = 1.0;
          for (
            let keypointIndex = 0;
            keypointIndex < hand.keypoints.length;
            keypointIndex++
          ) {
            let el = null;
            let cylEl = null;
            if (pointIndex >= self.points.length) {
              el = document.createElement("a-cone");
              const pointSize = 0.005;
              el.object3D.scale.set(pointSize, pointSize, pointSize);

              if (keypointIndex === 0 || keypointIndex % 4 !== 0) {
                cylEl = document.createElement("a-entity");
                let childCylEl = document.createElement(
                  keypointIndex === 0 ? "a-sphere" : "a-cylinder"
                );
                if (keypointIndex === 0) {
                  childCylEl.object3D.scale.set(8, 1, 1.2);
                } else {
                  childCylEl.object3D.scale.set(1.2, 1, 1.2);
                }
                childCylEl.object3D.rotation.set(0.5 * Math.PI, 0, 0);
                cylEl.appendChild(childCylEl);
              }

              self.el.appendChild(el);
              self.points.push(el);
              if (cylEl) {
                self.el.appendChild(cylEl);
              }
              self.cyls.push(cylEl); // always push so that the indices line up
            } else {
              el = self.points[pointIndex];
              cylEl = self.cyls[pointIndex];
            }

            let boneName = bonePrefix + JOINT_NAMES[keypointIndex];
            el.setAttribute("id", boneName);
            el.setAttribute("visible", self.debugPoints);
            el.setAttribute("color", left ? "#FF0000" : "#00FF00");
            if (cylEl) {
              if (self.debugPoints) {
                cylEl.setAttribute("rematerial", "material", "renderOrder 0");
              } else {
                cylEl.setAttribute(
                  "rematerial",
                  "material",
                  "occlude, renderOrder 0"
                );
              }
            }

            if (el) {
              const keypoint3D0 = hand.keypoints3D[0];
              if (keypointIndex === 0) {
                const keypoint0 = self.denoisedKeypoints[0 + NUM_HAND_KEYPOINTS * handIndex];
                let pv0 = getRay(keypoint0);
                let point0 = pv0[0].clone().add(pv0[1]);
                const point0NDC = self.videoSource.keypointToNDC(keypoint0);
                const rootPoint = point0.clone();
                point0.project(self.el.camera);

                for (
                  let nextKeypointIndex = 1;
                  nextKeypointIndex < hand.keypoints.length;
                  nextKeypointIndex++
                ) {
                  const nextKeypoint = self.denoisedKeypoints[nextKeypointIndex + NUM_HAND_KEYPOINTS * handIndex];
                  const nextKeypoint3D = hand.keypoints3D[nextKeypointIndex];
                  let point1 = rootPoint.clone();
                  point1.setX(point1.x + (nextKeypoint3D.x - keypoint3D0.x));
                  point1.setY(point1.y - (nextKeypoint3D.y - keypoint3D0.y));
                  point1.setZ(point1.z - (nextKeypoint3D.z - keypoint3D0.z));
                  point1.project(self.el.camera);

                  const point1NDC = self.videoSource.keypointToNDC(nextKeypoint);
                  let denom =
                    Math.pow(point1NDC.x - point0NDC.x, 2) +
                    Math.pow(point1NDC.y - point0NDC.y, 2);
                  scaleFactor = Math.sqrt(
                    (Math.pow(point1.x - point0.x, 2) +
                      Math.pow(point1.y - point0.y, 2)) /
                      denom
                  );
                  // FIXME: keep searching until we find a good denom?
                  break;
                }

                el.object3D.position.set(
                  pv0[0].x + pv0[1].x * scaleFactor,
                  pv0[0].y + pv0[1].y * scaleFactor,
                  pv0[0].z + pv0[1].z * scaleFactor
                );

                firstEl = el;
              } else {
                const keypoint3D1 = hand.keypoints3D[keypointIndex];
                el.object3D.position.copy(firstEl.object3D.position);
                el.object3D.position.setX(
                  el.object3D.position.x + (keypoint3D1.x - keypoint3D0.x)
                );
                el.object3D.position.setY(
                  el.object3D.position.y - (keypoint3D1.y - keypoint3D0.y)
                );
                el.object3D.position.setZ(
                  el.object3D.position.z - (keypoint3D1.z - keypoint3D0.z)
                );

                // Project onto the ray
                // FIXME: why?  scaleFactor must be wrong?
                {
                  const keypoint = self.denoisedKeypoints[keypointIndex + NUM_HAND_KEYPOINTS * handIndex];
                  const pv = getRay(keypoint);
                  let point = pv[0].clone();
                  let ray = pv[1].clone();

                  let toObject = el.object3D.position.clone().sub(pv[0]);
                  el.object3D.position.copy(
                    point.add(
                      ray.multiplyScalar(toObject.dot(ray) / ray.dot(ray))
                    )
                  );
                }
              }

              el.setAttribute("color", left ? "#FF0000" : "#00FF00");
            }

            pointIndex++;
          }

          for (let neighborConnection of neighborConnections) {
            const jointIndex = neighborConnection[0];
            let el = document.querySelector(
              "#" + bonePrefix + JOINT_NAMES[jointIndex]
            );
            let target = document.querySelector(
              "#" + bonePrefix + JOINT_NAMES[neighborConnection[1]]
            );

            let neighbor1 = document.querySelector(
              "#" + bonePrefix + JOINT_NAMES[neighborConnection[2][0]]
            );
            let neighbor2 = document.querySelector(
              "#" + bonePrefix + JOINT_NAMES[neighborConnection[2][1]]
            );
            if (el && neighbor1 && neighbor2 && target) {
              let v1 = neighbor1.object3D.position.clone();
              v1.sub(el.object3D.position);

              let v2 = neighbor2.object3D.position.clone();
              v2.sub(el.object3D.position);

              let up = v1.cross(v2);
              up.normalize();

              if (!left) {
                up.multiplyScalar(-1.0);
              }

              el.object3D.quaternion.setFromRotationMatrix(
                lookAt(el.object3D.position, target.object3D.position, up)
              );

              if (jointIndex !== 0) {
                let lastQuaternion = null;
                for (let fingerIndex = 1; fingerIndex < 3; fingerIndex++) {
                  let nextEl = document.querySelector(
                    "#" + bonePrefix + JOINT_NAMES[jointIndex + fingerIndex]
                  );
                  let nextTarget = document.querySelector(
                    "#" + bonePrefix + JOINT_NAMES[jointIndex + fingerIndex + 1]
                  );
                  if (nextEl && nextTarget) {
                    nextEl.object3D.quaternion.setFromRotationMatrix(
                      lookAt(
                        nextEl.object3D.position,
                        nextTarget.object3D.position,
                        up
                      )
                    );
                    lastQuaternion = nextEl.object3D.quaternion;
                  }
                }

                let tipEl = document.querySelector(
                  "#" + bonePrefix + JOINT_NAMES[jointIndex + 3]
                );
                if (tipEl) {
                  tipEl.object3D.quaternion.copy(lastQuaternion);
                }
              }
            }
          }

          let handTarget = document.querySelector(
            left ? "#leftHandController" : "#rightHandController"
          );
          if (handTarget) {
            handTarget.object3D.visible = true;
            let controls = handTarget.components["hand-tracking-controls"];
            if (controls && !handTarget.modelInitialized) {
              controls.addEventListeners();
              controls.initDefaultModel();
              handTarget.modelInitialized = true;
            }
            if (controls && controls.bones) {
              controls.mesh.visible = true;
              controls.mesh.position.set(0, 0, 0);
              for (
                let sphereIndex = NUM_HAND_KEYPOINTS * handIndex;
                sphereIndex < NUM_HAND_KEYPOINTS * (handIndex + 1);
                sphereIndex++
              ) {
                let sphere = self.points[sphereIndex];
                let bone = controls.getBone(sphere.id);
                if (bone) {
                  bone.position.copy(sphere.object3D.position);
                  bone.position.multiplyScalar(10.0);
                  bone.quaternion.copy(sphere.object3D.quaternion);
                  // FIXME: what's up with this
                  bone.scale.set(0.03, 0.03, 0.03);
                }
              }

              let wrist = controls.getBone(bonePrefix + "wrist");
              for (let unusedJoint of unusedJoints) {
                let bone = controls.getBone(bonePrefix + unusedJoint[0]);
                if (bone) {
                  let matchBone = controls.getBone(bonePrefix + unusedJoint[1]);
                  bone.position
                    .copy(matchBone.position)
                    .lerp(wrist.position, 0.7);
                  bone.quaternion
                    .copy(matchBone.quaternion)
                    .slerp(wrist.quaternion, 0.1);
                  bone.scale.copy(matchBone.scale);
                }
              }
            }
          }
        }
      }

      if (!hands || hands.length < 2) {
        let hideHand = function (leftController) {
          let handController = document.querySelector(
            !leftController ? "#leftHandController" : "#rightHandController"
          );
          if (handController) {
            handController.object3D.visible = false;
          }
        };

        if (!hands || hands.length === 0 || hands[0].handedness === "Right") {
          hideHand(self.videoSource.backCamera ? true : false);
        }
        if (!hands || hands.length === 0 || hands[0].handedness === "Left") {
          hideHand(self.videoSource.backCamera ? false : true);
        }
      }

      // Clear old elements
      for (; pointIndex < self.points.length; pointIndex++) {
        const point = self.points[pointIndex];
        point?.parentNode?.removeChild(point);

        const cyl = self.cyls[pointIndex];
        if (cyl) {
          cyl?.parentNode?.removeChild(cyl);
        }
      }
      self.points.length = hands ? NUM_HAND_KEYPOINTS * hands.length : 0;
      self.cyls.length = self.points.length;

      // Copy joint transforms to cylinders
      if (hands?.length > 0) {
        for (let pointIndex = 0; pointIndex < NUM_HAND_KEYPOINTS; pointIndex++) {
          for (let handIndex = 0; handIndex < hands.length; handIndex++) {
            const cylIndex = pointIndex + handIndex * NUM_HAND_KEYPOINTS;
            let cyl = self.cyls[cylIndex];
            if (cyl) {
              let point = self.points[cylIndex];
              cyl.object3D.position.copy(point.object3D.position);
              cyl.object3D.quaternion.copy(point.object3D.quaternion);
              cyl.object3D.scale.copy(point.object3D.scale);

              let nextPoint = self.points[(pointIndex !== 0 ? pointIndex + 1 : 9) + handIndex * NUM_HAND_KEYPOINTS];
              let height =
                point.object3D.position.distanceTo(nextPoint.object3D.position) /
                point.object3D.scale.y;
              // Spheres are twice as big
              let ratio = 0.5;
              if (pointIndex === 0) {
                height *= 0.5;
                ratio *= 2;
              }
              cyl.children[0].object3D.scale.setY(height);
              cyl.children[0].object3D.position.setZ(-ratio * height);
            }
          }
        }
      }

      const event = new CustomEvent("hand-tracking-result", { detail: { numHands: viableScores }});
      document.dispatchEvent(event);
    });
  }
});