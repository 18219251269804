import "aframe";

let initialized = false;

AFRAME.registerComponent("gydence-networked", {
  init: function () {
    if (!initialized) {
      if (!NAF.connection.isConnected()) {
        const self = this;
        document.body.addEventListener("connected", function () {
          if (!initialized) {
            self.setup();
            self.addNetworkComponent();
          }
        });
      } else {
        this.setup();
        this.addNetworkComponent();
      }
    } else {
      this.addNetworkComponent();
    }

  },

  setup: function () {
    NAF.schemas.add({
      template: "#template",
      components: ["position", "rotation", "scale", "geometry", "material"],
    });
    NAF.options.updateRate = 60;

    initialized = true;
  },

  addNetworkComponent: function () {
    this.el.setAttribute("networked", { template: "#template" });
  },
});
